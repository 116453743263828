import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import AssetPickerInfoContainer from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import AssetPickerViewTableContainer from '@complex/AssetPickerModal/Components/ViewTable/AssetPickerViewTableContainer'
import {
  getCustomDefaultEmptyListingProps,
  getUnloadedItemIds,
  onCustomSourceRowSelectionChangeInAllListsUtils,
  onRowSelectionChangeInAllListsUtils,
  getDisableSourceRowsProps,
  updateCustomSelectedViewItems,
} from '@complex/AssetPickerModal/utils/AssetPickerModalUtil'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import Modal, { ModalBody } from '@components/Modal'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { ItemDto } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'

import { unusedAssetPickerListingPageProps, unusedAssetPickerSidebarProps, unusedAssetPickerTableProps } from './AssetPickerModal.constants'
import AssetPickerPreviewContainer from './Components/Preview/AssetPickerPreviewContainer'
import AssetPickerSidebarContainer from './Components/Sidebar/AssetPickerSidebarContainer'
import AssetPickerTableContainer from './Components/Table/AssetPickerTableContainer'
import {
  AssetPickerCommonState,
  AssetPickerContext,
  assetPickerContextCommonValues,
  AssetPickerListingPageProps,
  AssetPickerTableProps,
  InfoBannerType,
} from './Context/AssetPicker.context'
import { useAssetPickerQueries } from './graphQL/AssetPickerModal.graphQL'
import { useAssetPickerFooterProps } from './utils/useAssetPickerFooterProps'
import { useAssetPickerHeaderProps } from './utils/useAssetPickerHeaderProps'
import {
  CustomSource,
  CustomSourceItems,
  ExtendedItemDto,
  ItemDtoRow,
  ListingPageCommonContext,
  ListingPageProps,
  ListPageCommonState,
  SetFilter,
} from '../ListingPage/Context/ListingPageCommon.context'
import { useListingPageBase } from '../ListingPage/Hooks/useListingPageBase'
import { listingPageMockAPI } from '../ListingPage/Utils/ListingPage.constants'
import { parseItemDtoResult, useGetDefaultValues } from '../ListingPage/Utils/ListingPage.utils'

import './AssetPickerModal.css'

export interface AssetPickerModalBaseProps {
  className?: string
  dataTest?: string
  getCustomDefaultEmptyListing?: (filter: ListPageCommonState, setFilter: SetFilter, t: Function) => EmptyListingProps | undefined
  isOpen: boolean
  isViewingSelected?: boolean
  onSubmit?: (items: ExtendedItemDto[]) => void
  onSubmitCustomSources?: (items: CustomSourceItems) => void
  onClose?: () => void
  onBack?: () => void
  preSelectedRowIds?: string[]
  preSelectedCustomSourceItems?: CustomSourceItems
  isSingleSelect?: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  viewSelectedLabel?: string
  titleText?: string
  infoBanner?: InfoBannerType
  hasCustomFilters?: boolean
  hasTags?: boolean
  hasSidebar?: boolean
  hasPreview?: boolean
  hideRecordsCount?: boolean
  restrictSelectionToOneSource?: boolean
  defaultSourceType?: string
  enableSubmitWithoutSelection?: boolean
  disableRowByCriteria?: (row: Row<any>) => boolean
  onSelectedCustomSourceChange?: (customSource: CustomSource) => void
  disabledListTooltipText?: string
  excludeItemIds?: string[]
}

export interface AssetPickerModalProps extends AssetPickerModalBaseProps {
  listingPageProps: AssetPickerListingPageProps
  tableProps: AssetPickerTableProps
  itemType: ItemType
  defaultSubTypes?: string[]
}

const rootClass = 'asset-picker-modal'

const AssetPickerModal: FC<AssetPickerModalProps> = (props: AssetPickerModalProps) => {
  const {
    dataTest = rootClass,
    className = '',
    defaultSourceType,
    getCustomDefaultEmptyListing,
    isOpen,
    isViewingSelected: isViewingSelectedProp = false,
    listingPageProps,
    listingPageProps: {
      addCustomData,
      sidebarProps: {
        customSources: customSourcesProps,
        renderCustomFilters: renderCustomFiltersProps,
        customDefaultFilters: customDefaultFiltersProps,
      },
    },
    isSingleSelect = false,
    preSelectedRowIds = [],
    preSelectedCustomSourceItems = {},
    itemType,
    tableProps,
    hasPreview = true,
    hasSidebar = true,
    hasTags = true,
    infoBanner,
    defaultSubTypes = [],
    restrictSelectionToOneSource = false,
    onSelectedCustomSourceChange,
    disableRowByCriteria,
    disabledListTooltipText,
    excludeItemIds = [],
  } = props

  const compatibleListingPageProps: ListingPageProps = {
    ...unusedAssetPickerListingPageProps,
    ...listingPageProps,
    defaultSubTypes,
    disableSessionData: true,
    addCustomData:
      excludeItemIds.length || addCustomData
        ? async (items) => {
            const filteredItems = items.filter((item) => !excludeItemIds.includes(item.externalId ?? ''))
            if (addCustomData) {
              return addCustomData(filteredItems)
            }
            return filteredItems
          }
        : undefined,
    sidebarProps: { ...unusedAssetPickerSidebarProps, ...listingPageProps.sidebarProps },
    tableProps: { ...unusedAssetPickerTableProps, listPage: props.tableProps.listPage },
  }

  const defaultListingState = useGetDefaultValues(compatibleListingPageProps, dataTest, itemType)

  const updatePicker = (fieldsToUpdate: Partial<AssetPickerCommonState>) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      ...fieldsToUpdate,
    }))
  }

  const updateListing = (fieldsToUpdate: Partial<ListPageCommonState>) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      listingPageState: {
        ...containerValues.listingPageState,
        ...fieldsToUpdate,
      },
    }))
  }

  const [containerValues, setContainerValues] = useState<AssetPickerCommonState>({
    ...assetPickerContextCommonValues,
    listingPageState: {
      ...defaultListingState,
      // bypassing this shared sort logic so that the asset picker table can handle it its own way
      isTableSortInitialized: true,
      itemType,
      sortBy: tableProps?.sortingBy?.length ? [{ id: tableProps?.sortingBy[0]?.id, desc: tableProps?.sortingBy[0]?.desc }] : [{ id: '', desc: true }],
      defaultSubTypes,
      selectedCustomSource: customSourcesProps && customSourcesProps[0],
      listingPageProps: {
        ...defaultListingState.listingPageProps,
        alwaysPreserveSearchTerm: true,
        getCustomDefaultEmptyListingProps: getCustomDefaultEmptyListing ?? getCustomDefaultEmptyListingProps,
        sidebarProps: {
          ...defaultListingState.listingPageProps.sidebarProps,
          hideTags: !props.hasTags,
          ...(!props.hasCustomFilters ? { renderCustomFilters: undefined } : {}),
        },
      },
    },
    isViewingSelected: isViewingSelectedProp,
    isSingleSelect,
    tableProps,
    viewTableProps: { ...tableProps },
    hasPreview,
    hasSidebar,
    hasTags,
  })

  const {
    isViewingSelected,
    customSourceSelectedViewItems,
    listingPageState,
    listingPageState: {
      initialPageLoading,
      showPreview,
      customSourceItems,
      items,
      filterActive,
      selectedCustomSource,
      search,
      searchItemsResults,
      listingPageProps: {
        hasCustomRequests,
        sidebarProps: { customSources },
      },
    },
  } = containerValues

  // A more robust version of the update function that we can pass to the listing page hook that it will use for the external api
  const setListingContainerValues: Dispatch<SetStateAction<ListPageCommonState>> = useCallback(
    (setValue) => {
      setContainerValues((containerValues) => {
        const newValue = typeof setValue === 'function' ? setValue(containerValues.listingPageState) : setValue
        return {
          ...containerValues,
          listingPageState: {
            ...containerValues.listingPageState,
            ...newValue,
          },
        }
      })
    },
    [setContainerValues]
  )

  const { t } = useTranslation()

  const {
    onSearch,
    setStatusToast,
    getFilterItems,
    getFilterItemsWithCustomRequests,
    getTagItems,
    getFolderItems,
    setItems,
    setError,
    setFilter,
    setFolder,
    setTag,
    setSelectedCustomSource,
    toggleSubType,
    onColumnSort,
    onClickTagInRow,
    onShouldFetch,
    currentTag,
  } = useListingPageBase(listingPageState, updateListing, setListingContainerValues)

  const selectedSourceItems = (customSourceItems ?? {})[selectedCustomSource?.label as string]

  const checkIfRowsHaveToBeDisabled = () => {
    if (!restrictSelectionToOneSource) {
      return false
    }
    // Check if we have items selected from a source that is not the current selected source
    const selectedSourcesEntries = Object.entries(customSourceSelectedViewItems ?? {})
    const sources = selectedSourcesEntries.filter((source) => !!source[1].length).map((source) => source[0])
    return !!sources.length && !sources.includes(selectedCustomSource?.label as string)
  }

  const setRowsDisabled = () => {
    const disableRow = !!defaultSourceType ? defaultSourceType !== selectedCustomSource?.value : checkIfRowsHaveToBeDisabled()
    updatePicker({
      tableProps: {
        ...tableProps,
        rowDisabled: () => disableRow,
        rowDisabledTitle: 'You can select items only from one source.',
        headerCheckboxDisabled: disableRow,
      },
    })
  }

  useEffect(() => {
    onSelectedCustomSourceChange && selectedCustomSource && onSelectedCustomSourceChange(selectedCustomSource)
  }, [selectedCustomSource])

  useEffect(() => {
    // This generally fires on mount or when onChangeCustomSouce is called
    const { allItemFilter: sourceAllItemsFiler, filterTrigger } = selectedCustomSource || {}
    updatePicker({ hasTags: props.hasTags })

    const filterActive = filterTrigger ?? sourceAllItemsFiler ?? listingPageProps.sidebarProps.allItemFilter
    updateListing({
      filterActive,
      activeFolderId: undefined,
      activeTagId: undefined,
      search: '',
      searchAll: false,
      fetchFilterCounts: true,
      fetchItems: true,
      currentPage: 0,
      folderPath: [],
      loading: true,
      items: [],
      searchItemsResults: [],
      sortBy: tableProps.sortingBy,
      listingPageProps: {
        ...listingPageState.listingPageProps,
        externalDataLoading: listingPageProps.externalDataLoading,
        onApiAvailable: listingPageProps.onApiAvailable,
        customPreviewItemCall: listingPageProps.customPreviewItemCall,
        subTypes: props.listingPageProps.subTypes,
        getCustomDefaultEmptyListingProps: getCustomDefaultEmptyListing ?? getCustomDefaultEmptyListingProps,
        sidebarProps: {
          ...listingPageState.listingPageProps.sidebarProps,
          ...(props.hasCustomFilters ? { renderCustomFilters: renderCustomFiltersProps } : { renderCustomFilters: undefined }),
          customSources: listingPageProps.sidebarProps.customSources,
          hasFavorites: props.listingPageProps.sidebarProps.hasFavorites,
          hasCreatedByMe: props.listingPageProps.sidebarProps.hasCreatedByMe,
          hasRecent: props.listingPageProps.sidebarProps.hasRecent,
          customDefaultFilters: customDefaultFiltersProps,
        },
      },
    })
  }, [itemType, selectedCustomSource, listingPageProps.externalDataLoading])

  useEffect(() => {
    // If the asset picker has already a rowDisabled function then use it, otherwise use the default one
    const rowsMustBeDisabled = () => (!!defaultSourceType ? defaultSourceType !== selectedCustomSource?.value : checkIfRowsHaveToBeDisabled())
    const disableRows = !tableProps.rowDisabled && restrictSelectionToOneSource && rowsMustBeDisabled()
    const disabledRowProps = disableRows ? getDisableSourceRowsProps(true) : {}
    updatePicker({ tableProps: { ...tableProps, ...disabledRowProps }, viewTableProps: { ...tableProps } })
  }, [tableProps])

  useEffect(() => {
    if (!Object.keys(customSourceSelectedViewItems ?? {}).length) {
      const customSourceSelectedViewItems =
        preSelectedCustomSourceItems ?? customSourcesProps?.reduce((curr, { label }) => ({ ...curr, [label]: [] }), {})
      updatePicker({
        customSourceSelectedViewItems,
      })
    }
  }, [])

  useEffect(() => {
    restrictSelectionToOneSource && setRowsDisabled()
  }, [customSourceSelectedViewItems, tableProps])

  useEffect(() => {
    if (checkIfRowsHaveToBeDisabled() && !!searchItemsResults.length && !searchItemsResults?.some(({ disabled }) => disabled)) {
      updateListing({ searchItemsResults: searchItemsResults.map((result) => ({ ...result, disabled: true })) })
    }
  }, [searchItemsResults])

  useEffect(() => {
    if (!checkIfRowsHaveToBeDisabled() && !!searchItemsResults.length && searchItemsResults?.some(({ disabled }) => disabled)) {
      updateListing({ searchItemsResults: searchItemsResults.map((result) => ({ ...result, disabled: false })) })
    }
  }, [isViewingSelected])

  const onRowSelectionChanged = (rowIds: string[], rows: Row<ItemDtoRow>[]) => {
    !!customSources?.length
      ? onCustomSourceRowSelectionChangeInAllListsUtils(containerValues, updatePicker, rowIds, rows, tableProps.rowDisabled)
      : onRowSelectionChangeInAllListsUtils(containerValues, updatePicker, rowIds, rows)
  }

  const { getItemRequest } = useAssetPickerQueries(itemType)

  const updateViewItems = async () => {
    const rowIds = !preSelectedRowIds?.length ? [] : isSingleSelect ? [preSelectedRowIds[0]] : preSelectedRowIds
    const loadedItems = selectedSourceItems ?? items
    const loadedSelectedItems = loadedItems.filter((item) => rowIds.includes(item.id))

    const unloadedItemIds = getUnloadedItemIds(
      loadedSelectedItems.map((item) => item.id),
      rowIds
    )

    if (unloadedItemIds.length) {
      const unloadedSelectedItems = (await Promise.all(
        unloadedItemIds.map(async (itemId) => {
          const { data } = await getItemRequest(itemId)
          return data?.getItem
        })
      )) as ItemDto[]

      hasCustomRequests
        ? updateCustomSelectedViewItems(updatePicker, preSelectedCustomSourceItems)
        : updatePicker({ selectedViewItems: [...loadedSelectedItems, ...parseItemDtoResult(unloadedSelectedItems)], loadingPreSelectedItems: false })
    } else {
      hasCustomRequests
        ? updateCustomSelectedViewItems(updatePicker, preSelectedCustomSourceItems)
        : updatePicker({ selectedViewItems: loadedSelectedItems, loadingPreSelectedItems: false })
    }
  }

  useEffect(() => {
    !initialPageLoading && updateViewItems()
  }, [initialPageLoading])

  useEffect(() => {
    if (disableRowByCriteria) {
      updatePicker({
        tableProps: {
          ...tableProps,
          rowDisabled: disableRowByCriteria,
          rowTooltip: (row) => (disableRowByCriteria(row) ? t(disabledListTooltipText) : undefined),
        },
      })
    }
  }, [disableRowByCriteria, disabledListTooltipText])

  const statusToast = containerValues.listingPageState.statusToast
  const fetchError = statusToast.showStatusToast
    ? {
        message: statusToast.statusMessage,
        status: statusToast.status,
        closeStatus: () => updateListing({ statusToast: { ...statusToast, showStatusToast: false } }),
      }
    : undefined

  const tableNotEmpty = !!(search ? searchItemsResults : selectedSourceItems ?? items).length
  let infoBannerValues
  if (!!customSourceItems || !!infoBanner) {
    if (!!infoBanner) {
      infoBannerValues = infoBanner
    } else if (!!selectedCustomSource?.filterInfoBanner && !!filterActive && !!customSourceItems) {
      infoBannerValues = selectedCustomSource?.filterInfoBanner(customSourceItems, filterActive, t)[filterActive?.name]
    }
  }

  const showInfoBanner = !!infoBannerValues && tableNotEmpty

  const showSidebar = !showPreview && !isViewingSelected && hasSidebar
  const showTable = !isViewingSelected && !showPreview

  const footerProps = useAssetPickerFooterProps({ modalProps: props, modalState: containerValues, update: updatePicker })
  const headerProps = useAssetPickerHeaderProps({ modalProps: props, modalState: containerValues, update: updatePicker, rootClass })
  const header = <ModalHeaderV2 {...headerProps} headerType={'list'} className={`${rootClass}__header`} />

  return (
    <AssetPickerContext.Provider value={{ values: { ...containerValues }, update: updatePicker, onRowSelectionChanged }}>
      <ListingPageCommonContext.Provider
        value={{
          ...listingPageMockAPI,
          update: updateListing,
          onSearch,
          setStatusToast,
          getFilterItems,
          getFilterItemsWithCustomRequests,
          getTagItems,
          getFolderItems,
          setItems,
          setTag,
          setSelectedCustomSource,
          setError,
          setFilter,
          setFolder,
          onColumnSort,
          toggleSubType,
          onClickTagInRow,
          onShouldFetch,
          currentTag,
          values: { ...containerValues.listingPageState },
        }}
      >
        <Modal className={classNames(rootClass, className)} dataTest={dataTest} isOpen={isOpen} header={header} noPadding>
          <ModalBody className={`${rootClass}__body`}>
            {fetchError && <StatusToast {...fetchError} />}
            {showPreview && <AssetPickerPreviewContainer />}
            {showSidebar && <AssetPickerSidebarContainer />}
            {isViewingSelected && <AssetPickerViewTableContainer />}
            <div
              className={classNames(`${rootClass}__body__info-wrapper`, {
                [`${rootClass}__body__info-wrapper--hidden`]: !showTable,
              })}
            >
              {showInfoBanner && !!infoBannerValues && <AssetPickerInfoContainer infoBanner={infoBannerValues} />}
              <AssetPickerTableContainer />
            </div>
          </ModalBody>
          <ModalFooterV2 {...footerProps} footerType={'list'} className={`${rootClass}__footer`} dataTest={`${dataTest}-footer`} />
        </Modal>
      </ListingPageCommonContext.Provider>
    </AssetPickerContext.Provider>
  )
}

export default AssetPickerModal
