import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import ListPickerModal from '@complex/ListPickerModalV2/ListPickerModal'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Checkbox from '@components/Checkbox'
import InputV2 from '@components/InputV2/InputV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import { BaseList, UpgradedSource, UpgradeManagerContext, UpgradeStep } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { useUpgradeManagerClassicRequests } from '@src/pages/programs/upgradeManager/graphQL/UpgradeManagerRequests.classic.graphQL'

import './NewSource.css'

export interface NewSourceProps {
  canCreateSegment?: boolean
  sourceToUpgrade: UpgradedSource
  onCheckboxChange?: (id: string, isChecked: boolean, upgradeId?: string) => void
  onSubmitSource?: (id: string, newSource: List, upgradeId: string) => void
  showTopDirectSelectSegmentsOnly?: boolean
}

interface State {
  createNewSegment: boolean
  creatingSegmentError?: string
  isCreatingNewSegment: boolean
  newSegmentName?: string
  newSource?: List | BaseList
  showListPicker: boolean
  recommendedSegmentChanged: boolean
}

const rootClass = 'new-source'
const rootText = 'AutomatedPrograms.UpgradeManager.NewSource.'
const SAME_NAME_SEGMENT_ERROR = 5
const SAME_NAME_SEGMENT_ERROR_MESSAGE = 'A segment with the same name already exists.'

export const NewSource: FC<NewSourceProps> = ({
  canCreateSegment,
  showTopDirectSelectSegmentsOnly,
  sourceToUpgrade: {
    source: { id, sourceName },
    recommended,
    removed,
    newSource: newSourceProp,
    upgradeId,
  },
  onCheckboxChange,
  onSubmitSource,
}: NewSourceProps) => {
  const {
    values: { activeUpgradeStep, upgradedCurrentStep },
  } = useContext(UpgradeManagerContext)

  const [state, setState] = useState<State>({
    createNewSegment: false,
    isCreatingNewSegment: false,
    showListPicker: false,
    newSource: newSourceProp ?? recommended,
    recommendedSegmentChanged: recommended?.id !== newSourceProp?.id,
  })

  const { createNewSegment, creatingSegmentError, isCreatingNewSegment, newSegmentName, newSource, showListPicker, recommendedSegmentChanged } = state

  const { t } = useTranslation()

  const { createSegmentRequest } = useUpgradeManagerClassicRequests()

  const isStepSourceRemoved = activeUpgradeStep === UpgradeStep.FIX_STEP_ISSUES && upgradedCurrentStep?.removed
  const isDisabled = removed || isStepSourceRemoved

  useEffect(() => {
    if (newSourceProp || recommended) {
      onSubmitLists([(newSourceProp || recommended) as List], false)
    }
  }, [])

  const onChange = (checked: boolean) => onCheckboxChange?.(id, checked, upgradeId)

  const onCreateSegmentCheckboxChange = (checked: boolean) => {
    setState((state) => ({ ...state, createNewSegment: checked, newSegmentName: newSegmentName || newSource?.name || sourceName }))
  }

  const onClick = () => setState((state) => ({ ...state, showListPicker: true }))

  const onCloseModal = () => setState((state) => ({ ...state, showListPicker: false }))

  const onSubmitLists = (lists: List[], recommendedSegmentChanged: boolean) => {
    onSubmitSource?.(id, lists[0], upgradeId)
    setState((state) => ({ ...state, newSource: lists[0], showListPicker: false, recommendedSegmentChanged }))
  }

  const onNewSegmentNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, newSegmentName: event.target.value, creatingSegmentError: undefined }))
  }, [])

  const onCreateNewSegmentClick = async () => {
    if (newSegmentName) {
      if (newSegmentName === newSource?.name) {
        setState((state) => ({ ...state, creatingSegmentError: SAME_NAME_SEGMENT_ERROR_MESSAGE, isCreatingNewSegment: false }))
      } else {
        setState((state) => ({ ...state, isCreatingNewSegment: true }))
        const { data, errors } = await createSegmentRequest(newSegmentName)
        if (data?.createSegment) {
          const newSegmentList = {
            id: data.createSegment.id,
            name: data?.createSegment.name,
            size: 0,
          } as List
          onSubmitLists([newSegmentList], false)
          setState((state) => ({ ...state, creatingSegmentError: undefined, newSegmentName: '', isCreatingNewSegment: false }))
        }
        if (errors) {
          const error =
            errors[0].extensions.errorCode === SAME_NAME_SEGMENT_ERROR ? SAME_NAME_SEGMENT_ERROR_MESSAGE : 'Something went wrong. Please try again.'
          setState((state) => ({ ...state, creatingSegmentError: error, isCreatingNewSegment: false }))
        }
      }
    }
  }

  const restoreRecommendedSegment = () => {
    if (!!recommended) {
      onSubmitSource?.(id, recommended as List, upgradeId)
      setState((state) => ({ ...state, newSource: recommended, recommendedSegmentChanged: false }))
    }
  }

  return (
    <>
      {showListPicker && (
        <ListPickerModal
          closeModal={onCloseModal}
          isOpen
          submitLists={(lists: List[]) => onSubmitLists(lists, true)}
          multiSelect={false}
          defaultSelectedLists={!!newSource ? [newSource.id] : undefined}
          hideLegacyLists
          hideLegacyListToggle
          disableRowByCriteria={(list) =>
            showTopDirectSelectSegmentsOnly && 'type' in list && 'parent' in list ? list.type !== 'Direct Select' || !!list.parent : false
          }
        />
      )}
      <div className={rootClass} data-test={rootClass}>
        <div className={`${rootClass}__container`}>
          {!!recommended && !recommendedSegmentChanged ? (
            <Typography text={t(`${rootText}Recommended`)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
          ) : (
            <div className={`${rootClass}__replacement`}>
              <Typography
                text={t(`${rootText}${activeUpgradeStep === UpgradeStep.FIX_STEP_ISSUES ? 'FixStepIssues.' : ''}Replacement`)}
                type={TextType.BODY_TEXT}
                weight={TextWeight.MEDIUM}
              />
              {!!recommended && !recommendedSegmentChanged && (
                <Tooltip
                  trigger={
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                    <div className={`${rootClass}__restore-recommended`} onClick={restoreRecommendedSegment} role={'button'}>
                      <Svg name={SvgNames.reset} type={SvgType.ICON} fill={SvgColor.TEXT_TEAL} />
                    </div>
                  }
                  align={'end'}
                >
                  {t(`${rootText}Recommended.Restore`)}
                </Tooltip>
              )}
            </div>
          )}
          <div className={classNames(`${rootClass}__source-box`, { [`${rootClass}__source-box-disabled`]: isDisabled })}>
            {!!newSource ? (
              <>
                <TextWithTooltipOnEllip typographyProps={{ text: newSource?.name, type: TextType.BODY_TEXT }} />
                <Typography text={t(`${rootText}Contacts`, { count: newSource?.size })} type={TextType.BODY_TEXT_LIGHT} />
              </>
            ) : (
              <Typography text={t('None selected')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.ITALIC} />
            )}
          </div>
        </div>
        {createNewSegment ? (
          <div className={`${rootClass}__create-segment-section`}>
            <InputV2
              placeholder={t(`New segment name`)}
              value={newSegmentName}
              onChange={onNewSegmentNameChange}
              {...(creatingSegmentError
                ? {
                    inputInfo: {
                      errorText: t(creatingSegmentError),
                      enabled: !!creatingSegmentError,
                    },
                  }
                : {})}
              error={!!creatingSegmentError}
              disabled={isDisabled}
              handleValueChangeFromOuter
            />
            <ButtonWithLoader onClick={onCreateNewSegmentClick} loading={isCreatingNewSegment} disabled={!newSegmentName || isDisabled}>
              {t('Create')}
            </ButtonWithLoader>
          </div>
        ) : (
          <Button
            className={`${rootClass}__button`}
            buttonType={ButtonType.SECONDARY}
            onClick={onClick}
            iconPosition={ButtonIconPosition.FLOAT}
            disabled={isDisabled}
          >
            <Svg name={SvgNames.listCheckNoFill} type={SvgType.ICON} />
            {!!newSource ? t(`${rootText}Button.Selected`) : t(`${rootText}Button.None`)}
          </Button>
        )}
        {canCreateSegment && (
          <Checkbox
            label={t(`Create new segment`)}
            onChange={onCreateSegmentCheckboxChange}
            checked={createNewSegment}
            disabled={isDisabled}
            showTickOnDisabled
          />
        )}
        {!!onCheckboxChange && <Checkbox label={t(`${rootText}Checkbox.Label`)} onChange={onChange} checked={isDisabled} />}
      </div>
    </>
  )
}
