import React from 'react'
import { Row } from 'react-table'

import { PageHeaderProps } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { ListingPageStatusToast } from '@complex/ListingPage/Utils/ListingPage.constants'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import { CardsOptions } from '@components/InfoCard/InfoCard'
import { SvgNames, SvgType } from '@components/Svg'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { rootContext } from '@const/globals'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { FormSubmissionDataCardDto, FormSubmissionSummaryDto, WebinarAttendanceData } from '@graphql/types/microservice/entity-join-types'
import { ExportSyncJobDto, FtpFileDto } from '@graphql/types/microservice/entity-upload-types'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput, StatValueDto } from '@graphql/types/microservice/segment-types'
import FormsJoinViewPageHeader from '@src/pages/listingPages/FormsJoinView/components/FormsJoinViewPageHeader/FormsJoinViewPageHeader'
import { onSendEmailClick } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.utils'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import {
  FtpExecutionStatus,
  FtpExportExecutionStatus,
  saveSelectedContactsToLocalStorage,
  Segment,
} from '@utils/contactSegments/contactSegments.utils'
import { FileUplaodModalType } from '@utils/formUtils'
import { parseJsonSafely } from '@utils/utils'

import { FilesData } from './components/FormUploadFileModal/FormUploadFileModal'

export const WEBINARS_SUBMISSIONS_URL = 'content/webinarSubmissions'
export const CUSTOM_DATA_URL = 'customData'
export const SEGMENTS_URL = 'segments'
export const FORMS_SUBMISSIONS_URL = 'content/formSubmissions'
export const FORM_SUBMISSION_LIST_URL = `${rootContext}/${FORMS_SUBMISSIONS_URL}`
export const WEBINAR_SUBMISSION_LIST_URL = `${rootContext}/${WEBINARS_SUBMISSIONS_URL}`
export const CUSTOM_DATA_LIST_URL = `${rootContext}/${CUSTOM_DATA_URL}`

const FORMS_REPORT_URL = `${rootContext}/report/forms-lps-media`

export const getDetailsURL = (url: string) => `${url}/details`

export type ListRelatedForms = { key: string; value: FormSubmissionSummaryDto[] }

export const FormsJoinViewListingPageContainerInitialState: FormsJoinViewListingPageContainerState = {
  columns: [],
  showDetails: false,
  activeSubmissionSendableContacts: { value: 0, percentageValue: 0 },
  activeSubmissionPerformanceData: { totalSubmissions: 0, totalViews: 0 },
  activeSubmissionConversionRate: 0,
  relatedForms: [],
  loadingFtpFiles: true,
  selectingFtpFile: false,
  selectingExportAction: false,
  isFTPConnectorActive: false,
  loadingFtpExportSyncs: true,
  ftpExportSyncJobs: [],
  selectingFtpExports: true,
  ftpSegmentsExecutionDetails: {},
  ftpExportSegmentsExecutionDetails: {},
  ftpFiles: [],
  webinarAttendanceData: { attendanceData: { attendees: 0, registrants: 0 }, completed: false },
  contactHeaders: [],
}

export const getPageHeaderProps = (itemType: ItemType): PageHeaderProps => {
  return {
    pageTitle: 'Audience Center',
    renderPageHeaderContent: () => <FormsJoinViewPageHeader currentItemType={itemType} />,
  }
}

export const getDropDownActions = (t: Function, urlPush: Function): MenuItem[] => [
  {
    text: t('View forms report'),
    icon: SvgNames.reports,
    onClick: () => urlPush(FORMS_REPORT_URL),
  },
]

export const customContactsHeaderActions = (
  selectedContacts: Contact[],
  unifiedListFieldMappings: UnifiedListFieldMapping[],
  contactHeaders?: Column[]
): HeaderAction[] => [
  {
    label: 'Send Email',
    icon: SvgNames.letter,
    hasTooltip: true,
    onClick: () => {
      saveSelectedContactsToLocalStorage(selectedContacts, contactHeaders ?? [], unifiedListFieldMappings)

      onSendEmailClick(selectedContacts)
    },
  },
]

const getUploadedFiles = (row: Row<Contact>, segmentContacts: PageInput | undefined) => {
  return (
    segmentContacts?.contacts[row.original.recId]
      ?.map((item: string) => parseJsonSafely(item))
      .filter((parsed: { type: string; data: string[] }) => parsed?.type === 'FILE')
      .flatMap((attachment: any) => attachment.data?.map((item: any) => item) ?? []) ?? []
  )
}

export const customContactsRowActions = (
  unifiedListFieldMappings: UnifiedListFieldMapping[],
  contactHeaders?: Column[],
  newFormFileUploadBlock?: boolean,
  segmentContacts?: PageInput,
  userAllowedToDownload?: boolean,
  toggleDownloadOrPreviewModal?: (type: FileUplaodModalType, contact: string[]) => void,
  handleDownloadOrPreviewFile?: (fileIds: number[], type?: FileUplaodModalType, uploadedFiles?: Omit<FilesData, 'url' | 'date'>[]) => void
): RowAction[] => [
  {
    label: 'Send Email',
    tooltipMessage: 'Send Email',
    icon: SvgNames.letter,
    onClick: (row: Row<Contact>) => {
      saveSelectedContactsToLocalStorage([row.original], contactHeaders ?? [], unifiedListFieldMappings)

      sendEmailToContact(row?.original.recId)
    },
  },
  {
    label: 'View Contact Report',
    tooltipMessage: 'View Contact Report',
    icon: SvgNames.contactReport,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.SUMMARY),
  },
  {
    label: 'Edit contact',
    tooltipMessage: 'Edit contact',
    icon: SvgNames.pencil,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.INFO),
  },
  {
    label: 'Preview uploaded files',
    icon: SvgNames.zoom,
    disabled: !userAllowedToDownload,
    tooltipMessage: !userAllowedToDownload ? 'Ask your administrator for permission to do this' : undefined,
    iconSize: SvgType.TINY_ICON,
    onClick: (row: Row<Contact>) => {
      const uploadedFiles = getUploadedFiles(row, segmentContacts)
      const ids = uploadedFiles.map((item: { name: string; id: number }) => item.id)

      if (uploadedFiles.length === 1) {
        handleDownloadOrPreviewFile?.(ids, FileUplaodModalType.PREVIEW)
      } else {
        toggleDownloadOrPreviewModal?.(FileUplaodModalType.PREVIEW, segmentContacts?.contacts[row.original.recId])
      }
    },
    hasTooltip: true,
    inDropdown: true,
    hidden: (row: Row<Contact>) => {
      const hasUploadedFiles =
        segmentContacts?.contacts[row.original.recId]
          ?.map((item: string) => parseJsonSafely(item))
          .some((parsed: { type: string; data: string[] }) => parsed?.type === 'FILE') || false
      return !newFormFileUploadBlock || !hasUploadedFiles
    },
  },
  {
    label: 'Download uploaded files',
    icon: SvgNames.download,
    disabled: !userAllowedToDownload,
    tooltipMessage: !userAllowedToDownload ? 'Ask your administrator for permission to do this' : undefined,
    iconSize: SvgType.TINY_ICON,
    onClick: (row: Row<Contact>) => {
      const uploadedFiles = getUploadedFiles(row, segmentContacts)
      const ids = uploadedFiles.map((item: { name: string; id: number }) => item.id)

      if (uploadedFiles.length === 1) {
        handleDownloadOrPreviewFile?.(ids, FileUplaodModalType.DOWNLOAD, uploadedFiles)
      } else {
        toggleDownloadOrPreviewModal?.(FileUplaodModalType.DOWNLOAD, segmentContacts?.contacts[row.original.recId])
      }
    },
    hasTooltip: true,
    inDropdown: true,
    hidden: (row: Row<Contact>) => {
      const hasUploadedFiles =
        segmentContacts?.contacts[row.original.recId]
          ?.map((item: string) => parseJsonSafely(item))
          .some((parsed: { type: string; data: string[] }) => parsed?.type === 'FILE') || false
      return !newFormFileUploadBlock || !hasUploadedFiles
    },
  },
]

export const getSegmentDetailsPerformanceCards = (
  sendableContacts: StatValueDto,
  performanceData: Partial<FormSubmissionDataCardDto>,
  conversionRate: number,
  t: Function
): CardsOptions => ({
  cardsTitle: t('Performance'),
  cards: [
    {
      label: t('Total Submissions'),
      tooltip: t('Total submissions over views'),
      labelContent: {
        primary: `${performanceData.totalSubmissions} / ${performanceData.totalViews}`,
      },
    },
    {
      label: t('Conversion rate'),
      labelContent: {
        primary: `${conversionRate}%`,
      },
    },
    {
      label: t('Sendable Contacts'),
      tooltip: t('Total contacts in this segment who are eligible to receive email'),
      labelContent: {
        primary: `${sendableContacts.percentageValue}%`,
        secondary: sendableContacts.value.toString(),
      },
    },
  ],
})

export const getWebinarSegmentDetailsPerformanceCards = (
  sendableContacts: StatValueDto,
  webinarAttendanceData: WebinarAttendanceAndStatusData,
  t: Function
): CardsOptions => {
  const textPrefix = 'ListPage.FormsJoinView.WEBINAR_REGISTRATION.Details.Performance'
  const { error, completed, attendanceData } = webinarAttendanceData
  const { attendees, registrants } = attendanceData
  const tooltip: string = error ? `${textPrefix}.Error` : completed ? `${textPrefix}.Attended.Registered.Tooltip` : `${textPrefix}.TotalRegistrations`
  const attendanceLabel = error ? `${textPrefix}.Error` : completed ? `${textPrefix}.Attended.Registered` : `${textPrefix}.TotalRegistrations`
  const attendanceLabelContent = error ? '-' : completed ? `${attendees} / ${registrants}` : `${registrants}`
  return {
    cardsTitle: t('Performance'),
    cards: [
      {
        label: t(attendanceLabel),
        tooltip: t(tooltip),
        labelContent: {
          primary: attendanceLabelContent,
        },
      },
      {
        label: t('Sendable Contacts'),
        tooltip: t('Total contacts in this segment who are eligible to receive email'),
        labelContent: {
          primary: `${sendableContacts.percentageValue}%`,
          secondary: sendableContacts.value.toString(),
        },
      },
    ],
  }
}

export const formsJoinViewURLByItemType: { [key: string]: string } = {
  [ItemType.FORM_SUBMISSION]: FORMS_SUBMISSIONS_URL,
  [ItemType.SEGMENT]: SEGMENTS_URL,
  [ItemType.WEBINAR_SUBMISSION]: WEBINARS_SUBMISSIONS_URL,
  [ItemType.CUSTOM_OBJECT]: CUSTOM_DATA_URL,
}

export const listingPageHeaderByItemType: { [key: string]: string } = {
  [ItemType.FORM_SUBMISSION]: 'Form Submissions',
  [ItemType.SEGMENT]: 'All Contacts',
  [ItemType.WEBINAR_SUBMISSION]: 'Webinar Registrations',
  [ItemType.CUSTOM_OBJECT]: 'Custom Data',
}

export const iconByItemType: { [key: string]: { selected: SvgNames; unselected: SvgNames } } = {
  [ItemType.FORM_SUBMISSION]: { selected: SvgNames.formSimpleSelected, unselected: SvgNames.formSimple },
  [ItemType.SEGMENT]: { selected: SvgNames.segmentsSelected, unselected: SvgNames.segmentsUnselected },
  [ItemType.WEBINAR_SUBMISSION]: { selected: SvgNames.webinar, unselected: SvgNames.webinarOutline },
  [ItemType.CUSTOM_OBJECT]: { selected: SvgNames.customDataSolid, unselected: SvgNames.customData },
}

export interface FormsJoinViewListingPageContainerProps {
  className?: string
  dataTest?: string
  storyUrl?: string
}

export interface WebinarAttendanceAndStatusData {
  attendanceData: WebinarAttendanceData
  completed: boolean
  error?: boolean
}

export interface FormsJoinViewListingPageContainerState {
  columns: Column[]
  showDetails: boolean
  selectedContact?: Contact
  activeSubmission?: ItemDto
  activeSubmissionSendableContacts: StatValueDto
  activeSubmissionPerformanceData: Partial<FormSubmissionDataCardDto>
  activeSubmissionConversionRate: number
  relatedForms: FormSubmissionSummaryDto[]
  submissionsDataCardsData?: FormSubmissionDataCardDto
  statusToast?: ListingPageStatusToast
  segmentToExport?: Segment
  loadingFtpFiles: boolean
  selectingFtpFile: boolean
  selectingExportAction: boolean
  isFTPConnectorActive: boolean
  loadingFtpExportSyncs: boolean
  ftpExportSyncJobs: ExportSyncJobDto[]
  selectingFtpExports: boolean
  ftpSegmentsExecutionDetails: Record<string, FtpExecutionStatus>
  ftpExportSegmentsExecutionDetails: Record<string, FtpExportExecutionStatus>
  ftpFileImportLogTarget?: string
  creatingFtpFile?: boolean
  crmPushErrorsModalProps?: { origin: string; listId: string }
  formsWithCrmPushErrors?: string[]
  showSelectFtpExportManage?: boolean
  ftpFiles: FtpFileDto[]
  loading?: boolean
  webinarAttendanceData: WebinarAttendanceAndStatusData
  attachmentsLoading?: boolean
  attachmentsData?: FilesData[]
  initialAttachmentsData?: Omit<FilesData, 'url' | 'date'>[]
  contactHeaders?: string[]
}

export const filesMockData = [
  {
    id: 0,
    name: 'File Name 1',
    date: 'Oct 9, 2023 2:30pm',
    url: 'https://aord-file-upload-service.s3.us-west-2.amazonaws.com/Temp/10_1_0001%3A0_12bac604-c2a8-4064-932c-3147f4ec878b_ashdasda.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241205T131345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIAXMUPSN4AKE4XL2OV%2F20241205%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=ec4a1b386319dcef7b896256f01fc2c54ac04d0d5c71b3b7247aaa024fff67fb',
  },
  {
    id: 1,
    name: 'File Name 2',
    date: 'Oct 9, 2023 2:30pm',
    url: 'https://aord-file-upload-service.s3.us-west-2.amazonaws.com/Temp/10_1_0001%3A0_51cbfeb2-ef8a-430d-a9c2-b93685bab2b8_image.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241205T131722Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIAXMUPSN4AKE4XL2OV%2F20241205%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=8d394c211444a9a9cf05744f0a67f854441c0acb26b099f7169a9180afc3a26d',
  },
  {
    id: 2,
    name: 'File Name 3',
    date: 'Oct 9, 2023 2:30pm',
    url: 'https://aord-file-upload-service.s3.us-west-2.amazonaws.com/Temp/10_1_0001%3A0_12bac604-c2a8-4064-932c-3147f4ec878b_ashdasda.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241205T131345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIAXMUPSN4AKE4XL2OV%2F20241205%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=ec4a1b386319dcef7b896256f01fc2c54ac04d0d5c71b3b7247aaa024fff67fb',
  },
  {
    id: 3,
    name: 'File Name 4',
    date: 'Oct 9, 2023 2:30pm',
    url: 'https://aord-file-upload-service.s3.us-west-2.amazonaws.com/Temp/10_1_0001%3A0_51cbfeb2-ef8a-430d-a9c2-b93685bab2b8_image.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241205T131722Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIAXMUPSN4AKE4XL2OV%2F20241205%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=8d394c211444a9a9cf05744f0a67f854441c0acb26b099f7169a9180afc3a26d',
  },
  {
    id: 4,
    name: 'File Name 5',
    date: 'Oct 9, 2023 2:30pm',
    url: 'https://aord-file-upload-service.s3.us-west-2.amazonaws.com/Temp/10_1_0001%3A0_12bac604-c2a8-4064-932c-3147f4ec878b_ashdasda.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241205T131345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIAXMUPSN4AKE4XL2OV%2F20241205%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=ec4a1b386319dcef7b896256f01fc2c54ac04d0d5c71b3b7247aaa024fff67fb',
  },
]
