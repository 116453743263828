import React, { FC, Key, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient, useMutation } from '@apollo/client'
import { ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useFolderRequests } from '@complex/ListingPage/GraphQL/Folders.graphQL'
import { useTagRequests } from '@complex/ListingPage/GraphQL/Tags.graphQL'
import DuplicateFolderModal from '@components/DuplicateModal/DuplicateFolderModal'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import RadioCard from '@components/RadioCard/RadioCard'
import RadioCardGroup from '@components/RadioCardGroup/RadioCardGroup'
import { useSegmentDetailsQueries } from '@components/SegmentDetails/SegmentDetails.graphQL'
import { SvgNames } from '@components/Svg'
import Typography, { ModalBodyStyle, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import copyLandingPageOrTemplate from '@graphql/mutations/copyLandingPageOrTemplate'
import createBeeLandingPage from '@graphql/mutations/createBeeLandingPage'
import {
  CopyLandingPageOrTemplateMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CreateBeeLandingPageMutation,
  CreateBeeLandingPageMutationVariables,
} from '@graphql/types/mutation-types'
import { ItemDto, LabelDto } from '@graphql/types/query-types'
import { BetaBannerCard } from '@src/pages/listingPages/LandingPages/components/BetaBannerCard/BetaBannerCard'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { CommonComposerState } from '@utils/composer/commonComposer/CommonComposer.context'
import { DuplicateModalProps } from '@utils/composer/landingPage/types'
import { sortFoldersByName } from '@utils/folderUtils'
import { Folder } from '@utils/interface/Folder'
import { useFetchLandingPageLazy } from '@utils/landingPages'
import { DeepUpdateState } from '@utils/types'

import './CreateLandingPageTemplateModal.css'

interface CreateLandingPageTemplateModalProps {
  startId?: string
  className?: string
  dataTest?: string
  onCancel?: () => void
  onCreate?: (method: CreateLandingPageTemplateMethod) => void
  isOpen: boolean
  showAdditionalOptions?: boolean
  disableLandingPageOption?: boolean
  landingPageTemplateId?: string
  duplicateModalProps?: DuplicateModalProps
  isSaveFailed?: boolean
  isLandingListingPage?: boolean
  includeUnpublishedChangesLabel?: string
  onInputChange?: (value: string) => void
  update?: DeepUpdateState<CommonComposerState> | ((state: Partial<ListPageCommonState>) => void)
  failToastError?: {
    title: string
    message: string
  }
}

const BETA_LINK = 'https://connect.act-on.com/hc/en-us/articles/28614216871831'

export enum CreateLandingPageTemplateMethod {
  NEW = 'new',
  LANDING_PAGE = 'landingPage',
}

const rootClass = 'create-landing-page-template-modal'

const CreateLandingPageTemplateModal: FC<CreateLandingPageTemplateModalProps> = (props: CreateLandingPageTemplateModalProps) => {
  const {
    startId,
    dataTest = rootClass,
    className = '',
    onCreate,
    onCancel,
    isOpen,
    disableLandingPageOption,
    landingPageTemplateId,
    duplicateModalProps,
    isSaveFailed,
    isLandingListingPage,
    includeUnpublishedChangesLabel,
    update,
    failToastError,
  } = props

  const {
    handleDuplicateCreate,
    headerTitleKey,
    itemNameLabel,
    namePlaceholder,
    onDuplicateCancel,
    onSubmitText,
    itemName,
    defaultName,
    onInputChange,
    showLPToggleToIncludeChanges = false,
    isLandingPageTemplateComposer = false,
  } = duplicateModalProps ?? {}

  const client = useApolloClient()

  const [method, setMethod] = useState<Key>(CreateLandingPageTemplateMethod.NEW)
  const [createBetaModal, setCreateBetaModal] = useState<boolean>(false)
  const [itemToCloneTags, setItemToCloneTags] = useState<ItemDto[]>()
  const [activeFolderId, setActiveFolderId] = useState()
  const [tags, setTags] = useState<LabelDto[]>([])
  const [folders, setFolders] = useState<Folder[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [includeUnpublishedChanges, setIncludeUnpublishedChanges] = useState(isLandingPageTemplateComposer)
  const [submitFailed, setSubmitFailed] = useState(false)
  const [isBetaToggleOn, setIsBetaToggleOn] = useState<boolean>(window.localStorage.getItem('isBetaToggleOn') === 'true')
  const [setMutation] = useMutation<CreateBeeLandingPageMutation, CreateBeeLandingPageMutationVariables>(createBeeLandingPage, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [setCopyLandingPageOrTemplateMutation] = useMutation<CopyLandingPageOrTemplateMutation, CopyLandingPageOrTemplateMutationVariables>(
    copyLandingPageOrTemplate,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const { t } = useTranslation()

  const { newLPComposerTemplates } = useAccountSettings()
  const { getItemDetailsRequest } = useSegmentDetailsQueries()
  const { getAllFoldersRequest } = useFolderRequests()
  const { createTagRequest, getAllTagsRequest } = useTagRequests()

  const { showToggle, loading: isLPLoading } = useFetchLandingPageLazy(
    startId,
    newLPComposerTemplates && (!!isLandingListingPage || showLPToggleToIncludeChanges)
  )

  const getItemDetails = useCallback(() => {
    getItemDetailsRequest(ItemType.LANDING_PAGE_TEMPLATE, undefined, landingPageTemplateId).then(({ data }) => {
      const { folderId, labels } = data?.getItem ?? {}
      setItemToCloneTags(labels as ItemDto[])
      setActiveFolderId(folderId)
    })
  }, [getItemDetailsRequest, landingPageTemplateId])

  const getTags = useCallback(() => {
    getAllTagsRequest([ItemType.LANDING_PAGE_TEMPLATE], []).then(({ data }) => {
      if (data?.getLabels) {
        setTags(data.getLabels.filter(filterNotEmptyArray))
      }
    })
  }, [getAllTagsRequest])

  const getFolders = useCallback(() => {
    setLoading(true)
    getAllFoldersRequest(ItemType.LANDING_PAGE_TEMPLATE, [], true)
      .then(({ data }) => {
        if (data?.getFolders) {
          setFolders(sortFoldersByName(data.getFolders))
        }
      })
      .finally(() => setLoading(false))
  }, [getAllFoldersRequest])

  const handleCreateTag = useCallback(
    (tag: LabelDto) => {
      createTagRequest(ItemType.LANDING_PAGE, tag).then(({ errors }) => {
        if (!errors) {
          getTags()
        }
      })
    },
    [createTagRequest]
  )

  const header = (
    <ModalHeaderV2 headerType={ModalHeaderType.Form} className={`${rootClass}__header`} headerText={t('CreateLandingPageTemplateModal.Title')} />
  )

  const createButtonText =
    (CreateLandingPageTemplateMethod.NEW === method && isBetaToggleOn && newLPComposerTemplates) ||
    CreateLandingPageTemplateMethod.LANDING_PAGE === method
      ? 'Next'
      : 'Create'

  const handleToggle = (isOn: boolean) => {
    setIsBetaToggleOn(isOn)
    window.localStorage.setItem('isBetaToggleOn', `${isOn}`)
  }

  const createLandingPageTemplate = async (name: string, tags: LabelDto[], folderIdToClone: number | undefined) => {
    try {
      const { data, errors } = await setMutation({
        variables: {
          createBeeLandingPageInput: {
            folderId: folderIdToClone,
            tags: tags,
            title: name,
            isTemplate: true,
          },
        },
      })
      if (data) {
        const id = data?.createBeeLandingPage.id
        update?.({ fetchItems: true, fetchTags: true })
        window.open(`${rootContext}/content/landingPage/${id}/design?firstEdit=true`, '_blank')
        onCancel?.()
      }
      if (errors) {
        setSubmitFailed(true)
      }
    } catch (error) {
      setSubmitFailed(true)
    }
  }

  const createTemplateFromLandingPage = async (name: string, tags: LabelDto[], folderIdToClone: number | undefined) => {
    try {
      const { data, errors } = await setCopyLandingPageOrTemplateMutation({
        variables: {
          folderId: folderIdToClone,
          id: startId,
          title: name,
          toTemplate: true,
          tags,
          includeUnPublishedChanges: includeUnpublishedChanges,
        },
      })
      if (data) {
        const id = data?.copyLandingPageOrTemplate
        update?.({ fetchItems: true, fetchTags: true })
        window.open(`${rootContext}/content/landingPage/${id}/design?firstEdit=true`, '_blank')
        onCancel?.()
      }
      if (errors) {
        setSubmitFailed(true)
      }
    } catch (error) {
      setSubmitFailed(true)
    }
  }

  const isDataLoaded = useRef(false)

  useEffect(() => {
    if (!isDataLoaded.current && (createBetaModal || !!duplicateModalProps)) {
      getTags()
      getFolders()

      if (duplicateModalProps) {
        getItemDetails()
      }

      isDataLoaded.current = true
    }
  }, [createBetaModal, duplicateModalProps])

  useEffect(() => {
    if (!createBetaModal) {
      setSubmitFailed(false)
    }
  }, [createBetaModal])

  return createBetaModal || !!duplicateModalProps ? (
    <DuplicateFolderModal
      isBee
      hideIcon
      tags={tags}
      folders={folders}
      activeFolderId={activeFolderId}
      itemToCloneTags={itemToCloneTags}
      isSaveFailed={isSaveFailed || submitFailed}
      loading={loading || isLPLoading}
      itemNameRequired
      dropDownSvgIcon={SvgNames.folderNestedGray}
      headerTitleKey={headerTitleKey || t('CreateLandingPageTemplateModal.Title')}
      itemNameLabel={itemNameLabel || t('CreateLandingPageTemplateModal.BetaCreate.Label')}
      itemName={itemName ?? ''}
      defaultName={defaultName ?? ''}
      itemNameDescription={t('CreateLandingPageTemplateModal.BetaCreate.Description')}
      onSubmitText={onSubmitText || t('Create')}
      onTagCreate={handleCreateTag}
      onInputChange={onInputChange}
      onCancel={onDuplicateCancel || (() => setCreateBetaModal(false))}
      onClone={startId && !isLandingPageTemplateComposer ? createTemplateFromLandingPage : handleDuplicateCreate || createLandingPageTemplate}
      preventAutoClosingModal={newLPComposerTemplates}
      newLPComposerCreateBlank={newLPComposerTemplates}
      showLPToggleToIncludeChanges={showToggle}
      namePlaceholder={namePlaceholder ?? ''}
      includeUnpublishedChanges={includeUnpublishedChanges}
      onIncludeUnpublishedChangesToggle={setIncludeUnpublishedChanges}
      includeUnpublishedChangesLabel={includeUnpublishedChangesLabel}
      failToastError={isSaveFailed || submitFailed ? failToastError : undefined}
    />
  ) : (
    <Modal paddingV2 className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <Typography text={t('CreateLandingPageTemplateModal.Info')} {...ModalBodyStyle} className={`${rootClass}__info`} />
        {newLPComposerTemplates && <BetaBannerCard onToggle={handleToggle} isToggleOn={isBetaToggleOn} betaLink={BETA_LINK} />}
        <RadioCardGroup selectedOption={method} onSelect={(key: Key) => setMethod(key)}>
          <RadioCard
            title={
              <>
                <Typography text={t('CreateLandingPageTemplateModal.Options.New')} weight={TextWeight.MEDIUM} inline />
                {newLPComposerTemplates && isBetaToggleOn && <Pill text={t('BETA')} type={PillType.SECONDARY} size={PillSize.REGULAR} />}
              </>
            }
            description={t('CreateLandingPageTemplateModal.Options.New.Description')}
            svgName={SvgNames.createLandingPage}
            hoverSvgName={SvgNames.createLandingPageActive}
            key={CreateLandingPageTemplateMethod.NEW}
            className={`${rootClass}__new-option`}
          />
          <RadioCard
            title={t('CreateLandingPageTemplateModal.Options.LandingPage')}
            description={t('CreateLandingPageTemplateModal.Options.LandingPage.Description')}
            tooltipText={disableLandingPageOption && t('CreateLandingPageTemplateModal.Options.LandingPage.Tooltip')}
            disabled={disableLandingPageOption}
            svgName={SvgNames.createLandingPageFromTemplate}
            hoverSvgName={SvgNames.createLandingPageFromTemplateActive}
            disabledSvgName={SvgNames.createLandingPageFromTemplateDisabled}
            key={CreateLandingPageTemplateMethod.LANDING_PAGE}
            className={`${rootClass}__landing-page-option`}
          />
        </RadioCardGroup>
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        onClose={onCancel}
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonLabel: t(createButtonText),
          actionButtonOnClick: () =>
            newLPComposerTemplates && isBetaToggleOn && method === CreateLandingPageTemplateMethod.NEW
              ? setCreateBetaModal(true)
              : onCreate?.(method as CreateLandingPageTemplateMethod),
        }}
      />
    </Modal>
  )
}

export default CreateLandingPageTemplateModal
