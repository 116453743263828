import { CSSProperties, Dispatch, HTMLAttributeAnchorTarget, MouseEvent, MutableRefObject, ReactNode, RefObject, SetStateAction } from 'react'
import { ConnectDragSource } from 'react-dnd'

import { TFunction } from 'i18next'

import { ButtonType } from '@components/Button'
import { CheckboxProps } from '@components/Checkbox/Checkbox'
import { TooltipProps } from '@components/Tooltip/Tooltip'
import { TextType, TextWeight, TypographyProps } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { ReactSetStateAction } from '@interface/common'
import { Align, Side } from '@radix-ui/react-popper'
import {
  Cell,
  CellContext,
  Column,
  ColumnDef,
  ExpandedState,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingFn,
  SortingState,
  Table,
} from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/virtual-core/src'
import { Message } from '@utils/sms.utils'

import { CanDropCallback, TablePaginationState } from './types'
import { MenuItem } from '../../DropDownActions/DropDownActions'
import * as EmptyListing from '../../EmptyListing/EmptyListing'
import { SvgNames, SvgType } from '../../Svg'

interface Action<T = {}> {
  link?: string
  iconSize?: SvgType
  topSection?: ((rows: Row<T>) => boolean) | boolean
  hasTooltip?: boolean
  tooltipMessage?: ((row?: Row<T>) => string) | string
  tooltipAlign?: Align
  tooltipAlignOffset?: number
  disabledType?: string
  footerSectionLine?: boolean
  label?: ((row?: Row<T>) => string) | string
  icon?: ((row?: Row<T>) => ReactNode) | SvgNames
  inFooter?: ((rows: Row<T>) => boolean) | boolean
  hidden?: ((rows: Row<T>[] | Row<T>) => boolean) | boolean
  disabled?: ((row?: Row<T>) => boolean) | boolean
  inDropdown?: ((rows: Row<T>) => boolean) | boolean
}

export interface HeaderAction<T = {}> extends Action<T> {
  buttonType?: ButtonType
  deselectAllAction?: boolean
  embeddedComponent?: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement> | Event) => void
}

export interface RowAction<T = {}> extends Action<T> {
  onClick: (row: Row<T>) => void
  disabledType?: string
}

export interface TableV2RowData<T> {
  subRows?: T[]
}
export const isTableV2RowData = <T>(item: unknown): item is TableV2RowData<T> => item instanceof Object && 'subRows' in item

interface LoaderProps {
  loading: boolean
  component?: JSX.Element
}

export type OverrideCheckboxCellReturnType = {
  cellStatus: boolean
  cellValue: JSX.Element
}

export interface TableV2RadioTooltipProps {
  position?: Side
  withoutTail?: boolean
  sideOffset?: number
}

export interface TableV2Props<TData> {
  data: TData[]
  loading?: boolean
  allLoaded?: boolean
  rowEmptyText?: string
  isDraggable?: boolean
  enableRadio?: boolean
  resetSorting?: boolean
  enableSubRow?: boolean
  bodyMaxHeight?: number
  bodyMinHeight?: number
  styles?: CSSProperties
  enableFooter?: boolean
  manualSorting?: boolean
  enableSorting?: boolean
  stickyColumns?: string[]
  withoutBorders?: boolean
  showBottomBorder?: boolean
  showTopBorder?: boolean
  resetPageIndex?: boolean
  enableCheckbox?: boolean
  enablePaginate?: boolean
  sortingBy?: SortingState
  rowDisabledTitle?: string
  enableMultiSort?: boolean
  loaderProps?: LoaderProps
  manualPagination?: boolean
  enablePagesInput?: boolean
  pageSizeOptions?: number[]
  enableSpacerBlock?: boolean
  tableMenuItems?: MenuItem[]
  enableLazyLoading?: boolean
  enableOuterLoader?: boolean
  enableInsideLoader?: boolean
  enableStickyHeader?: boolean
  enableCalculatedRow?: boolean
  hasDisabledRowStyles?: boolean
  enableSortingRemoval?: boolean
  rowActions?: RowAction<TData>[]
  stickyHeaderTopPosition?: number
  enableSubRowSelection?: boolean
  forceResetSelectedRows?: boolean
  paginationState?: TablePaginationState
  columns: ColumnDef<TData, unknown>[]
  headerActions?: HeaderAction<TData>[]
  headerCheckboxDisabled?: boolean
  enableBodyHeightComputation?: boolean
  resetSelectionWhenPageChanges?: boolean
  defaultSelectedRows?: RowSelectionState
  defaultExpandedRows?: ExpandedState
  canDrop?: boolean | CanDropCallback<TData>
  stickyReference?: RefObject<HTMLDivElement>
  scrollableElementRef?: RefObject<HTMLDivElement>
  customSorts?: { [key: string]: SortingFn<TData> }
  emptyState?: EmptyListing.EmptyListingProps | JSX.Element
  tagProps?: Omit<TableV2TagsProps<TData>, 'cell' | 'tagState' | 'setTagState'>
  resetSelectedRowsOnChange?: any[]
  onLoading?: () => void
  onPaginationChange?: OnChangeFn<PaginationState>
  onSort?: (sortBy: SortingState) => void
  onRowExpanded?: (row: Row<TData>) => void
  customClass?: (row: Row<TData>) => string
  hasToExpand?: (row: Row<TData>) => boolean
  rowDisabled?: (row: Row<TData>) => boolean
  rowTooltip?: (row: Row<TData>) => string | ReactNode
  headerActionText?: (count: number) => string
  onRowCheckboxChange?: (row: Row<TData>) => void
  onHeaderCheckboxChange?: (table?: Table<TData>) => void
  isRowSelectionDisabled?: (row: Row<TData>) => boolean
  fetchData?: (pageIndex: number, pageSize: number) => void
  onRowSectionSelectionChanged?: (rows: Row<TData>[]) => void
  onRowClicked?: (row: Row<TData>, rows?: Row<TData>[]) => void
  onRowSelectionChanged?: (rowIds: string[], rows: Row<TData>[]) => void
  onRowsChanged?: (rows: Row<TData>[]) => void
  overrideCheckboxCell?: (row: Row<TData>) => OverrideCheckboxCellReturnType
  onRowsSort?: (selectedRows: Row<TData>[], droppedOnRow: Row<TData>, above: boolean, parentRowId?: string) => void
  /**
   * If not set, the `id` of the `row` will be the index of the row in the data.
   * For tables with `subRows`, it will be `index.subindex.subindex...`.
   * @warning Do not use this with `enableSubRow`,
   * the table currently cannot calculate things like indentation and thread lines with this prop set
   *  */
  rowUniqueIdKey?: ((originalRow: TData, index: number, parent?: Row<TData> | undefined) => string) | keyof TData
  showScrollArea?: boolean
  virtualScroller?: VirtualScrollerProps
  preserveScrollOnPagination?: boolean
  tooltipProps?: TableV2RadioTooltipProps
  dataTest?: string
}

export interface CommonV2RowProps<T> {
  rows: Row<T>[]
  isExpanded?: boolean
  hoveredRowID?: string
  enableSubRow?: boolean
  stickyColumns?: string[]
  stickyColsWidths?: number[]
  rowActions?: RowAction<T>[]
  tagProps: TableV2Props<T>['tagProps']
  enableSpacerBlock: TableV2Props<T>['enableSpacerBlock']
  rowRef: React.MutableRefObject<HTMLTableRowElement | null>
  rowDisabled?: (row: Row<T>) => boolean
  hasDisabledRowStyles?: boolean
  hasRowDisabledTitle?: boolean
  hasToExpand?: TableV2Props<T>['hasToExpand']
  onRowExpanded?: TableV2Props<T>['onRowExpanded']
  onRowClicked?: (row: Row<T>, rows?: Row<T>[]) => void
  setHoveredRowID: ReactSetStateAction<string | undefined>
  customClass?: (row: Row<T>) => string
}

export interface CommonV2CellProps<T> {
  row: Row<T>
  parentRows: Row<T>[]
  caretCellID: string
  hoveredRowID?: string
  cell: Cell<T, unknown>
  column: Column<T, unknown>
  hasToExpand?: TableV2Props<T>['hasToExpand']
  onRowExpanded?: TableV2Props<T>['onRowExpanded']
}

export interface DraggableRowV2Props {
  isDraggable?: boolean
  isOver?: boolean
  canDrop?: boolean
  isOverTop?: boolean
  isDragging?: boolean
  draggableRootClassName?: string
  dragRef?: ConnectDragSource
}

export interface IndeterminateSelect extends CheckboxProps {
  className?: string
  checked: boolean
  dataTest?: string
  disabled?: boolean
  indeterminate?: boolean
  onChange: (event: boolean) => void
  tooltip?: string | ReactNode
}

export interface ResizingTagsProps {
  defaultTagsNumber: number
  maxWindowWidth: number
  readOnlyTags: boolean
  tagsColumnWidthLimitRef: MutableRefObject<number | null>
  tagsGap: number
  tagsRef: RefObject<HTMLDivElement>
  tagWidth: number
  waitingTagRef: RefObject<HTMLDivElement>
  setTagState: Function
}

export interface TableTagState {
  hover: boolean
  openedRowID: string
  numberOfTagsDisplayed: number
}

export interface TagColProps<T> {
  appliedTags: LabelDto[]
  disabled?: boolean
  readOnlyTags: boolean
  rootClass: string
  row: Row<T>
  selectedTagId?: number
  tags: LabelDto[]
  numberOfTagsDisplayed: number
  tagsRef: RefObject<HTMLDivElement>
  waitingTagRef: RefObject<HTMLDivElement>
  t: TFunction
  tagAction?: (name: string) => void
  onCreateTag?: (tag: LabelDto) => void
  setTagState: ReactSetStateAction<TableTagState>
  onApplyAndRemoveTags: (rowID: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
}

export interface RenderTagManagerTriggerParams {
  appliedTags: LabelDto[]
  numberOfTagsDisplayed: number
  rootClass: string
}

export interface TableV2TagsProps<T> {
  tags?: LabelDto[]
  cell: Cell<T, unknown>
  disabled?: boolean
  selectedTagId?: number
  readOnlyTags?: boolean
  tagState: TableTagState
  defaultTagsNumber?: number
  tagAction?: (name: string) => void
  onCreateTag?: (tag: LabelDto) => void
  setTagState: ReactSetStateAction<TableTagState>
  onApplyAndRemoveTags?: (rowID: number[], tagsToApply: LabelDto[], tagsToRemove: number[]) => void
}

export interface MutedColProps<T> {
  cell: CellContext<T, unknown>
}

interface LinkToProp {
  pathname: string
}

export interface EnhancedColWithTitleProps<T> {
  title?: string
  linkTo?: LinkToProp
  extraContent?: ReactNode
  cell: CellContext<T, unknown>
  target?: HTMLAttributeAnchorTarget
}

export interface ColWithTitleLinkProps {
  row: CellContext<Message, unknown>
  titleCol?: string
  linkTo: LinkToProp
  disabled?: boolean
}

export interface ColWithTitleProps<T> {
  cell: CellContext<T, unknown>
  title?: string
}

export interface ColWithTooltipProps<T, D = unknown> {
  cell: CellContext<T, D>
  tooltipTitle?: ReactNode
  typographySpecifiedTitle?: string | JSX.Element
  weight?: TextWeight
  type?: TextType
  dataTest?: string
  tooltipProps?: Omit<TooltipProps, 'trigger'>
  typographyProps?: TypographyProps
}

export interface EnhancedColWithTooltipProps<T> extends ColWithTooltipProps<T> {
  extraContent: ReactNode
  rootClass?: string
}

export interface TableV2CheckboxProps<T> {
  rowSelection: RowSelectionState
  rowDisabledTitle?: string
  headerCheckboxDisabled?: boolean
  rowTooltip?: TableV2Props<T>['rowTooltip']
  overrideCheckboxCell?: TableV2Props<T>['overrideCheckboxCell']
  onRowCheckboxChange?: TableV2Props<T>['onRowCheckboxChange']
  onHeaderCheckboxChange?: TableV2Props<T>['onHeaderCheckboxChange']
}

export interface TableV2RadioProps<T> {
  rowDisabledTitle?: string
  rowTooltip?: TableV2Props<T>['rowTooltip']
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
  tooltipProps?: TableV2RadioTooltipProps
}

export interface VirtualScrollerProps {
  enabled: boolean
  estimatedHeight?: number
  containerHeight?: number
  virtualItems?: VirtualItem[]
}

export interface TableV2RowProps<T extends {}> extends CommonV2RowProps<T> {
  row: Row<T>
  rootClass: string
  dataTest?: string
  caretCellID: string
  isDraggable?: boolean
  firstColumnsID?: string
  isCellClickable?: boolean
  draggableRowProps?: DraggableRowV2Props
  style?: CSSProperties
}
