import { useContext } from 'react'

import { TFunction } from 'i18next'

import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { formatContactsUtils } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { GroupDefinitionDto, RowDefinitionDto, SegmentDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { Folder } from '@interface/Folder'
import { SegmentInput, SegmentSyncType } from '@interface/Segment'
import { updateGroup } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import { FieldType, getNewRowId } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { useSegmentComposerCategorizationRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.categorization.graphQL'
import { useSegmentComposerClassicRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.classic.graphQL'
import { useSegmentComposerListRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.list.graphQL'
import { FactorType, SEGMENT_COMPOSER_CREATE_URL, SEGMENT_COMPOSER_EDIT_URL } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext, SegmentComposerState } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { caseInsensitiveAlphabeticSort, filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { buildSegment, buildSegmentHierarchy, buildSegments, getSegmentPath } from '@utils/contactSegments/contactSegments.utils'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'
import { useSegmentService } from '@utils/hooks/microservices/useSegmentService'
import { logNewRelicError } from '@utils/new-relic.utils'

const getSegmentHierarchyFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.SegmentHierarchy'),
  }
}

const getSubscriptionCategoriesFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.SubscriptionCategories'),
  }
}

const getCampaignFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.Campaigns'),
  }
}

const getScoreSheetFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.ScoreSheets'),
  }
}

const getSendersFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.Senders'),
  }
}

const getUnifiedFieldsFailMessage = (t: TFunction) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t('SegmentComposer.StatusToast.FailMessage.UCLFields'),
  }
}

const TextTypes = ['TEXT', 'EMAIL']
const NumericTypes = ['SCORE', 'NUMBER']
const DateTypes = ['DATE', 'DATETIME']
const BooleanTypes = ['BOOLEAN']

const getFieldType = (dataType: string) => {
  if (TextTypes.includes(dataType)) {
    return FieldType.TEXT
  }
  if (NumericTypes.includes(dataType)) {
    return FieldType.NUMERIC
  }
  if (DateTypes.includes(dataType)) {
    return FieldType.DATE
  }
  if (BooleanTypes.includes(dataType)) {
    return FieldType.BOOLEAN
  }
  return FieldType.TEXT
}

export const getSegmentPathBreadcrumb = (segmentPath: BreadcrumbsItemType[], name: string) => {
  return [{ text: 'All Contacts', hasTooltip: true }, ...segmentPath, { text: name, hasTooltip: true, svgName: SvgNames.segment }]
}

const getSegmentComposerEditUrl = (id: string) => {
  return `${SEGMENT_COMPOSER_EDIT_URL}/${id}`
}

const getSegmentComposerCreateUrl = (type: 'direct' | 'query', parentId?: string) => {
  return `${SEGMENT_COMPOSER_CREATE_URL}/${type}${parentId ? `/${parentId}` : ''}`
}

/**
 * Opens the Segment Composer in create mode.
 *
 * @param {('direct' | 'query')} type - The type of segment to create.
 * @param {string} [parentId] - The optional parent ID for the segment.
 */
export const openSegmentComposerToCreate = (type: 'direct' | 'query', parentId?: string) => {
  window.open(getSegmentComposerCreateUrl(type, parentId), '_blank')
}

/**
 * Opens the Segment Composer in edit mode.
 *
 * @param {string} id - The ID of the segment to edit.
 */
export const openSegmentComposerToEdit = (id: string) => {
  window.open(getSegmentComposerEditUrl(id), '_blank')
}

/**
 * Retrieves the last position of the rows in the targetGroup.
 * @param targetGroup
 * @returns The last position of the rows in the targetGroup.
 */
const getRowLastPosition = (targetGroup: GroupDefinitionDto) => {
  return targetGroup.rows?.filter(filterNotEmptyArray).sort((a, b) => (a.position > b.position ? -1 : 1))[0].position || 0
}

export const useAddRow = () => {
  const {
    update,
    values: { uclFieldsOptions },
  } = useContext(SegmentComposerContext)

  /**
   * Add a new default row to the last position of the targetGroup.
   *
   * @param targetGroup - The `GroupDefinitionDto` to add the new row to.
   * @param segmentDefinition - The current `SegmentDefinitionDto` being edited.
   */
  const addRow = (targetGroup: GroupDefinitionDto, segmentDefinition: SegmentDefinitionDto) => {
    const { group } = segmentDefinition
    const emailField = uclFieldsOptions.find((option) => option.extraOptions?.standardFieldKey === 'email')

    const updatedGroup = {
      ...targetGroup,
      rows: [
        ...(targetGroup.rows as RowDefinitionDto[]),
        {
          rowId: getNewRowId(),
          factor: { type: FactorType.Profile, profileFactor: { fieldColumnIndex: emailField?.value } },
          position: getRowLastPosition(targetGroup) + 1,
        },
      ],
    } as GroupDefinitionDto

    if (group) {
      const updatedRootGroup = updateGroup(group, targetGroup, updatedGroup)
      update({
        segmentDefinition: {
          ...segmentDefinition,
          group: updatedRootGroup,
        },
      })
    }
  }

  return {
    addRow,
  }
}

export const useLoadAllAssets = (
  segment: SegmentInput,
  update: (newState: Partial<SegmentComposerState>) => void,
  isNew: boolean
): { loadAllAssets: (segmentId?: string) => void } => {
  const { getAllTags, getAllFolders, getAllActOnContactsSegment } = useCategorizationService()
  const { getScoreSheetsRequest, getUnifiedFieldsRequest } = useSegmentComposerListRequests()
  const { getCampaignsRequest, getVerifiedSendersRequest, getSubscriptionCategoriesRequest } = useSegmentComposerClassicRequests()
  const { getSegmentHierarchyRequest } = useSegmentComposerCategorizationRequests()
  const { getColumnsRequest, getContactsRequest } = useSegmentService()

  const { t } = useTranslation()

  const loadAssets = async () => {
    const getAllTagsPromise = getAllTags({ types: [ItemType.SEGMENT] })
    const getAllFoldersPromise = getAllFolders({ type: ItemType.SEGMENT })
    const getAllActOnContactsSegmentPromise = getAllActOnContactsSegment()
    const [tags = [], folders = [], allContactsSegment] = await Promise.all([
      getAllTagsPromise,
      getAllFoldersPromise,
      getAllActOnContactsSegmentPromise,
    ])
    update({ tags, folders: folders as Folder[], allContactsSegment: allContactsSegment ? buildSegment(allContactsSegment) : undefined })
  }

  const loadUCLFields = async () => {
    try {
      const { data, errors } = await getUnifiedFieldsRequest()

      if (!!data?.unifiedListFieldMappings) {
        update({
          uclFieldsOptions: (data.unifiedListFieldMappings as UnifiedListFieldMapping[])
            .filter((field) => field && !field.hidden && !field.deleted)
            .map(({ displayName = '', dataType = '', columnIndex, standardFieldKey }) => ({
              label: displayName,
              value: columnIndex.toString(),
              extraOptions: { type: getFieldType(dataType), standardFieldKey },
            })),
        })
      } else if (errors) {
        update({ statusToast: getUnifiedFieldsFailMessage(t) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: getUnifiedFieldsFailMessage(t) })
      logNewRelicError(e)
    }
  }

  const loadScoreSheets = async () => {
    try {
      const { data, errors } = await getScoreSheetsRequest()
      if (!!data?.scoreSheets) {
        update({
          scoreSheetOptions: data.scoreSheets.map((scoreSheet) => ({ label: scoreSheet?.name, value: scoreSheet?.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: getScoreSheetFailMessage(t) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: getScoreSheetFailMessage(t) })
      logNewRelicError(e)
    }
  }

  const loadSenders = async () => {
    try {
      const { data, errors } = await getVerifiedSendersRequest()
      if (!!data?.loadFromAddressesPage) {
        update({
          senderOptions: data.loadFromAddressesPage
            .filter((sender) => sender.isVerified)
            .map((sender) => ({
              label: sender.email ?? '',
              value: sender.uuid ?? '',
            }))
            .sort((a, b) => caseInsensitiveAlphabeticSort(a.label, b.label)),
        })
      } else if (errors) {
        update({ statusToast: getSendersFailMessage(t) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: getSendersFailMessage(t) })
      logNewRelicError(e)
    }
  }

  const loadCampaigns = async () => {
    try {
      const { data, errors } = await getCampaignsRequest()
      if (!!data?.campaigns) {
        update({
          campaignOptions:
            data.campaigns
              .filter((campaign) => campaign.id)
              .map((campaign) => ({ label: campaign.name, value: campaign.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: getCampaignFailMessage(t) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: getCampaignFailMessage(t) })
      logNewRelicError(e)
    }
  }

  const loadSubscriptionCategories = async () => {
    try {
      const { data, errors } = await getSubscriptionCategoriesRequest()
      if (!!data?.getSubscriptionCategories) {
        update({
          subscriptionCategories:
            data.getSubscriptionCategories
              .filter((category) => !!category)
              .map((category) => ({ label: category?.name, value: category?.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: getSubscriptionCategoriesFailMessage(t) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: getSubscriptionCategoriesFailMessage(t) })
      logNewRelicError(e)
    }
  }

  const loadSegmentHierarchy = async (segmentId?: string): Promise<SegmentInput | undefined> => {
    if (segmentId) {
      try {
        const { data, errors } = await getSegmentHierarchyRequest(segmentId)
        if (!!data?.getItemHierarchyByExternalId) {
          const segments = buildSegments(data.getItemHierarchyByExternalId)
          const segment = segments.find((item) => item?.externalId === segmentId)
          if (segment) {
            const segmentHierarchy = buildSegmentHierarchy(segments)
            const segmentPath = getSegmentPath(segmentId, segmentHierarchy)
              .filter((segment) => isNew || segment.externalId !== segmentId)
              .map(({ name }) => ({ text: name, hasTooltip: true }))
              .reverse()

            if (isNew) {
              update({ segmentHierarchy, segmentPath })
            } else {
              const segmentInput: SegmentInput = {
                folderId: segment.folderId,
                id: segment.externalId,
                labels: segment.tags,
                name: segment.name,
                description: '', // TODO: doesn't exists yet in the segment
                createdBy: 'John Doe', // TODO: the segment has the authorId, we need to get the user name
                lastModifiedBy: '', // TODO: doesn't exists yet in the segment
                createdDate: segment.createdTime ? new Date(segment.createdTime).getTime() : Date.now(),
                lastModifiedDate: segment.updatedTime ? new Date(segment.updatedTime).getTime() : Date.now(),
                syncType: SegmentSyncType.DYNAMIC,
                isDirectSelect: segment.type === 'Direct Select',
              }
              update({ segmentHierarchy, segmentPath, segment: segmentInput })
              return segmentInput
            }
          } else {
            update({ statusToast: getSegmentHierarchyFailMessage(t) })
          }
        } else if (errors) {
          update({ statusToast: getSegmentHierarchyFailMessage(t) })
          logNewRelicError(errors)
        }
      } catch (e) {
        update({ statusToast: getSegmentHierarchyFailMessage(t) })
        logNewRelicError(e)
      }
    }
  }

  const getColumns = async (externalId: string, headers: string[]) => {
    const columns = await getColumnsRequest(externalId, headers)
    return columns ?? headers.map((name, id) => ({ name, id } as Column))
  }

  const loadContacts = async ({ id } = segment) => {
    const page: PageInput = {
      id,
      pageNumber: 0,
      pageSize: -1,
      search: '',
    }
    const contactsPage = await getContactsRequest(page)
    if (contactsPage) {
      const columns = id ? await getColumns(id, contactsPage.headers as string[]) : []
      const selectedContacts = formatContactsUtils(contactsPage, columns)
      update({ selectedContacts, columns })
    }
  }

  const loadAllAssets = async (segmentId?: string) => {
    try {
      const [segmentInput] = await Promise.all([
        loadSegmentHierarchy(segmentId),
        loadAssets(),
        loadCampaigns(),
        loadScoreSheets(),
        loadSenders(),
        loadSubscriptionCategories(),
        loadUCLFields(),
      ])
      if (!isNew && segmentInput?.id && segmentInput.isDirectSelect) {
        // this function should only be called while editing an existing direct select segment
        await loadContacts(segmentInput)
      }
    } finally {
      update({ loadingAssets: false })
    }
  }

  return {
    loadAllAssets,
  }
}
