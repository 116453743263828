import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { LaunchListingEntry } from '@graphql/types/query-types'
import { FilterDefinition } from '@utils/filter'

import { SmsColumnNames } from './SmsPickerModal.tables'

export interface SmsPickerUi {
  id: string
  name: string
  timestamp: number
  userName: string
}

export enum SmsPickerType {
  SENT = 'SENT',
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
}

export interface SmsPickerSourceProps {
  allItemFilter: FilterDefinition
  columnNames: SmsColumnNames
  i18nListPageKey: string
  label: string
}

export const convertSmsListingEntryToPickerMessage = (listingEntry: LaunchListingEntry): SmsPickerUi => {
  return {
    id: listingEntry.launchId ?? '',
    name: listingEntry.title ?? '',
    timestamp: listingEntry.status === 'SCHEDULED' ? listingEntry.scheduledSendTime ?? 0 : listingEntry.lastModifiedTime ?? 0,
    userName: listingEntry.userFriendlyName ?? '--',
  }
}

export const convertSmsListingEntryToItemDto = (listingEntry: LaunchListingEntry): ListingPageItem => {
  const message = convertSmsListingEntryToPickerMessage(listingEntry)
  const dto: ListingPageItem = { name: message.name, id: message.id, externalId: message.id }
  dto.item = JSON.stringify(message)
  return dto
}
