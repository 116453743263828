import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { ApolloClient, InMemoryCacheConfig } from '@apollo/client'
import { isProd } from '@const/globals'
import { createClient, getEnvironmentLocation } from '@utils/apollo'
import { getAuthenticatedPromise as getAuthenticatedPromiseUtil } from '@utils/auth'

// PLEASE KEEP THIS LIST IN ALPHABETICAL ORDER
export enum MicroserviceClients {
  ACCOUNT = 'account',
  CATEGORIZATION = 'categorization',
  CHATGPT_EMAIL = 'chatgpt-email',
  CRM = 'crm',
  CRM_ENTITY_MAPPING = 'crm-entity-mapping',
  CRM_SYNC = 'crm-sync',
  DATA_LAKE_QUERY = 'data-lake-query',
  EMAIL_EVENT = 'email-event',
  EMAIL_MANAGEMENT = 'email-management',
  EMAIL_MANAGEMENT_PROXY = 'email-management-proxy',
  EMAIL_SUPPRESSION = 'email-suppression',
  ENTITY_UPLOAD = 'entity-upload',
  ENTITY_JOIN = 'entity-join-proxy',
  FILE_UPLOAD = 'file-upload',
  FILE_UPLOAD_PROXY = 'file-upload-proxy',
  FORM = 'forms-proxy',
  HOT_PROSPECTS = 'hot-prospects',
  LIST = 'list',
  ML_AUDIENCE = 'ml-audience',
  SEGMENT = 'segment',
  SEGMENT_DEFINITION = 'segment-definition-proxy',
  SMS_MANAGEMENT = 'sms-management',
  SMS_REPORTING = 'sms-reporting',
  SSO_AUTH_PROXY = 'sso-auth-proxy',
  THOUGHTSPOT = 'thoughtspot',
  USER_SETTINGS = 'user-settings',
  WEBHOOKS_INCOMING_MANAGEMENT = 'webhooks-incoming-management',
}

export interface UseMicroserviceClientParams {
  serviceName: MicroserviceClients
}

const getFallBackUrl = (serviceName: MicroserviceClients) => {
  const { aorLocation, prefix } = getEnvironmentLocation()
  const onProd = isProd()

  return `https://${prefix}${serviceName}-service.${aorLocation}.${onProd ? '' : 'dev-'}act-on.net`
}

export const getAuthenticatedPromise = (serviceName: MicroserviceClients, path: string, token: string, AOAccountContext: string): Promise<any> => {
  if (token === 'storybook') {
    return Promise.resolve('storybook')
  }
  const url = `${getFallBackUrl(serviceName)}${path}`

  return getAuthenticatedPromiseUtil(url, token, AOAccountContext)
}

export interface CreateMicroserviceClientParams {
  microserviceUrls: { [key: string]: string }
  serviceName: string
  accountId: string
  userId: string
  token: string
  aoAccountContext: string
  cacheConfig?: InMemoryCacheConfig
}

export const getMicroserviceTransactionId = (accountId: string, userId: string) => `${accountId}-${userId}-${new Date().getTime()}`

export const createMicroserviceClient = (params: CreateMicroserviceClientParams) => {
  const { microserviceUrls, serviceName, token, aoAccountContext, accountId, userId, cacheConfig } = params
  return createClient(
    `${microserviceUrls[`${serviceName}-service`]}/graphql`,
    token,
    aoAccountContext,
    getMicroserviceTransactionId(accountId, userId),
    cacheConfig
  )
}

export const useMicroserviceClient = ({ serviceName }: UseMicroserviceClientParams, cacheConfig?: InMemoryCacheConfig) => {
  const {
    token,
    aoAccountContext,
    microserviceUrls,
    accountSettings: { accountId, userId },
  } = useSelector((state: any) =>
    state
      ? state.account.account
      : {
          token: 'storybook',
          aoAccountContext: 'storybook',
          microserviceUrls: ['storybook'],
          accountSettings: { accountId: 'storybook', userId: 'storybook' },
        }
  )

  const clientParams: CreateMicroserviceClientParams = {
    microserviceUrls,
    serviceName,
    accountId,
    userId,
    token,
    aoAccountContext,
    cacheConfig,
  }

  const isInitialMount = useRef<boolean>(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const refreshTimer = setTimeout(() => setClient(createMicroserviceClient(clientParams)), 60000)
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [token])

  const [client, setClient] = useState<ApolloClient<any>>(createMicroserviceClient(clientParams))
  return { client, token, aoAccountContext }
}

export const useMicroserviceUrl = ({ serviceName }: UseMicroserviceClientParams) => {
  const { microserviceUrls } = useSelector((state: any) => state.account.account)

  if (microserviceUrls) {
    const serviceToDiscover = `${serviceName}-service`
    return serviceToDiscover in microserviceUrls ? `${microserviceUrls[serviceToDiscover]}` : undefined
  }
}

export default useMicroserviceClient
