import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import SelectV2 from '@components/SelectV2/SelectV2'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { PaginationSelectMenuPlacement } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { OnChangeFn, PaginationState, Table } from '@tanstack/react-table'

import './TableV2Pagination.css'

interface PaginationTooltipProps {
  dataTest: string
  disabled: boolean
  isActive: boolean
  buttonText: string
  iconName: SvgNames
  onClick: () => void
}

interface TableV2PaginationProps<T> {
  dataTest?: string
  onPaginationChange?: OnChangeFn<PaginationState>
  table: Table<T>
  resetPageIndex?: boolean
  enablePagesInput?: boolean
  pageSizeOptions?: number[]
  fetchData?: (pageIndex: number, pageSize: number) => void
  insideModal?: boolean
  preserveScrollPosition?: boolean
  dropdownPosition?: PaginationSelectMenuPlacement
}

const rootClass = 'table-pagination'

const DEBOUNCE_TIME = 200
export const MIN_PAGINATION_SIZE = 10

const PaginationTooltip = ({ dataTest, disabled, isActive, buttonText, iconName, onClick }: PaginationTooltipProps) => (
  <Tooltip
    trigger={
      <button
        data-test={dataTest}
        disabled={disabled}
        onClick={onClick}
        className={classNames({
          [`${rootClass}__arrow-background`]: true,
          [`${rootClass}__arrow-background-active`]: isActive,
        })}
      >
        <Svg name={iconName} type={SvgType.LARGER_ICON} />
      </button>
    }
    position="top"
  >
    {buttonText}
  </Tooltip>
)

export const TableV2Pagination = <T extends {}>({
  dataTest = rootClass,
  onPaginationChange,
  table,
  resetPageIndex,
  enablePagesInput = true,
  pageSizeOptions = [10, 20, 50, 100],
  fetchData,
  insideModal = false,
  preserveScrollPosition = false,
  dropdownPosition = 'auto',
}: TableV2PaginationProps<T>) => {
  const pageCount = table.getPageCount()
  const paginationRef = createRef<HTMLDivElement>()
  const { pageIndex, pageSize } = table.getState().pagination
  const [inputValue, setInputValue] = useState<string | number>(pageIndex + 1)
  const pageContainer = useRef(document.getElementsByClassName('page-container')[0])

  const { t } = useTranslation()

  useEffect(() => {
    const [prevHeight, prevScrollTop] = getHeightAndScrollTop()
    if (!onPaginationChange) {
      fetchData?.(pageIndex, pageSize)
    }
    debouncedUpdateScrollTop(prevHeight, prevScrollTop)
  }, [pageIndex, pageSize])

  const debouncedUpdateScrollTop = useDebouncedCallback((prevHeight: number, prevScrollTop: number) => {
    const [nextHeight, nextScrollTop] = getHeightAndScrollTop()

    if (preserveScrollPosition && prevHeight !== nextHeight) {
      const scrollDiff = nextScrollTop - prevScrollTop
      pageContainer.current.scrollTop += nextHeight - prevHeight - scrollDiff
    }
  }, DEBOUNCE_TIME)

  const getHeightAndScrollTop = useCallback((): [number, number] => {
    return [paginationRef.current?.parentElement?.offsetHeight || 0, pageContainer.current?.scrollTop || 0]
  }, [paginationRef])

  const debouncedGotoPage = useDebouncedCallback((pageIdx: number) => {
    table.setPageIndex(pageIdx)
  }, DEBOUNCE_TIME)

  const debouncedInputGotoPage = useDebouncedCallback((pageIdx: number) => {
    table.setPageIndex(pageIdx)
  }, DEBOUNCE_TIME * 3)

  const debouncedNextPage = useDebouncedCallback(() => {
    table.nextPage()
  }, DEBOUNCE_TIME)

  const debouncedPreviousPage = useDebouncedCallback(() => {
    table.previousPage()
  }, DEBOUNCE_TIME)

  useEffect(() => {
    setInputValue(pageIndex + 1)
  }, [pageIndex])

  useEffect(() => {
    if (resetPageIndex) {
      debouncedGotoPage(0)
    }
  }, [resetPageIndex])

  const closeOptionsOnScroll = useCallback((event: Event) => {
    if (event.type === 'scroll') {
      return true
    }
    return false
  }, [])

  return (
    <div className={rootClass} data-test={dataTest} ref={paginationRef}>
      <div className={`${rootClass}__controls`}>
        <Typography text={t('Records per page:')} />
        <div className={`${rootClass}__select`}>
          <SelectV2
            isPaginator
            defaultValue={{
              label: String(pageSize),
              value: String(pageSize),
            }}
            options={pageSizeOptions.map((pageSize) => ({ label: String(pageSize), value: String(pageSize) }))}
            onChange={(e) => {
              table.setPageIndex(0)
              table.setPageSize(Number(e?.label) || pageSize)
            }}
            insideModal={insideModal}
            closeMenuOnScroll={closeOptionsOnScroll}
            menuPlacement={dropdownPosition}
          />
        </div>
      </div>
      <div className={classNames({ [`${rootClass}__controls`]: true, [`${rootClass}__controls-without-input`]: !enablePagesInput })}>
        <div className={`${rootClass}__btn`}>
          <PaginationTooltip
            dataTest={`${dataTest}-first-page-button`}
            buttonText={t('First Page')}
            onClick={() => debouncedGotoPage(0)}
            isActive={table.getCanPreviousPage()}
            disabled={!table.getCanPreviousPage()}
            iconName={SvgNames.paginationControlsStart}
          />
          <PaginationTooltip
            dataTest={`${dataTest}-previous-page-button`}
            buttonText={t('Previous page')}
            onClick={() => debouncedPreviousPage()}
            isActive={table.getCanPreviousPage()}
            disabled={!table.getCanPreviousPage()}
            iconName={SvgNames.paginationControlsBack}
          />
        </div>

        {enablePagesInput && (
          <div className={`${rootClass}__count`}>
            <Typography text={t('Page')} />
            <div className={`${rootClass}__count-input`}>
              <input
                data-test={`${dataTest}-page-input`}
                type="number"
                className={classNames({ input: true })}
                value={inputValue}
                min={0}
                onChange={(e) => {
                  const targetValue = Math.abs(Number(e.target.value))
                  const page = targetValue < 0 ? inputValue : targetValue > pageCount ? pageCount - 1 : targetValue - 1
                  setInputValue(targetValue ? targetValue : '')
                  targetValue && debouncedInputGotoPage(Number(page))
                }}
              />
            </div>
            <Typography dataTest={`${dataTest}-total-pages`} text={t(`of {{count}}`, { count: pageCount })} />
          </div>
        )}
        <div className={`${rootClass}__btn`}>
          <PaginationTooltip
            dataTest={`${dataTest}-next-page-button`}
            buttonText={t('Next page')}
            onClick={() => debouncedNextPage()}
            isActive={table.getCanNextPage()}
            disabled={!table.getCanNextPage()}
            iconName={SvgNames.paginationControlsNext}
          />
          <PaginationTooltip
            dataTest={`${dataTest}-last-page-button`}
            buttonText={t('Last page')}
            onClick={() => debouncedGotoPage(pageCount - 1)}
            isActive={table.getCanNextPage()}
            disabled={!table.getCanNextPage()}
            iconName={SvgNames.paginationControlsEnd}
          />
        </div>
      </div>
    </div>
  )
}
