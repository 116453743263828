import { useMemo } from 'react'

import { FetchPolicy, useApolloClient } from '@apollo/client'
import getLaunchesForAccountPaginated from '@graphql/queries/getLaunchesForAccountPaginated'
import getMessageDetails from '@graphql/queries/getMessageDetails'
import {
  GetLaunchesForAccountPaginatedQuery,
  GetLaunchesForAccountPaginatedQueryVariables,
  GetMessageDetailsQuery,
  GetMessageDetailsQueryVariables,
} from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useSmsPickerRequests = () => {
  const actonClient = useApolloClient()
  const fetchPolicy: FetchPolicy = 'cache-first'
  const options = { fetchPolicy }

  return useMemo(
    () => ({
      getLaunchesForAccountPaginatedRequest: createGraphQLQuery<GetLaunchesForAccountPaginatedQuery, GetLaunchesForAccountPaginatedQueryVariables>(
        actonClient,
        getLaunchesForAccountPaginated,
        options
      ),
      getMessageDetailsRequest: createGraphQLQuery<GetMessageDetailsQuery, GetMessageDetailsQueryVariables>(actonClient, getMessageDetails),
    }),
    [actonClient]
  )
}
