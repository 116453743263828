import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { useTranslation } from '@const/globals'
import { CombineLabel } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CombineLabel/CombineLabel'
import { LogicalOperator } from '@src/pages/SegmentComposer/SegmentComposer.constants'

import './SegmentDefinitionElementWithOperator.css'

interface SegmentDefinitionElementWithOperatorProps {
  children: ReactNode
  className?: string
  dataTest?: string
  isPreview?: boolean
  operator?: LogicalOperator
}

const rootClass = 'segment-definition-element-with-operator'

const SegmentDefinitionElementWithOperator: FC<SegmentDefinitionElementWithOperatorProps> = (props: SegmentDefinitionElementWithOperatorProps) => {
  const { dataTest = rootClass, children, className = '', isPreview = false, operator } = props

  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {operator && (
        <CombineLabel
          dataTest={`${dataTest}-operator`}
          className={classNames(`${rootClass}__operator`, { [`${rootClass}__operator-preview`]: isPreview })}
          label={t(operator)}
        />
      )}
      {children}
    </div>
  )
}

export default SegmentDefinitionElementWithOperator
