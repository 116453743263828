import React, { ReactNode } from 'react'
import { Cell } from 'react-table'

import { ApolloClient } from '@apollo/client'
import Button, { ButtonType } from '@components/Button/Button'
import { Column, ColumnStatus, ColumnType } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { CONTACTS_DETAILS_URL, ContactsTableActions } from '@components/ContactsDetails/ContactsDetails.constants'
import { formGeoIPFields, formSubmissionFields } from '@components/EditColumnsDropdown/utils/EditColumnsDropdown.constants'
import { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { isNonSendableSegment } from '@components/SegmentDetails/SegmentDetails.utils'
import { FilesData } from '@components/SegmentDetails/SegmentDetailsContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames, SvgType } from '@components/Svg'
import { ColWithTitle } from '@components/Table/components/tableColumns'
import { HeaderAction, RowAction, TableColumn, TableColumnAlign } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { ColWithTitle as ColWithTitleV2 } from '@components/TableV2/utils/tableV2ColumnUtils'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext } from '@const/globals'
import deleteContacts from '@graphql/microservices/list/deleteContacts'
import changeBounceStatus from '@graphql/microservices/segment/changeBounceStatus'
import columnIndexOrder from '@graphql/microservices/segment/columnIndexOrder'
import columnIndexOrderToSubSegments from '@graphql/microservices/segment/columnIndexOrderToSubSegments'
import getColumnIndexOrder from '@graphql/microservices/segment/getColumnIndexOrder'
import getContacts from '@graphql/microservices/segment/getContacts'
import getContactsFromBounce from '@graphql/microservices/segment/getContactsFromBounce'
import removeContactsFromSegment from '@graphql/microservices/segment/removeContactsFromSegment'
import { DeleteContactsMutation, DeleteContactsMutationVariables, UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import {
  ChangeBounceStatusMutation,
  ChangeBounceStatusMutationVariables,
  GetColumnIndexOrderQuery,
  GetColumnIndexOrderQueryVariables,
  GetContactsFromBounceQuery,
  GetContactsFromBounceQueryVariables,
  GetContactsQuery,
  GetContactsQueryVariables,
  PageInput,
  RemoveContactsFromSegmentMutation,
  RemoveContactsFromSegmentMutationVariables,
} from '@graphql/types/microservice/segment-types'
import { CellContext, Row } from '@tanstack/react-table'
import { BouncesSegments, bouncesVerbs, ItemType, smsBouncesVerbs } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { sendEmailToContact } from '@utils/contact/contact.utils'
import { ContactViewPanel, openContactView } from '@utils/contacts'
import { goEditSegment, saveSelectedContactsToLocalStorage, Segment } from '@utils/contactSegments/contactSegments.utils'
import { FileUplaodModalType } from '@utils/formUtils'
import { getFormattedNumber } from '@utils/numbers'
import { isValidJson, parseJsonSafely } from '@utils/utils'

const HEADER_CHAR_WIDTH = 11
const COLUMN_VALUE_CHAR_WIDTH = 12
const PADDING = 24
const TABLE_ACTION_WIDTH = 60
export const uuidColumns = ['Uuid', '_AO_UUID']

export const getTableLabel = (search: string, totalCount: number, loading: boolean, t: Function, itemLabel = 'Contacts') => {
  return search !== '' && !loading
    ? `${t(`Result${totalCount !== 1 ? 's' : ''}`)} ${t('for')} "${search}"`
    : `${getFormattedNumber(totalCount)} ${t(itemLabel)}`
}

const getColumnWidth = (columnData: any, accessor: string, headerText: string, maxCellWidth: number) => {
  const maxWidth = maxCellWidth
  const columnWidth = Math.max(
    ...columnData.map((cellData: any) => (`${cellData[accessor]}` || '').length * COLUMN_VALUE_CHAR_WIDTH),
    headerText.length * HEADER_CHAR_WIDTH
  )
  return Math.min(maxWidth, columnWidth + PADDING)
}

export const renderFieldCell = (cell: Cell<Contact>, search: string): ReactNode => {
  return search ? renderBoldTextOnMatch(cell.value, search) : <ColWithTitle row={cell} />
}

export const filterContactColumns = ({ status, name }: Column) => status !== ColumnStatus.HIDDEN && !uuidColumns.includes(name)

export const getColumnsHeadersUtils = (columns: Column[], maxCellWidth: number, contacts: any[], search: string) => {
  return columns.filter(filterContactColumns).map(({ name: header, status }, index) => {
    const width = getColumnWidth(contacts, index.toString(), header || '', maxCellWidth)
    const lastIndex = columns.filter(({ status }) => status !== ColumnStatus.HIDDEN).length - 1
    const maxWidthAndFlex =
      index === lastIndex ? { flexColumn: true, minWidth: width + TABLE_ACTION_WIDTH } : { maxWidth: maxCellWidth, minWidth: width }
    return {
      ...maxWidthAndFlex,
      Header: header || '',
      accessor: `fields[${index.toString()}]`,
      align: 'left' as TableColumnAlign,
      disableSortBy: true,
      sticky: status === ColumnStatus.LOCKED ? 'left' : '',
      Cell: (cell: Cell<Contact>) => renderFieldCell(cell, search),
      className: `contacts-details__cell-search ${index === lastIndex ? 'contacts-details__last-column' : ''}`,
    } as TableColumn
  })
}

export const renderFieldCellV2 = (cell: CellContext<Contact, unknown>, search: string): ReactNode => {
  return search ? renderBoldTextOnMatch(cell.getValue<string>(), search) : <ColWithTitleV2 cell={cell} />
}

export const getColumnsHeadersUtilsV2 = (columns: Column[], search: string): ColumnDefWithAdditionalProps<Contact>[] => {
  return columns.filter(filterContactColumns).map(({ name: header }, index) => ({
    header,
    accessorKey: `fields.${index}`,
    textAlign: 'left',
    padding: { left: 24 },
    maxSize: 200,
    cell: (cell) => renderFieldCellV2(cell, search),
  }))
}

export const getStickyColumns = (columns: Column[]) => {
  return columns
    .filter(filterContactColumns)
    .reduce(
      (stickyColumns, column, index) => (column.status === ColumnStatus.LOCKED ? [...stickyColumns, `fields_${index}`] : stickyColumns),
      ['cellCheckbox']
    )
}

export const formatContactsUtils = (
  contacts: PageInput,
  columns: Column[],
  toggleDownloadModal?: (type: FileUplaodModalType, contact: string[], headerText?: string, ids?: number[]) => void,
  handleDownloadOrPreviewFile?: (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: FilesData[]) => void,
  userAllowedToDownload?: boolean
): Contact[] => {
  if (contacts?.contacts && columns.length > 0) {
    return Object.keys(contacts.contacts).map((recId: string, index) => {
      const contact: string[] = contacts.contacts[recId]

      const fields = columns.reduce((columns: string[], { status, id, name }: Column) => {
        if (status === ColumnStatus.HIDDEN || uuidColumns.includes(name)) {
          return columns
        }

        const fieldValue = contact[id]

        if (typeof fieldValue === 'string' && isValidJson(fieldValue)) {
          const parsedField = parseJsonSafely(fieldValue)

          if (parsedField?.type === 'FILE' && Array.isArray(parsedField.data)) {
            const fileNames = parsedField.data.map((item: { name: string }) => item.name).filter(Boolean)
            const fileIds = parsedField.data.map((item: { id: string }) => item.id).filter(Boolean)

            const ids = parsedField.data.map((item: { name: string; id: number }) => item.id)

            const data =
              contact
                ?.map((item) => parseJsonSafely(item))
                .filter((parsed) => parsed?.type === 'FILE')
                .flatMap((attachment) => attachment.data) ?? []

            const displayString =
              fileNames.length > 1 ? (
                <div className="contact-details__file-field-value" title={fileNames[0]}>
                  <LinkTextButton
                    noBorder
                    hideIcons
                    maxWidth="100%"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDownloadOrPreviewFile?.([fileIds[0]], FileUplaodModalType.DOWNLOAD, data)
                    }}
                  >
                    <TextWithTooltipOnEllip typographyProps={{ text: fileNames[0], title: fileNames[0] }} tooltipProps={{ hide: true }} />
                  </LinkTextButton>
                  {','}
                  <Button
                    disabled={!userAllowedToDownload}
                    buttonType={ButtonType.TERTIARY}
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleDownloadModal?.(FileUplaodModalType.DOWNLOAD, contact, contacts.headers?.[id]?.toUpperCase(), ids)
                    }}
                  >
                    <Typography key={parsedField.type} inline type={TextType.NORMAL_TEXT_TEAL_LARGE} weight={TextWeight.MEDIUM}>
                      +{fileNames.length - 1}
                    </Typography>
                  </Button>
                </div>
              ) : fileNames.length === 1 ? (
                <LinkTextButton
                  noBorder
                  hideIcons
                  maxWidth={130}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDownloadOrPreviewFile?.([fileIds[0]], FileUplaodModalType.DOWNLOAD, data)
                  }}
                >
                  <TextWithTooltipOnEllip typographyProps={{ text: fileNames[0], title: fileNames[0] }} tooltipProps={{ hide: true }} />
                </LinkTextButton>
              ) : (
                fileNames[0]
              )
            return [...columns, displayString]
          }
        }

        return [...columns, fieldValue]
      }, [])

      return { id: index.toString(), recId, fields, rawFields: contact }
    })
  }
  return []
}

export const mapContactIdToUuid = (segmentContacts: PageInput): Record<string, string> => {
  let mappedIds = {}
  if (segmentContacts.headers) {
    const uuidIndex = segmentContacts.headers.findIndex((header) => uuidColumns.includes(header as string))
    mappedIds = Object.keys(segmentContacts.contacts).reduce(
      (result, id) => ({
        ...result,
        [id]: segmentContacts.contacts[id][uuidIndex],
      }),
      {}
    )
  }
  return mappedIds
}

export const getColumnsUtils = (
  segment: Segment,
  headers: string[],
  client: ApolloClient<any>,
  unifiedListFieldMappings: UnifiedListFieldMapping[]
): Promise<Column[]> => {
  return client
    .query<GetColumnIndexOrderQuery, GetColumnIndexOrderQueryVariables>({
      query: getColumnIndexOrder,
      fetchPolicy: 'network-only',
      variables: {
        segmentId: segment.externalId,
      },
    })
    .then(({ data }) => {
      const { order = [], fixed = [], hidden = [] } = { ...data.getColumnIndexOrder }
      return buildColumns(order as number[], hidden as number[], fixed as number[], headers, unifiedListFieldMappings)
    })
    .catch(() => buildColumns([], [], [], headers, unifiedListFieldMappings))
}

export const buildColumns = (
  order: number[],
  hidden: number[],
  locked: number[],
  defaultHeaders: string[],
  unifiedListFieldMappings: UnifiedListFieldMapping[]
) => {
  const unifiedListFieldMappingsNames = unifiedListFieldMappings.map(({ displayName = '' }) => displayName)
  const getColumnType = (header: string) => {
    if (formGeoIPFields.includes(header)) {
      return ColumnType.GEOIP
    } else if (formSubmissionFields.includes(header)) {
      return ColumnType.FORM_SUBMISSION
    } else if (unifiedListFieldMappingsNames.includes(header)) {
      return ColumnType.ACTON_CONTACTS
    } else {
      return ColumnType.FORM
    }
  }

  return defaultHeaders
    .map((header: string, index) => {
      const status = locked.includes(index) ? ColumnStatus.LOCKED : hidden.includes(index) ? ColumnStatus.HIDDEN : ColumnStatus.DEFAULT
      return {
        name: header,
        id: index,
        status,
        index,
        type: getColumnType(header),
      } as Column
    })
    .sort((firstColumn, secondColumn) => {
      const firstColumnPosition = order.indexOf(firstColumn.id)
      return firstColumnPosition === -1 ? 1 : firstColumnPosition - order.indexOf(secondColumn.id)
    })
}

const getColumnsSeparatedByStatus = (columns: Column[]) => {
  return columns.reduce(
    ([order, hidden, locked]: any, { status, id }: Column) => [
      [...order, id],
      [...hidden, ...(status === ColumnStatus.HIDDEN ? [id] : [])],
      [...locked, ...(status === ColumnStatus.LOCKED ? [id] : [])],
    ],
    [[], [], []]
  )
}

export const saveColumnsUtils = (columns: Column[], segmentId: string, client: ApolloClient<any>, allSubsegments = false): Promise<any> => {
  const [order, hidden, fixed] = getColumnsSeparatedByStatus(columns)
  return client.mutate({
    mutation: allSubsegments ? columnIndexOrderToSubSegments : columnIndexOrder,
    variables: {
      order,
      fixed,
      hidden,
      segmentId,
      ...(allSubsegments ? {} : { applyUCL: false }),
    },
  })
}

const commonEmptyStateProps = (isDirectSelect = false): EmptyListingProps => ({
  imgSrc: StaticImageNames.errorNothingFound,
  size: EmptyListingSize.LARGE,
  textAlign: TextAlign.CENTER,
  textType: TextType.BODY_TEXT_LIGHT,
  headline: isDirectSelect ? 'This segment has no contacts!' : 'No contacts match your segment conditions',
  className: `contacts-details__empty-listing`,
})

enum ContactDetailsEmptyStates {
  EDITABLE_SEGMENT,
  NON_EDITABLE_SEGMENT,
  BOUNCE,
  SMS_OPT_IN,
  SEARCH,
  WEBINAR,
  FORM_SUBMISSION,
}

export const onSendEmailClick = (selectedContacts: Contact[]) => {
  if (selectedContacts.length > 0) {
    const contactsIds = selectedContacts.map(({ recId }) => recId)
    sendEmailToContact(contactsIds.toString())
  }
}

export const getContactDetailsEmptyState = ({ externalId, isEditable }: Segment, isBounceSegment = false, search: string, itemType: ItemType) => {
  if (search !== '') {
    return ContactDetailsEmptyStates.SEARCH
  }
  if (externalId === smsBouncesVerbs[BouncesSegments.SMS_OPT_IN]) {
    return ContactDetailsEmptyStates.SMS_OPT_IN
  }
  if (isBounceSegment) {
    return ContactDetailsEmptyStates.BOUNCE
  }
  if (isEditable) {
    return ContactDetailsEmptyStates.EDITABLE_SEGMENT
  }
  if (itemType === ItemType.WEBINAR_SUBMISSION) {
    return ContactDetailsEmptyStates.WEBINAR
  }
  if (itemType === ItemType.FORM_SUBMISSION) {
    return ContactDetailsEmptyStates.FORM_SUBMISSION
  }
  return ContactDetailsEmptyStates.NON_EDITABLE_SEGMENT
}

export const contactDetailsEmptyStateData: { [key in ContactDetailsEmptyStates]: (options: any) => EmptyListingProps } = {
  [ContactDetailsEmptyStates.EDITABLE_SEGMENT]: ({ isDirectSelect, segment, segmentSearch, searchAllItems }) => ({
    ...commonEmptyStateProps(isDirectSelect),
    buttonOnClick: () => goEditSegment(segment, CONTACTS_DETAILS_URL, { search: segmentSearch, searchAll: searchAllItems }),
    text: `Try editing this segment ${
      isDirectSelect ? 'and selecting some contacts to begin targeting' : 'to begin targeting contacts'
    } by location, behavior, activity, engagement, and more.`,
    buttonText: 'Edit segment',
  }),
  [ContactDetailsEmptyStates.NON_EDITABLE_SEGMENT]: ({ isDirectSelect }) => ({
    ...commonEmptyStateProps(isDirectSelect),
    buttonURL: `${rootContext}/datamanagement`,
    text: 'Visit Data Management to import contacts or sync your CRM.',
    buttonText: 'Data Management',
  }),
  [ContactDetailsEmptyStates.BOUNCE]: () => ({
    ...commonEmptyStateProps(),
    text: 'Contacts will automatically be added to this segment when they qualify.',
  }),
  [ContactDetailsEmptyStates.SMS_OPT_IN]: () => ({
    ...commonEmptyStateProps(),
    text: 'Visit SMS Opt Ins to select contacts.',
    buttonText: 'Select SMS Opt-Ins',
    buttonURL: `${rootContext}/sms/optins`,
  }),
  [ContactDetailsEmptyStates.SEARCH]: () => ({
    imgSrc: StaticImageNames.emptySearch,
    size: EmptyListingSize.MEDIUM,
    text: 'There were no contacts matching your search.',
    headline: 'No results found',
  }),
  [ContactDetailsEmptyStates.WEBINAR]: () => ({
    imgSrc: StaticImageNames.errorNothingFound,
    size: EmptyListingSize.LARGE,
    text: 'You’ll see contacts here after they’ve submitted a form that’s related to this list.',
    headline: 'No contacts here yet.',
  }),
  [ContactDetailsEmptyStates.FORM_SUBMISSION]: () => ({
    imgSrc: StaticImageNames.errorNothingFound,
    size: EmptyListingSize.LARGE,
    text: 'You’ll see contacts here after they’ve submitted a form that’s related to this list.',
    headline: 'No contacts here yet.',
  }),
}

const getRemoveFromBounceSegmentProps = (segment: Segment, isBulk = false) => {
  const props: { [key: string]: HeaderAction | RowAction } = {
    [BouncesSegments.OPT_OUT]: { label: 'Remove opt-out', hidden: isBulk },
    [BouncesSegments.HARD_BOUNCES]: { label: 'Change to soft bounce', hidden: isBulk },
    [BouncesSegments.SOFT_BOUNCES]: { label: 'Change to hard bounce' },
  }
  return props[segment.name] ?? { hidden: true }
}

export const getBouncesContactsHeaderActions = (
  segment: Segment,
  doTableAction: (action: ContactsTableActions) => void,
  allowToDelete: boolean
): HeaderAction[] => [
  {
    hidden: isNonSendableSegment(segment),
    label: 'Send Email',
    icon: SvgNames.letter,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.SEND_EMAIL),
  },
  {
    disabled: !allowToDelete,
    hidden: !allowToDelete,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Delete',
    icon: SvgNames.delete,
    iconSize: SvgType.ICON,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.DELETE),
  },
  {
    ...getRemoveFromBounceSegmentProps(segment, true),
    icon: SvgNames.removeContact,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.REMOVE_FROM_BOUNCE),
  },
]

export const getBouncesContactsRowActions = (
  segment: Segment,
  onDeleteContactClick: (row: Row<Contact>) => void,
  onRemoveFromBounceClick: (contacts: Contact[]) => void,
  allowToDelete: boolean,
  contactHeaders: Column[],
  unifiedListFieldMappings: UnifiedListFieldMapping[]
): RowAction[] => [
  {
    hidden: isNonSendableSegment(segment),
    label: 'Send Email',
    icon: SvgNames.letter,
    onClick: (row: Row<Contact>) => {
      saveSelectedContactsToLocalStorage([row.original], contactHeaders, unifiedListFieldMappings)

      sendEmailToContact(row.original.recId)
    },
  },
  {
    label: 'Contact Report',
    icon: SvgNames.contactReport,
    onClick: ({ original: { recId } }: Row<Contact>) => openContactView(recId, ContactViewPanel.SUMMARY),
  },
  {
    label: 'Edit contact',
    icon: SvgNames.pencil,
    inDropdown: true,
    onClick: ({ original: { recId } }: Row<Contact>) => openContactView(recId, ContactViewPanel.INFO),
  },
  {
    disabled: !allowToDelete,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Delete contact',
    icon: SvgNames.delete,
    inDropdown: true,
    onClick: onDeleteContactClick,
  },
  {
    ...getRemoveFromBounceSegmentProps(segment),
    icon: SvgNames.removeContact,
    inDropdown: true,
    onClick: ({ original: contact }: Row<Contact>) => onRemoveFromBounceClick([contact]),
  },
]

const getEmailFieldIndex = (headers: Column[]) =>
  headers.findIndex(({ name }) => name === ('E-mail Address' || 'Email Address' || 'E-mail' || 'Email'))

export const getChangeBounceStatusConfirmationModal = (contacts: Contact[], headers: Column[], segment: Segment, t: Function) => {
  const titles: { [key: string]: (email: string) => { title: string; body: string; button: string } } = {
    [BouncesSegments.OPT_OUT]: (email: string) => ({
      title: 'Remove contact from opt-out list?',
      body: t('CHANGE_BOUNCE_STATUS.OPT_OUT', { email }),
      button: 'Remove contact',
    }),
    [BouncesSegments.SOFT_BOUNCES]: (email: string) => ({
      title: 'Change contact status to hard bounce?',
      body: t('CHANGE_BOUNCE_STATUS.SOFT_BOUNCES', { email }),
      button: 'Change status',
    }),
    [BouncesSegments.HARD_BOUNCES]: (email: string) => ({
      title: 'Change contact status to soft bounce?',
      body: t('CHANGE_BOUNCE_STATUS.HARD_BOUNCES', { email }),
      button: 'Change status',
    }),
  }

  const emailFieldIndex = getEmailFieldIndex(headers)
  const email = contacts[0].fields[emailFieldIndex]
  return titles[segment.name](contacts.length > 1 ? t('the selected contacts') : email ?? t('the selected contact'))
}

export const onRemoveFromBounce = (contacts: Contact[], segment: Segment, client: ApolloClient<any>) => {
  const contactRecIds = contacts.map(({ recId }) => recId)
  const verb = Object.values(bouncesVerbs).find((id) => id === segment.externalId) ?? ''
  return changeContactsBounceStatus(contactRecIds, verb, client)
}

export const changeContactsBounceStatus = (recIds: string[], verb: string, client: ApolloClient<any>) =>
  client.mutate<ChangeBounceStatusMutation, ChangeBounceStatusMutationVariables>({
    mutation: changeBounceStatus,
    variables: { recIds, verb },
  })

export const getContactsHeaderActions = (isDirectSelect: boolean, doTableAction: Function, allowToDelete: boolean): HeaderAction[] => [
  {
    label: 'Send Email',
    icon: SvgNames.letter,
    hasTooltip: true,
    onClick: () => doTableAction(ContactsTableActions.SEND_EMAIL),
  },
  {
    disabled: !allowToDelete,
    hidden: !allowToDelete,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Delete',
    icon: SvgNames.delete,
    iconSize: SvgType.ICON,
    inDropdown: isDirectSelect,
    hasTooltip: !isDirectSelect,
    onClick: () => doTableAction(ContactsTableActions.DELETE),
  },
  {
    hidden: !isDirectSelect,
    label: 'Remove from segment',
    icon: SvgNames.removeContact,
    inDropdown: true,
    onClick: () => doTableAction(ContactsTableActions.REMOVE_FROM_SEGMENT),
  },
]

export const getContactsRowActions = (
  isDirectSelect: boolean,
  onDeleteContactClick: (row: Row<Contact>) => void,
  onRemoveContactFromSegment: (recIds: string[]) => void,
  allowToDelete: boolean,
  contactHeaders: Column[],
  unifiedListFieldMappings: UnifiedListFieldMapping[]
): RowAction[] => [
  {
    label: 'Send Email',
    icon: SvgNames.letter,
    onClick: (row: Row<Contact>) => {
      saveSelectedContactsToLocalStorage([row.original], contactHeaders ?? [], unifiedListFieldMappings)

      sendEmailToContact(row?.original.recId)
    },
  },
  {
    label: 'Contact Report',
    icon: SvgNames.contactReport,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.SUMMARY),
  },
  {
    label: 'Edit contact',
    icon: SvgNames.pencil,
    inDropdown: true,
    onClick: (row: Row<Contact>) => openContactView(row?.original.recId, ContactViewPanel.INFO),
  },
  {
    disabled: !allowToDelete,
    tooltipMessage: 'Ask your administrator for permission to do this',
    label: 'Delete contact',
    icon: SvgNames.delete,
    inDropdown: true,
    onClick: onDeleteContactClick,
  },
  {
    hidden: !isDirectSelect,
    label: 'Remove from Segment',
    icon: SvgNames.removeContact,
    inDropdown: true,
    onClick: (row: Row<Contact>) => onRemoveContactFromSegment([row?.original.recId]),
  },
]

export const deleteContactsUtils = (recordIds: number[], uuids: string[], client: ApolloClient<any>) =>
  client.mutate<DeleteContactsMutation, DeleteContactsMutationVariables>({
    mutation: deleteContacts,
    variables: {
      recordIds,
      uuids,
    },
  })

export const getSegmentContacts = (page: PageInput, client: ApolloClient<any>, isInactiveBounceSegment = false, isSubmissionList = false) =>
  client
    .query<GetContactsQuery & GetContactsFromBounceQuery, GetContactsQueryVariables & GetContactsFromBounceQueryVariables>({
      query: isInactiveBounceSegment ? getContactsFromBounce : getContacts,
      fetchPolicy: 'network-only',
      variables: {
        page,
        isSubmissionList,
      },
    })
    .then(({ data: { getContacts, getContactsFromBounce } }) => ({ ...(getContacts ?? getContactsFromBounce) }))

export const removeContactsFromSegmentUtils = (segmentId: string, contactIds: string[], client: ApolloClient<any>) =>
  client.mutate<RemoveContactsFromSegmentMutation, RemoveContactsFromSegmentMutationVariables>({
    mutation: removeContactsFromSegment,
    variables: { segmentId, recIds: contactIds },
  })
