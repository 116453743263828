import React, { FC, Fragment, ReactNode, useCallback, useMemo } from 'react'

import classNames from 'classnames'

import { ButtonProps } from '@components/Button/Button'
import Button, { ButtonType } from '@components/Button/index'
import ButtonWithLoader from '@components/ButtonWithLoader/ButtonWithLoader'
import Checkbox from '@components/Checkbox/index'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalType } from '@components/Modal/modalUtils'
import Svg, { SvgNames, SvgType } from '@components/Svg/index'
import { SvgColor } from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ModalFooterV2.css'

export interface ModalFooterV2ButtonProps {
  actionButtonLabel?: string
  actionButtonLoading?: boolean
  actionButtonOnClick?: ButtonProps['onClick']
  actionButtonDisabled?: boolean
  actionButtonTooltip?: string
  cancelButtonLabel?: string
  cancelButtonDisabled?: boolean
  /**
   * Dynamic data attributes for the component.
   * Keys follow `data-{string}` format, and values are strings.
   */
  actionButtonDataProperties?: { [key: `data-${string}`]: string }
  /** Render a non-standard button type. Only use with approval from the design team */
  renderCustomActionButton?: () => ReactNode
}

interface ModalFooterV2CheckboxProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

export interface ModalFooterV2ViewSelectedProps {
  noSelectionText?: string
  noSelection?: boolean
  hideButton?: boolean
  label?: string
  icon?: SvgNames
  currentText: string | ReactNode
  isViewSelected: boolean
  onClick: () => void
}

interface ModalFooterV2TextProps {
  text: string
  infoTooltip?: string
}

interface ModalFooterV2LeftButtonProps {
  label: string
  onClick: () => void
}

interface ModalFooterV2Legend {
  legend: string
  icon?: SvgNames
}

interface ModalFooterV2LegendProps {
  label?: string
  legends: ModalFooterV2Legend[]
}

interface ModalFooterV2DeleteButtonProps {
  onDelete: () => void
  deleteText?: string
}

export interface ModalFooterV2Props {
  footerType?: ModalType
  showTopBorder?: boolean
  checkbox?: ModalFooterV2CheckboxProps
  viewSelected?: ModalFooterV2ViewSelectedProps
  leftButton?: ModalFooterV2LeftButtonProps
  /** Render non-standard content on the left side of the footer. Only use with approval from the design team  */
  customContent?: ReactNode
  deleteButton?: ModalFooterV2DeleteButtonProps
  textContent?: ModalFooterV2TextProps
  legends?: ModalFooterV2LegendProps
  buttons?: ModalFooterV2ButtonProps
  onClose?: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'modal-footer-v2'

const ModalFooterV2: FC<ModalFooterV2Props> = (props: ModalFooterV2Props) => {
  const {
    footerType,
    showTopBorder,
    checkbox,
    viewSelected,
    leftButton,
    deleteButton,
    textContent,
    legends,
    buttons,
    onClose,
    dataTest = rootClass,
    className = '',
    customContent,
  } = props

  const { t } = useTranslation()

  const buttonElement = useMemo(() => {
    if (buttons) {
      const renderPrimaryButton = () => {
        const trigger = buttons.actionButtonLoading ? (
          <ButtonWithLoader
            onClick={buttons.actionButtonOnClick}
            loading={buttons.actionButtonLoading}
            className={`${rootClass}__action-button-loading`}
            key={`${dataTest}-action-button-loading`}
            dataProperties={buttons.actionButtonDataProperties}
          >
            {buttons?.actionButtonLabel ?? t('Submit')}
          </ButtonWithLoader>
        ) : (
          <Button
            buttonType={ButtonType.PRIMARY}
            disabled={buttons.actionButtonDisabled}
            onClick={buttons.actionButtonOnClick}
            dataTest={`${dataTest}-action-button`}
            key={'action-button'}
            dataProperties={buttons.actionButtonDataProperties}
          >
            {buttons?.actionButtonLabel ?? t('Submit')}
          </Button>
        )

        if (buttons?.actionButtonTooltip) {
          return <Tooltip trigger={trigger} text={buttons.actionButtonTooltip} position={'left'} minimalPadding={false} />
        }

        return buttons.renderCustomActionButton ? (
          <Fragment key={`${dataTest}-action-button-custom`}>{buttons.renderCustomActionButton()}</Fragment>
        ) : (
          trigger
        )
      }

      return (
        <>
          {buttons.cancelButtonLabel && (
            <Button
              buttonType={ButtonType.FLOAT_GRAY}
              onClick={onClose}
              className={`${rootClass}__cancel-button`}
              dataTest={`${dataTest}-cancel-button`}
              disabled={buttons.cancelButtonDisabled}
            >
              {buttons.cancelButtonLabel}
            </Button>
          )}
          {renderPrimaryButton()}
        </>
      )
    } else {
      return (
        <Button buttonType={ButtonType.WHITE} onClick={onClose} dataTest={`${dataTest}-close-button`}>
          {t('Close')}
        </Button>
      )
    }
  }, [buttons, dataTest, onClose, t])

  const renderLeftElement = useCallback(() => {
    if (checkbox) {
      return <Checkbox label={checkbox.label} checked={checkbox.checked} onChange={checkbox.onChange} dataTest={`${dataTest}-checkbox`} />
    }

    if (viewSelected) {
      return (
        <div className={`${rootClass}__view-selected`}>
          {!viewSelected.hideButton && (
            <Button
              buttonType={ButtonType.INFO}
              onClick={viewSelected.onClick}
              className={`${rootClass}__view-selected-button`}
              dataTest={`${dataTest}-view-selected-button`}
              disabled={viewSelected.noSelection}
            >
              {viewSelected.icon ? (
                <Svg name={viewSelected.icon} />
              ) : (
                <Svg name={viewSelected.isViewSelected ? SvgNames.arrowLeft : SvgNames.listCheck} />
              )}
              {viewSelected.label ? viewSelected.label : t(viewSelected.isViewSelected ? 'View all' : 'View selected')}
            </Button>
          )}
          {viewSelected.noSelection ? (
            <Typography text={viewSelected.noSelectionText} type={TextType.BODY_TEXT_SMALL_LIGHT} />
          ) : (
            <Typography text={viewSelected.currentText} type={TextType.BODY_TEXT_SMALL_LIGHT} weight={TextWeight.MEDIUM} />
          )}
        </div>
      )
    }

    if (customContent) {
      return customContent
    }

    if (leftButton) {
      return (
        <Button buttonType={ButtonType.INFO} onClick={leftButton.onClick} dataTest={`${dataTest}-left-button`}>
          {leftButton.label}
        </Button>
      )
    }

    if (deleteButton) {
      return (
        <Button
          buttonType={ButtonType.DELETE_OUTLINE}
          onClick={deleteButton.onDelete}
          className={`${rootClass}__delete`}
          dataTest={`${dataTest}-delete-button`}
        >
          <Svg name={SvgNames.delete} fill={SvgColor.REMOVE_RED} />
          {t(deleteButton.deleteText ?? 'Delete')}
        </Button>
      )
    }

    if (textContent) {
      return (
        <>
          <Typography
            text={textContent.text}
            dataTest={`${dataTest}-text-content`}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
          {textContent.infoTooltip && <InfoTooltip>{textContent.infoTooltip}</InfoTooltip>}
        </>
      )
    }

    if (legends) {
      return (
        <div className={`${rootClass}__legend`}>
          <Typography text={t(legends.label ?? 'Legend: ')} dataTest={`${dataTest}-legends`} />
          {legends.legends.map((legend) => (
            <div key={legend.icon} className={`${rootClass}__legend-item`}>
              {legend.icon && <Svg name={legend.icon} type={SvgType.LARGER_ICON} dataTest={`${dataTest}-legends-icon`} />}
              <Typography text={legend.legend} />
            </div>
          ))}
        </div>
      )
    }
  }, [checkbox, dataTest, leftButton, legends, deleteButton, t, textContent, viewSelected, customContent])

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__form`]: footerType === ModalFooterType.Form,
        [`${rootClass}__list`]: footerType === ModalFooterType.List,
        [`${rootClass}__default`]: footerType === ModalFooterType.Basic,
      })}
      data-test={dataTest}
    >
      <div
        className={classNames(`${rootClass}__inner`, {
          [`${rootClass}__inner-show-top-border`]: !footerType && showTopBorder,
        })}
      >
        <div className={`${rootClass}__inner-left`}>{renderLeftElement()}</div>
        <div className={`${rootClass}__inner-right`}>{buttonElement}</div>
      </div>
    </div>
  )
}

export default ModalFooterV2
