import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import ColumnsOrderModal from '@components/ColumnsOrderModal/ColumnsOrderModal'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { YesNo } from '@components/ConfirmationModal'
import { ContactsTableActions, MAX_WIDTHS, PAGE_SIZE_OPTIONS } from '@components/ContactsDetails/ContactsDetails.constants'
import { ContactsDetailContext } from '@components/ContactsDetails/ContactsDetailsContext'
import {
  contactDetailsEmptyStateData,
  formatContactsUtils,
  getBouncesContactsHeaderActions,
  getBouncesContactsRowActions,
  getColumnsHeadersUtils,
  getColumnsHeadersUtilsV2,
  getContactDetailsEmptyState,
  getContactsHeaderActions,
  getContactsRowActions,
  getStickyColumns,
  getTableLabel,
  onSendEmailClick,
  uuidColumns,
} from '@components/ContactsDetails/utils/ContactsDetails.utils'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import EditColumnsDropdown from '@components/EditColumnsDropdown/EditColumnsDropdown'
import EmptyListing from '@components/EmptyListing/EmptyListing'
import Search, { SearchType } from '@components/Search/Search'
import { SegmentDetailsContext } from '@components/SegmentDetails/context/SegmentDetails.context'
import SvgNames from '@components/Svg/SvgNames'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { MultipleTables } from '@components/TableV2/components/MultipleTables/MultipleTables'
import TableWithMenu from '@components/TableWithMenu/TableWithMenu'
import Typography, { ModalBodyStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { PaginationState, Row, RowSelectionState } from '@tanstack/react-table'
import { useAccountSettings } from '@utils/account/account.utils'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import useWindowSize from '@utils/hooks/useWindowSize'

import './ContactsDetails.css'

export interface ContactDetailsProps {
  className?: string
  dataTest?: string
  defaultColumns?: Column[]
  enableTableV2?: boolean
  resetSelectionWhenPageChanges?: boolean
  segment: Segment
  showSelectedContactsOnly?: boolean
  isBounceSegment?: boolean
  segmentSearch?: string
  selectedContacts?: Contact[]
  tableHeader?: ReactNode
  contactsTableLabelSuffix?: string
  searchAllItems?: boolean
  segmentContacts: PageInput
  customHeaderActions?: (
    selectedContacts: Contact[],
    unifiedListFieldMappings: UnifiedListFieldMapping[],
    contactsHeaders: Column[]
  ) => HeaderAction[]
  customRowActions?: (
    unifiedListFieldMappings: UnifiedListFieldMapping[],
    contactsHeaders: Column[],
    newFormFileUploadBlock?: boolean,
    segment?: PageInput,
    userAllowedToDownload?: boolean
  ) => RowAction[]
  onColumnsSave?: (columns: Column[], allSubsegments: boolean) => void
  onContactRowClicked?: (row: Row<Contact>) => void
  onContactsSelected?: (contact: Contact[]) => void
  onPageOptionsChange?: (page: PaginationState) => void
  onSearch?: (search: string) => void
  itemType: ItemType
}

const rootClass = 'contacts-details'

const ContactsDetails: FC<ContactDetailsProps> = (props: ContactDetailsProps) => {
  const {
    dataTest = rootClass,
    enableTableV2 = false,
    resetSelectionWhenPageChanges = false,
    segment,
    showSelectedContactsOnly = false,
    className = '',
    isBounceSegment,
    itemType,
    segmentSearch,
    searchAllItems,
    segmentContacts,
    selectedContacts = [],
    tableHeader,
    contactsTableLabelSuffix,
    customRowActions,
    customHeaderActions,
    onColumnsSave: onColumnsSaveProp,
    onContactRowClicked,
    onContactsSelected,
    onSearch,
  } = props

  const [maxCellWidth, setMaxCellWidth] = useState(MAX_WIDTHS.small)

  const {
    values: {
      hasNextPage,
      pageSize,
      pageIndex,
      search,
      columns,
      loading,
      confirmationModalData,
      showColumnsOrderModal,
      contactsToDelete,
      unifiedListFieldMappings,
    },
    loadContacts,
    onPaginationChange,
    onRemoveFromBounceClick,
    onRemoveContactFromSegment,
    onColumnsSave,
    onDeleteContacts,
    toggleDownloadModal,
    handleDownloadOrPreviewFile,
    update,
  } = useContext(ContactsDetailContext)

  const { userAllowedToDelete, newFormFileUploadBlock, userAllowedToDownload } = useAccountSettings()

  const {
    update: segmentDetailsUpdate,
    values: { fetchItems },
  } = useContext(SegmentDetailsContext)

  const { t } = useTranslation()

  const size = useWindowSize()
  const isDirectSelect = segment?.type === 'Direct Select'
  const totalCount = segmentContacts?.totalCount || 0
  const noContacts = useMemo(() => !loading && !Object.keys(segmentContacts?.contacts ?? {}).length, [loading, segmentContacts])
  const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

  const tableMenuItems: MenuItem[] = [
    {
      text: 'Manage column order',
      icon: SvgNames.columnOrder,
      onClick: () => update({ showColumnsOrderModal: true }),
    },
  ]

  const formattedContacts = useMemo(
    () => formatContactsUtils(segmentContacts, columns, toggleDownloadModal, handleDownloadOrPreviewFile, userAllowedToDownload),
    [segmentContacts, columns, toggleDownloadModal, handleDownloadOrPreviewFile, userAllowedToDownload]
  )

  const defaultSelectedRows = useMemo(() => {
    if (enableTableV2) {
      return selectedContacts.reduce(
        (rowSelectionState: RowSelectionState, contact) => ({
          ...rowSelectionState,
          [contact.recId]: true,
        }),
        {}
      )
    }
  }, [enableTableV2, selectedContacts])

  const columnsHeaders = useMemo(
    () => (enableTableV2 ? [] : getColumnsHeadersUtils(columns, maxCellWidth, formattedContacts, search)),
    [enableTableV2, columns, maxCellWidth, formattedContacts, search]
  )

  const columnsHeadersV2 = useMemo(() => (enableTableV2 ? getColumnsHeadersUtilsV2(columns, search) : []), [columns, enableTableV2, search])

  const stickyColumns = useMemo(() => (enableTableV2 ? getStickyColumns(columns) : []), [columns, enableTableV2])

  const { uuidColumn, uuidColumnIndex } = useMemo(() => {
    const uuidColumnIndex = columns.findIndex(({ name }) => uuidColumns.includes(name))
    return { uuidColumnIndex, uuidColumn: uuidColumnIndex !== -1 ? columns[uuidColumnIndex] : undefined }
  }, [columns])

  const changePageOptions = (pageIndex: number, pageSize: number) => {
    update({ pageIndex, pageSize })
  }

  const onDeleteContactClick = (row?: Row<Contact>) => {
    if (row) {
      update({
        contactsToDelete: [
          uuidColumnIndex !== -1 && row.original.fields[uuidColumnIndex]
            ? row.original.fields[uuidColumnIndex]
            : segmentContacts.rowIds[row.original.recId],
        ],
      })
    } else {
      if (selectedContacts.length > 0) {
        update({
          contactsToDelete: selectedContacts.map(({ recId, fields }) =>
            uuidColumnIndex !== -1 && fields[uuidColumnIndex] ? fields[uuidColumnIndex] : segmentContacts.rowIds[recId]
          ),
        })
      }
    }
  }

  const onRowSelectionChanged = (ids: string[], contactRows: Row<Contact>[]) => {
    if (onContactsSelected) {
      if (ids.length > 0) {
        if (ids.length !== selectedContacts.length) {
          const updatedSelectedContacts = ids
            .map((id) => {
              if (enableTableV2) {
                const contactFromCurrentPage = contactRows.find((contact) => contact.id === id)?.original
                return contactFromCurrentPage ?? selectedContacts.find(({ recId }) => recId === id)
              } else {
                return contactRows[parseInt(id)]?.original
              }
            })
            .filter(filterNotEmptyArray)
          onContactsSelected(updatedSelectedContacts)
        }
      } else if (selectedContacts.length > 0) {
        onContactsSelected([])
      }
    }
  }

  const defaultTableActions: { [key in ContactsTableActions]: () => void } = {
    [ContactsTableActions.EXPORT]: () => undefined,
    [ContactsTableActions.SEND_EMAIL]: () => onSendEmailClick(selectedContacts),
    [ContactsTableActions.SEND_SMS]: () => undefined,
    [ContactsTableActions.REMOVE_FROM_SEGMENT]: () => onRemoveContactFromSegment(),
    [ContactsTableActions.REMOVE_FROM_BOUNCE]: () => onRemoveFromBounceClick(selectedContacts),
    [ContactsTableActions.DELETE]: () => onDeleteContactClick(),
  }

  const doTableAction = (action: ContactsTableActions) => {
    if (defaultTableActions.hasOwnProperty(action)) {
      defaultTableActions[action]()
    }
  }

  const headerActions: HeaderAction[] = useMemo(
    () =>
      customHeaderActions
        ? customHeaderActions(selectedContacts, unifiedListFieldMappings, columns)
        : isBounceSegment
        ? getBouncesContactsHeaderActions(segment, doTableAction, userAllowedToDelete)
        : getContactsHeaderActions(isDirectSelect, doTableAction, userAllowedToDelete),
    [segment, selectedContacts, columns, unifiedListFieldMappings]
  )

  const rowActions: RowAction[] = useMemo(
    () =>
      customRowActions
        ? customRowActions(unifiedListFieldMappings, columns, newFormFileUploadBlock, segmentContacts, userAllowedToDownload)
        : isBounceSegment
        ? getBouncesContactsRowActions(segment, onDeleteContactClick, onRemoveFromBounceClick, userAllowedToDelete, columns, unifiedListFieldMappings)
        : getContactsRowActions(
            isDirectSelect,
            onDeleteContactClick,
            onRemoveContactFromSegment,
            userAllowedToDelete,
            columns,
            unifiedListFieldMappings
          ),
    [segment, isDirectSelect, columns, unifiedListFieldMappings]
  )

  useEffect(() => {
    if (fetchItems && unifiedListFieldMappings.length > 0) {
      segmentDetailsUpdate({ fetchItems: false })
      loadContacts()
    }
  }, [fetchItems, unifiedListFieldMappings])

  useEffect(() => {
    const width = size.width || 0
    setMaxCellWidth(width <= 1600 ? MAX_WIDTHS.small : width <= 2000 ? MAX_WIDTHS.medium : MAX_WIDTHS.large)
  }, [size])

  useEffect(() => {
    if (unifiedListFieldMappings.length > 0 && !showSelectedContactsOnly) {
      loadContacts()
    }
  }, [pageSize, pageIndex, segment.id, search, unifiedListFieldMappings, showSelectedContactsOnly])

  useEffect(() => {
    if (contactsToDelete.length > 0) {
      update({
        confirmationModalData: {
          title: contactsToDelete.length === 1 ? 'Delete this contact' : 'Delete these contacts',
          body: (
            <>
              <Typography
                text={t(
                  contactsToDelete.length === 1
                    ? 'The contact record and activity history will be deleted from Act-On Contacts and all Segments. '
                    : 'The contacts’ records and activity histories will be deleted from Act-On Contacts and all Segments. '
                )}
                {...ModalBodyStyle}
                inline
              />
              <Typography text={t('You will not be able to undo this action.')} {...ModalBodyStyle} weight={TextWeight.BOLD} inline />
            </>
          ),
          button: 'Delete',
          onAnswer: (answer: YesNo) => {
            if (answer === YesNo.YES) {
              onDeleteContacts()
            }
            update({ contactsToDelete: [] })
          },
        },
      })
    }
  }, [contactsToDelete])

  const onSaveColumnsClick = (columns: Column[], allSubsegments: boolean) => {
    let updatedColumns = columns
    if (uuidColumn && !updatedColumns.some(({ name }) => uuidColumns.includes(name))) {
      updatedColumns = [...updatedColumns.slice(0, uuidColumn.id), uuidColumn, ...updatedColumns.slice(uuidColumn.id)]
    }
    if (onColumnsSaveProp) {
      onColumnsSaveProp?.(updatedColumns, allSubsegments)
      update({ showColumnsOrderModal: false })
    } else {
      onColumnsSave(updatedColumns, allSubsegments)
    }
  }

  const renderTopSection = () => (
    <div className={classNames(`${rootClass}__top-section`, { [`${className}__top-section`]: className })}>
      {tableHeader ?? (
        <Typography
          className={classNames([`${rootClass}__top-section--label`, 'ellip'])}
          type={TextType.BODY_TEXT}
          weight={TextWeight.BOLD}
          text={getTableLabel(search, totalCount, loading, t, contactsTableLabelSuffix)}
        />
      )}
      <Search
        incomingValue={search}
        onChangeHandler={(value) => {
          update({ pageIndex: 0, search: value })
          onSearch?.(value)
        }}
        placeholder={'Search'}
        searchType={SearchType.LARGE}
        canClear
      />
    </div>
  )

  const renderEmptyState = () => {
    const emptyState = getContactDetailsEmptyState(segment, isBounceSegment, search, itemType)
    const { headline, text, buttonText, ...props } = contactDetailsEmptyStateData[emptyState]({
      isDirectSelect,
      segment,
      segmentSearch,
      searchAllItems,
    })
    return <EmptyListing {...props} headline={t(headline)} text={t(text)} buttonText={t(buttonText)} />
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {confirmationModalData && (
        <DeleteConfirmationModal
          dataTest={`${dataTest}--delete-confirmation-modal`}
          title={t(confirmationModalData.title)}
          body={confirmationModalData.body}
          deleteButtonText={confirmationModalData.button}
          onAnswer={(answer) => {
            confirmationModalData.onAnswer(answer)
            update({ confirmationModalData: undefined })
          }}
          isOpen
        />
      )}
      {showColumnsOrderModal && (
        <ColumnsOrderModal
          isOpen={showColumnsOrderModal}
          onClose={() => update({ showColumnsOrderModal: false })}
          onSave={onSaveColumnsClick}
          checkboxLabel={'Apply changes to all sub-segments'}
          columns={columns.filter(({ name }) => !uuidColumns.includes(name))}
        />
      )}
      {renderTopSection()}
      {noContacts ? (
        renderEmptyState()
      ) : (
        <MultipleTables
          enableTableV2={enableTableV2}
          tableV2Props={{
            rowActions,
            headerActions,
            loading,
            enableCheckbox: true,
            data: formattedContacts,
            defaultSelectedRows,
            enableOuterLoader: true,
            columns: columnsHeadersV2,
            enablePagesInput: showSelectedContactsOnly || search === '',
            stickyColumns,
            enablePaginate: true,
            tableMenuItems,
            paginationState: {
              pageIndex: segmentContacts.pageNumber ?? pageIndex,
              pageSize: segmentContacts.pageSize ?? pageSize,
              controlledPageCount: !showSelectedContactsOnly && search !== '' && hasNextPage ? pageIndex + 2 : pageCount,
            },
            onRowSelectionChanged,
            onPaginationChange,
            rowUniqueIdKey: 'recId',
            resetSelectionWhenPageChanges,
          }}
          oldTable={
            <TableWithMenu
              className={`${rootClass}__table`}
              data={formattedContacts}
              columns={columnsHeaders}
              // keeping the count on 2 to show the "next page" icon
              controlledPageCount={search !== '' && hasNextPage ? 2 : pageCount}
              showPages={search === ''}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              canLastPage={search === ''}
              fetchData={changePageOptions}
              headerActions={headerActions}
              loading={loading}
              onRowSelectionChanged={onRowSelectionChanged}
              initialState={{
                pageIndex,
                pageSize,
                selectedRowIds: selectedContacts.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),
              }}
              canPaginate
              hasOverflow
              hasHeadersWithEllipsis
              useCheckboxes
              useHeaderCheckbox
              useStickyColumns
              rowActions={rowActions}
              menuItems={
                itemType === ItemType.SEGMENT ? (
                  selectedContacts.length === 0 ? (
                    tableMenuItems
                  ) : (
                    []
                  )
                ) : (
                  <EditColumnsDropdown onSave={onSaveColumnsClick} columns={columns.filter(({ name }) => !uuidColumns.includes(name))} />
                )
              }
              updatePageOnKeyPress
              onRowClicked={(row: Row<Contact>) => onContactRowClicked?.(row)}
            />
          }
        />
      )}
    </div>
  )
}

export default ContactsDetails
