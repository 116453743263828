import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { SortDirection } from '@utils/common'
import { partialMatch } from '@utils/searchUtils'

export const paginateAssetResults = (data: ListingPageItem[], params?: FilterQueryParams) => {
  if (data.length === 0) {
    return []
  }
  const { pageNumber = 0, pageSize = 50, direction = SortDirection.ASC, orderBy } = params ?? {}
  const firstColumnKey = Object.keys(data[0])[0]
  const orderByKey = (orderBy ?? firstColumnKey) as keyof ListingPageItem
  const sortInverter = direction === SortDirection.ASC ? 1 : -1

  const sortedItems = [...data].sort((a, b) => {
    if (typeof a[orderByKey] === 'string' && typeof b[orderByKey] === 'string') {
      const aVal = a[orderByKey].toLocaleLowerCase()
      const bVal = b[orderByKey].toLocaleLowerCase()
      return aVal.localeCompare(bVal) * sortInverter
    }
    return a[orderByKey] > b[orderByKey] ? 1 * sortInverter : -1 * sortInverter
  })

  const startIndex = pageNumber * pageSize
  const pageItems = pageSize !== -1 ? sortedItems.slice(startIndex, startIndex + pageSize) : sortedItems
  return pageItems
}

export const searchAssetsRequestWrapper = async <Response, T extends {}, Key extends keyof T, WrapperFunction extends (data: T[]) => Response>(
  data: T[],
  search: string,
  fields: Key[],
  responseWrapper: WrapperFunction
) => {
  const results = (await searchAssets(data, search, fields)).data
  return await responseWrapper(results)
}

export const searchAssets = async <T extends {}, K extends keyof T>(data: T[], search: string, fields: K[]) => {
  return {
    data: data.filter((message) => partialMatch(message, fields, search)).slice(0, 100),
  }
}
