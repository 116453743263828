import { useMemo } from 'react'

import { FetchPolicy, useApolloClient } from '@apollo/client'
import getAllTriggeredMessages from '@graphql/queries/getAllTriggeredMessages'
import { GetAllTriggeredMessagesQueryVariables, GetAllTriggeredMessagesQuery } from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useEmailTriggeredRequests = () => {
  const actonClient = useApolloClient()
  const fetchPolicy: FetchPolicy = 'cache-first'
  const options = { fetchPolicy }

  return useMemo(
    () => ({
      getAllTriggeredMessagesRequest: createGraphQLQuery<GetAllTriggeredMessagesQuery, GetAllTriggeredMessagesQueryVariables>(
        actonClient,
        getAllTriggeredMessages,
        options
      ),
    }),
    [actonClient]
  )
}
