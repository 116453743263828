import React, { FC } from 'react'

import { TFunction } from 'i18next'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerInfoType } from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { RenderSearchColumnsV2, ListPageCommonState, SetError, Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FormType } from '@complex/ListingPage/Utils/ListingPage.constants'
import { useFormsPickerRequests } from '@components/AssetPickers/FormsPickerModal/GraphQL/FormsPickerRequests.graphQL'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { allFormsFilter } from '@utils/filter'
import { Form, getFormsPreviewUrl, getFormsSubTypes } from '@utils/forms'
import useCRM, { CRMConnectorType } from '@utils/hooks/useCRM'

import { renderCustomFilters } from './FormsPickerModal.sidebar'
import { renderSearchTableColumns, renderTableColumns } from './FormsPickerModal.tables'

const rootClass = 'forms-picker-modal'

export const getCreateFormFromMigratedModalProps = (t: TFunction) => ({
  isOpen: true,
  excludeNew: true,
  titleText: t('ListPage.Forms.MigratePicker.Title', { count: 1 }),
  primaryButtonText: t('ListPage.Forms.MigratePicker.PrimaryButton', { count: 1 }),
  secondaryButtonText: t('ListPage.Forms.MigratePicker.SecondaryButton'),
  infoBanner: {
    values: {
      title: '',
      text: t('ListPage.Forms.MigratePicker.Warning'),
      type: AssetPickerInfoType.INFO,
    },
  },
  isSingleSelect: true,
})

export type FormsPickerModalProps = AssetPickerModalBaseProps & { excludeClassic?: boolean; excludeNew?: boolean }

const FormsPickerModal: FC<FormsPickerModalProps> = (props) => {
  const { t } = useTranslation()
  const { hasCRMConnected, connectorType } = useCRM()
  const { getFormPreviewRequest } = useFormsPickerRequests()

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues
    const form = (selectedRows[0] as Form) ?? {}

    if (form.formType === FormType.NEW) {
      update({ previewUrl: getFormsPreviewUrl(form.externalId ?? '') })
    } else {
      update({ loading: true })
      const { data, errors } = await getFormPreviewRequest({ formId: form.externalId ?? '' })

      if (data) {
        update({ loading: false, previewHtml: data.getFormPreview })
      } else if (errors) {
        setError('Unexpected error', errors)
      }
    }
  }

  const getConnectorName = (type: CRMConnectorType): string => {
    if (type === CRMConnectorType.SALESFORCE) {
      return 'Salesforce'
    } else if (type === CRMConnectorType.NETSUITE) {
      return 'Netsuite'
    }
    return type
  }

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    folders: FolderData[]
  ) => renderSearchTableColumns(search, folders, t)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(t),
    renderSearchColumns,
    listPage: 'Forms',
    sortingBy: [{ id: 'lastUpdated', desc: true }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allFormsFilter,
    hasSalesUsersAction: true,
    renderCustomFilters: (params) => renderCustomFilters(params, hasCRMConnected ? getConnectorName(connectorType) : '', t),
  }

  const subTypes = getFormsSubTypes(connectorType)

  const listingPageProps: AssetPickerListingPageProps = {
    customPreviewItemCall,
    sidebarProps,
    subTypes,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: true,
    hasCustomFilters: true,
    hasSidebar: true,
    hasPreview: true,
    itemType: ItemType.FORM,
    defaultSubTypes: props.excludeClassic && props.excludeNew ? [] : props.excludeClassic ? ['new'] : props.excludeNew ? ['classic'] : [],
    disableRowByCriteria: (row) => {
      return props.excludeItemIds?.includes(row.original.externalId) || false
    },
    disabledListTooltipText: t('Selected'),
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default FormsPickerModal
