import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import BooleanCriteria from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BooleanCriteria/BooleanCriteria'
import DateCriteria from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import { IgnoreYearPopoverProps } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/IgnoreYearPopover/IgnoreYearPopover'
import NumericCriteria from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import {
  defaultExpression,
  ProfileExpressionState,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.constants'
import { getNewCondition } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression.utils'
import TextCriteria from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/TextCriteria/TextCriteria'
import {
  FieldType,
  getConditionType,
  getDefaultComparisonByField,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { NumericRangeCondition, NumericValueCondition, TextListCondition } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'

import './ProfileExpression.css'

interface ProfileExpressionProps {
  className?: string
  dataTest?: string
  row: RowDefinitionDto
}

const rootClass = 'profile-expression'

const ProfileExpression: FC<ProfileExpressionProps> = (props: ProfileExpressionProps) => {
  const { dataTest = rootClass, className = '', row } = props
  const { t } = useTranslation()
  const {
    values: { uclFieldsOptions },
  } = useContext(SegmentComposerContext)
  const factor = row.factor?.profileFactor
  const emptyOption = { label: '', value: '' }
  const selectedOption = uclFieldsOptions.find((option) =>
    factor ? option.value === factor.fieldColumnIndex?.toString() : option.extraOptions?.standardFieldKey === 'email'
  )
  const defaultOption = selectedOption ?? emptyOption

  const [state, setState] = useState<ProfileExpressionState>({
    field: defaultOption,
    expression: { ...defaultExpression, field: defaultOption.value },
    ignoreYear: false,
    textCriteria: { values: [] },
    numericCriteria: {},
    booleanCriteria: { value: true },
  })
  const { expression, field, comparison, ignoreYear, numericCriteria, textCriteria, dateCriteria, booleanCriteria } = state
  const { values } = textCriteria
  const { numberRange, numberValue } = numericCriteria
  const update = useDeepUpdate(setState)

  const ignoreYearProps: IgnoreYearPopoverProps = {
    ignoreYear,
    onIgnoreYearChange: (ignoreYear) => update({ ignoreYear }),
  }
  const onValuesCreate = (inputValue: string) => {
    if (!values.find((option) => option.label === inputValue)) {
      update({ textCriteria: { ...textCriteria, values: [...values, { label: inputValue, value: inputValue }] } })
    }
  }

  useEffect(() => {
    update({
      expression: {
        ...expression,
        condition: {
          ...expression.condition,
          value: numberValue,
        } as NumericValueCondition,
      },
    })
  }, [numberValue])

  useEffect(() => {
    update({
      expression: {
        ...expression,
        condition: {
          ...expression.condition,
          rangeStart: numberRange?.rangeStart,
          rangeEnd: numberRange?.rangeEnd,
        } as NumericRangeCondition,
      },
    })
  }, [numberRange])

  useEffect(() => {
    update({
      expression: {
        ...expression,
        condition: {
          ...expression.condition,
          values: values.map((value) => value.label),
        } as TextListCondition,
      },
    })
  }, [values])

  useEffect(() => {
    const newType = getConditionType(field.extraOptions?.type || '')
    const defaultComparison = getDefaultComparisonByField[(field.extraOptions?.type as FieldType) || FieldType.TEXT](t)

    update({
      comparison: newType !== expression.condition.type ? defaultComparison : comparison,
      expression: {
        ...expression,
        field: field.value,
        condition: getNewCondition(defaultComparison.value, state),
      },
    })
  }, [field])

  useEffect(() => {
    update({
      expression: {
        ...expression,
        condition: getNewCondition(comparison?.value || '', state),
      },
    })
  }, [comparison])

  const getComparisonDropdown = () => {
    switch (field.extraOptions?.type) {
      case 'TEXT':
        return <TextCriteria onChange={(textCriteria) => update({ textCriteria })} onCreate={onValuesCreate} textCriteria={textCriteria} />
      case 'NUMERIC':
        return <NumericCriteria onChange={(numericCriteria) => update({ numericCriteria })} numericCriteria={numericCriteria || {}} />
      case 'DATE':
        return (
          <DateCriteria dateCriteria={dateCriteria || {}} onChange={(dateCriteria) => update({ dateCriteria })} ignoreYearProps={ignoreYearProps} />
        )
      case 'BOOLEAN':
        return <BooleanCriteria onChange={(booleanCriteria) => update({ booleanCriteria })} booleanCriteria={booleanCriteria} />
      default:
        return null
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        dataTest={`${dataTest}-field-select`}
        className={`${rootClass}__field-select`}
        options={uclFieldsOptions}
        defaultValue={expression.field}
        onChange={(field?: SelectV2SingleOption) => update({ field })}
        isClearable={false}
        isSearchable={false}
      />
      {getComparisonDropdown()}
    </div>
  )
}

export default ProfileExpression
