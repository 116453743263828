import React from 'react'

import { TFunction } from 'i18next'

import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/query-types'

export interface EmailDto extends ItemDto {
  name: string
  beeComposer: boolean
}

export const renderLegacyEmailPill = (beeComposer: boolean, t: TFunction, rootClass: string) => {
  if (!beeComposer) {
    return <Pill text={t('Legacy')} type={PillType.GRAY} size={PillSize.EXTRA_SMALL} noMargin className={`${rootClass}__message-row-pill`} />
  }
  return null
}

const getAssetTypeText = (type: string) => {
  switch (type) {
    case 'FORMS':
      return 'Form:'
    case 'PROGRAMS':
      return 'Program:'
    case 'OTHER':
      return 'Other:'
    case 'WEBINARS':
      return 'Webinar:'
  }
  return ''
}

export const renderEmailNameCell = (original: ItemDto, search: string, rootClass: string, t: TFunction, showPill = true) => {
  const item = original as EmailDto
  const itemName = item?.name || 'Untitled email'
  const pill = showPill ? renderLegacyEmailPill(item.beeComposer, t, rootClass) : null

  const renderSecondaryText = () => {
    const assetName = 'assetName' in original && typeof original.assetName === 'string' ? original.assetName : 'Untitled asset'

    const typographyProps = { type: TextType.BODY_TEXT_SMALL_LIGHT, className: 'ellip' }
    const assetType = 'assetType' in original && typeof original.assetType === 'string' ? getAssetTypeText(original.assetType) : undefined

    const assetNameTypography = search ? (
      renderBoldTextOnMatch(assetName, search, true, typographyProps)
    ) : (
      <Typography text={assetName} {...typographyProps} />
    )
    const assetTypeTypography = assetType ? (
      <Typography text={assetType} type={TextType.BODY_TEXT_SMALL_LIGHT} weight={TextWeight.MEDIUM} />
    ) : undefined

    return (
      <div className={`${rootClass}__item-name-typography__secondary-text-wrapper`}>
        {assetTypeTypography}
        {assetNameTypography}
      </div>
    )
  }

  return (
    <div className={`${rootClass}__item-name ellip`}>
      <Tooltip
        trigger={
          <div className={`${rootClass}__item-name-typography`}>
            {itemName && <div className={'ellip'}>{search ? renderBoldTextOnMatch(itemName, search) : itemName}</div>}
            {renderSecondaryText()}
          </div>
        }
        minimalPadding
        ellipOnTrigger
      >
        {itemName}
      </Tooltip>
      {pill}
    </div>
  )
}
