import React from 'react'

import { SvgNames } from '@components/Svg'
import Svg, { SvgColor, SvgProps } from '@components/Svg/Svg'

interface Address {
  name?: string
  street?: string
  street2?: string
  state?: string
  city?: string
  zip?: string
  country?: string
  UUID?: string
}

export const renderSelectOptionIcon = (isSelected?: boolean, svgProps?: Partial<SvgProps>) => {
  const { name: svgName = SvgNames.buildings, fill = SvgColor.BUTTON_GRAY, type, containerType } = svgProps ?? {}

  return (
    <div>
      <Svg name={svgName} fill={isSelected ? SvgColor.TEXT_TEAL : fill} type={type} containerType={containerType} />
    </div>
  )
}

export const parseAddress = (svgProps?: Partial<SvgProps>, address?: Address) => {
  const { name = '', street, street2, city, state, zip, country, UUID } = address ?? {}

  const getFormattedAddress = () => {
    const notBlank = (text: string | undefined) => !!text
    const streetVal = [street, street2].filter(notBlank).join(' ')
    const regionVal = [streetVal, city, state].filter(notBlank).join(', ')
    return [regionVal, zip].filter(notBlank).join(' ') || undefined
  }

  const subText = getFormattedAddress()

  return {
    label: name,
    value: name,
    renderItemIcon: (isSelected?: boolean) => renderSelectOptionIcon(isSelected, svgProps),
    subText,
    extraOptions: {
      street: street,
      street2: street2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      UUID: UUID,
    },
  }
}
