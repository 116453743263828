import { MutableRefObject } from 'react'

import Bee from '@beefree.io/sdk'
import { IBeeConfig, IPluginRow, RowsConfiguration } from '@beefree.io/sdk/dist/types/bee'
import { PersonalizationItem } from '@complex/Personalization/utils/Personalization.context'
import { MediaPickerModalType } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { EmailComposerRowCategoryDto, EmailContentEditSessionEntity } from '@graphql/types/microservice/email-management-types'
import { Campaign, GetLastPublishedMessageContentQuery, SubscriptionCategoryGq } from '@graphql/types/query-types'
import {
  ComposerHistoryState,
  DetectedURLChanges,
  EmailComposerModalState,
  EmailMessage,
  EmailPreview,
  EmailSender,
  EmailValidations,
  MessageLimits,
  OnStatusChangeFunc,
  SEND_TYPE,
} from '@utils/composer/context/EmailComposer.context'
import { LandingPage, PublicUrlManager } from '@utils/composer/context/LandingPageComposer.context'
import { DeepPartial, DeepUpdateState } from '@utils/types'

import { BeeEventHooks } from '../beeEditor/beeEditorTypes'
import { MessageConfiguration } from '../emailComposer/types'

export enum CommonComposerTab {
  SETTINGS = 'settings',
  DESIGN = 'design',
  SEND = 'send',
  REVIEW = 'review',
  REPORT = 'report',
  CUSTOM_CODE = 'customCode',
}

export interface CommonComposerState {
  landingPage: LandingPage
  message: EmailMessage
  lastEdited?: number
  createdAt?: number

  publicUrlManager: PublicUrlManager
  // Page view
  tab: CommonComposerTab
  isPreview: boolean
  isTestSend: boolean
  isSend: boolean
  isClickthroughLinks: boolean
  isCustomerCareSaveModal: boolean
  isReviewAndSendModal: boolean
  modalState: EmailComposerModalState
  showEmailTooLargeWarning: boolean
  emailTooLargeWarningDismissed: boolean

  // Loading / saving
  loading: boolean
  isPlainTextComposerLoading: boolean
  isBeeLoading: boolean
  isCoEditing: boolean
  isIndicatingAutoSave: boolean
  autoSaveFailure: boolean
  isSaving: boolean
  isSaveFailed?: boolean
  isCreatedNewMessage?: boolean
  haveUnsavedChanges?: boolean
  autoSaveSuccess?: boolean | undefined
  sendErrorCount?: boolean
  savedWithTempId?: boolean
  isUploadHtmlFirstEdit?: boolean
  isEmailCrmFirstEdit?: boolean
  retrieveMessageSuccess?: boolean
  createMessageSuccess?: boolean

  // Bee editor
  beeEditorRef: MutableRefObject<Bee | undefined>
  beeConfig?: IBeeConfig
  startSession?: boolean
  shouldStartNewSession?: boolean
  sessionId?: string
  lastEmailContentEditSession?: EmailContentEditSessionEntity | null
  editingUsernames: string[]
  isCoeditingPrimaryUser: boolean
  isInitializeBeeEditor: boolean

  // Account / user data
  personalizations?: PersonalizationItem[]
  emailSenders: EmailSender[]
  analyticsDomains: string[]
  subscriptionCategories: SubscriptionCategoryGq[]
  campaigns: Campaign[]
  hasFatigueRules: boolean
  launchApproval: string
  messageLimits: MessageLimits
  savedRowCategories: EmailComposerRowCategoryDto[]
  savedRowDuplicateError: boolean
  savedRows: IPluginRow[]

  // Component states
  preview: EmailPreview
  validations: EmailValidations
  lastSavedTitle: string
  cancelHeaderTitleEdit?: boolean
  recipientsCount: number
  previousSendType: SEND_TYPE
  // Alternate behaviors
  /**
   * For behavior that depends on Bee editor events,
   * some logic needs to be different since we can't initialize Bee in a story at this time
   */
  isStory?: boolean
  disabledFeatures: {
    autoSave: boolean
    // R2 features
    editPlainText: boolean
  }
  messageConfiguration: MessageConfiguration

  // Detected changes with url
  detectedURLChanges: DetectedURLChanges

  //BEE config variables
  beeClientId?: string
  beeClientSecret?: string

  showMediaPickerModal?: boolean
  showRefreshPreviewModal?: boolean
  mediaPickerModalType?: MediaPickerModalType | undefined
  customTabFirstRender?: boolean

  brandingFavicon?: string
}

export type SaveComposerCommonType = (
  containerValues: CommonComposerState,
  autoSave?: boolean,
  isFromCopy?: boolean,
  published?: boolean,
  isFromCreateLandingPage?: boolean,
  isFromCreateTemplate?: boolean
) => Promise<boolean>

export type CommonComposerAPI<T extends CommonComposerState = CommonComposerState> = {
  update: DeepUpdateState<T>
  updateModal: <T extends EmailComposerModalState, K extends keyof T>(modal: K, value: T[K], closeOthers?: boolean) => void
  updatePreview: (fields: Partial<EmailPreview>) => void
  updateValidations: (fields: DeepPartial<EmailValidations>) => void
  onTest: () => void
  onEditPlainText: (onConfirm?: VoidFunction) => void
  onPreview: () => void
  onTabChange: (tab: CommonComposerTab, state?: ComposerHistoryState) => void
  onStatusChange: OnStatusChangeFunc
  onJoinSession: () => void
  onCreateSubscriptionCategory: (name: string) => Promise<SubscriptionCategoryGq | undefined>
  onCreateEmailContentEditSession: (emailContentId: string, sessionId: string) => Promise<EmailContentEditSessionEntity | undefined>
  onCreateCampaign: (name: string) => Promise<Campaign | undefined>
  onConvertToTemplate: (discardDraft: boolean) => Promise<{ errors?: string; data?: string }>
  onClose: () => void
  onAutoSave: (forceEnabled?: boolean) => Promise<void> | undefined
  onSave: (
    pureSave?: boolean,
    isSilentSave?: boolean,
    LPpublished?: boolean,
    lastPublishedMessageData?: GetLastPublishedMessageContentQuery['getLastPublishedMessageContent']
  ) => Promise<boolean | undefined>
  onSaveAndClose: () => Promise<void>
  onSaveAsCopy: () => Promise<void>
  onSaveAsDraft: () => Promise<void>
  onSaveAsTemplate: () => Promise<void>
  onCheckSubjectAndPreviewText: () => Promise<void>
  onDiscardAndClose: () => Promise<void>
  onGetRowsConfigurations?: (hasExternalContentURLs?: boolean) => RowsConfiguration | undefined
  eventHooks: BeeEventHooks
}
