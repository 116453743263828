import { initReactI18next, useTranslation as useTranslationi18next } from 'react-i18next'

// eslint-disable-next-line no-restricted-imports
import enJson from '@src/../static/locales/en.json'
import i18n from 'i18next'
import { v4 as uuidv4 } from 'uuid'

import { FEATURE_FLAGS } from '@utils/const/features/availableFeatures'
import { getFeatureFlag } from '@utils/const/features/featureFlags'
import { TLogger } from '@utils/tLogger'

import { Environment } from '../env'

export const rootContext = '/app'
export const legacyActonContext = '/acton'

export const EXTERNAL_WINDOW_PATH = '/legacy'

export const MIN_TABLE_ROWS_FOR_PAGINATION = 11
export const DAY_INTERVAL = 24 * 3600 * 1000

export const RESPONSIVE_WIDTH_MIN = 885
export const RESPONSIVE_WIDTH_MAX = 1045

export const USER_NOT_VALID_ERROR = 1
export const PROGRAM_NOT_FOUND_ERROR = 2
export const DATA_FETCHING_ERROR = 5
export const NO_PERSONALIZATION_FOUND_ERROR_CODE = 36
export const FAILED_TO_START_ERROR = 500

export const INPUT_DEBOUNCE_TIME = 500

export const TOP_BAR_HEIGHT = 60

export const SERVICES_AGREEMENT_LINK = 'https://act-on.com/services-agreement'

export const MAX_EMAIL_INPUT_LENGTH = 128
export const MAX_SELECT_SEARCH_INPUT_LENGTH = 80
export const CACHE_BUSTER = '139.0'

declare const __PROD__: boolean
declare const __STAGING__: boolean
declare const __TEST__: boolean
declare const __DEV__: boolean
declare const __DEBUG__: boolean
declare const __LOCAL_DEV__: boolean
declare const __PATH__: string
declare const __AOA_ENV__: string
declare const jest: any

export interface OnComposeAutoResponse {
  userSaved: boolean
  msgid: string
  bufferid?: boolean
  subject?: string
  title?: string
}

declare global {
  interface Window {
    onComposeAutoResponseCallbacks: { [key: string]: (response: OnComposeAutoResponse) => void }
    onComposeAutoResponseDone(response: OnComposeAutoResponse): void
    __ENV__: Environment
    isIE?: boolean
    newrelic: any
    Forethought: any
    Cypress: any
    /** If using StoryPropsMapper, in the Storybook env this will be the title of the current story, */
    currentStory?: string
    /** Contains the url to redirect to when user with multi-factor authentication logs out */
    mfaLogoutRedirect?: string
  }
}

export function isAOATest(): boolean {
  const isCypressAOATest = isCypress() && window.Cypress.spec.relative.includes('/aoa/')
  return isCypressAOATest
}

export function isProd(): boolean {
  return typeof __PROD__ !== 'undefined' && __PROD__
}

export function isStaging(): boolean {
  return typeof __STAGING__ !== 'undefined' && __STAGING__
}

export function isTest(): boolean {
  return typeof __TEST__ !== 'undefined' && __TEST__
}

export function isDev(): boolean {
  return typeof __DEV__ !== 'undefined' && __DEV__
}

export function isDebug(): boolean {
  return typeof __DEBUG__ !== 'undefined' && __DEBUG__
}

export function isLocal(): boolean {
  return typeof __LOCAL_DEV__ !== 'undefined' && __LOCAL_DEV__
}

export function getPath(): string {
  return __PATH__
}

export function getEnvironment(): Environment {
  return window.__ENV__
}

export function getAOAEnvironment(): string | undefined {
  try {
    return __AOA_ENV__
  } catch (e) {}
  return undefined
}

export function setEnvironment(env: Environment) {
  window.__ENV__ = env
}

export function getStaticImageRoot() {
  if (isJest()) {
    return rootContext
  }
  const aoaEnv = getAOAEnvironment()
  return aoaEnv ? `/${aoaEnv}` : rootContext
}

export function isJest() {
  let isJest = false
  try {
    isJest = jest !== undefined
  } catch (e) {}
  return isJest
}

export function isCypress() {
  return typeof window !== 'undefined' && window.Cypress !== undefined
}

export type TranslationKey = keyof typeof enJson

const tLogger = new TLogger()

export function useTranslation() {
  if (isJest()) {
    i18n.use(initReactI18next).init({
      lng: 'en',
      fallbackLng: 'en',
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false,
      },

      resources: { en: { translation: enJson } },
    })
    return { t: i18n.t as any, i18n }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translationi18next = useTranslationi18next()
  translationi18next.t = tLogger.wrap(translationi18next.t)
  return translationi18next
}

export function getUUID(useRandom?: boolean) {
  return isJest() ? `id${useRandom ? Math.random() : ''}` : uuidv4()
}

export function isMocks() {
  return getFeatureFlag(FEATURE_FLAGS.MOCK) === true
}

export function bindGlobalEvents() {
  document.addEventListener('mousedown', () => {
    ;(window as any).isClick = true
  })
  document.addEventListener('keydown', () => {
    ;(window as any).isClick = false
  })
}

export function isEventClick() {
  return (window as any).isClick ?? false
}

export function isIE() {
  return window.isIE ?? false
}

export function bindComposerResponse() {
  window.onComposeAutoResponseCallbacks = {}
  window.onComposeAutoResponseDone = function (response: OnComposeAutoResponse) {
    Object.keys(window.onComposeAutoResponseCallbacks).forEach((key) => {
      try {
        window.onComposeAutoResponseCallbacks[key](response)
      } catch (e) {
        if (__DEBUG__) {
          console.log(e) // eslint-disable-line no-console
        }
      }
    })
  }
}

export function listenToComposerResponse(key: string, callback: (response: OnComposeAutoResponse) => void) {
  if (window && window.onComposeAutoResponseCallbacks) {
    window.onComposeAutoResponseCallbacks[key] = callback
  }
}

export function removeComposerResponseListener(key: string) {
  if (window && window.onComposeAutoResponseCallbacks) {
    delete window.onComposeAutoResponseCallbacks[key]
  }
}

export function undefinedReturnedFunction() {
  return undefined
}

export const domain = () => (isProd() ? 'actonsoftware.com' : isStaging() ? 'acton-staging.com' : 'ez-touch.net')

export class FormatFile {
  static readableBytes = (bytes: number) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const neg = bytes < 0
    if (neg) {
      bytes = -bytes
    }

    if (bytes < 1) {
      return `${(neg ? '-' : '') + bytes} B`
    }
    const exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
    bytes = parseFloat((bytes / 1024 ** exponent).toFixed(2))

    return `${(neg ? '-' : '') + bytes} ${units[exponent]}`
  }

  static columnToLetter = (col: number) => {
    let temp
    let letter = ''
    let column = col

    while (column >= 0) {
      temp = column % 26
      letter = String.fromCharCode(temp + 65) + letter
      column = (column - temp - 1) / 26
    }
    return letter
  }
}
export default {
  domain,
  isProd,
  isTest,
  isDev,
  isDebug,
  isLocal,
  getPath,
  getEnvironment,
  setEnvironment,
  useTranslation,
  isMocks,
  isEventClick,
  isIE,
  getUUID,
  listenToComposerResponse,
  removeComposerResponseListener,
  undefinedReturnedFunction,
}
