import { useEffect, useState } from 'react'

import { paginateAssetResults } from '@complex/AssetPickerModal/utils/AssetPickerModal.filtering.utils'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { CustomRequestFilter, FilterCounts, ListingPageExternalApi, ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { AssetType, TriggeredMessagePickerUi } from '@graphql/types/query-types'
import { filterNotEmptyArray } from '@utils/array'
import { useTranslation } from '@utils/const/globals'
import { allEmailTriggeredFilter, FilterDefinition } from '@utils/filter'
import { partialMatch } from '@utils/searchUtils'

import {
  renderTriggeredCustomFilters,
  formAssetsFilter,
  programAssetsFilter,
  webinarAssetsFilter,
  otherAssetsFilter,
  emailTriggeredPickerFilters,
} from './EmailTriggeredPicker.sidebar'
import { renderTriggeredSearchTableColumns, renderTriggeredTableColumns } from './EmailTriggeredPicker.tables'
import { useEmailTriggeredRequests } from './useEmailTriggeredRequests'
import { EmailPickerProps, EmailType } from '../useEmailPickerProps'

export const useEmailTriggeredPickerProps = (listingApi: ListingPageExternalApi | undefined) => {
  const { t } = useTranslation()
  const [filterCounts, setFilterCounts] = useState<FilterCounts>()
  const request = useEmailTriggeredRequests()

  useEffect(() => {
    if (listingApi && filterCounts !== undefined) {
      listingApi.updateFilterCounts(filterCounts)
    }
  }, [listingApi, filterCounts])

  const convertEmailsToItemDto = (emails: (TriggeredMessagePickerUi | undefined)[]) => {
    return emails.filter(filterNotEmptyArray).map((email) => {
      const dto = { ...email } as ListingPageItem
      dto.name = email.title
      dto.externalId = email.id
      dto.item = JSON.stringify(dto)
      return dto
    })
  }

  const isFormEmail = (email: TriggeredMessagePickerUi) => (`${email.assetType}` as AssetType) === 'FORMS'
  const isProgramEmail = (email: TriggeredMessagePickerUi) => (`${email.assetType}` as AssetType) === 'PROGRAMS'
  const isWebinarEmail = (email: TriggeredMessagePickerUi) => (`${email.assetType}` as AssetType) === 'WEBINARS'
  const isOtherEmail = (email: TriggeredMessagePickerUi) => (`${email.assetType}` as AssetType) === 'OTHER'

  const filterByType = (emails: TriggeredMessagePickerUi[], filter?: FilterDefinition) => {
    if (!filter) {
      return emails
    }
    const items = [...emails]
    if (filter.name === formAssetsFilter.name) {
      return items.filter(isFormEmail)
    } else if (filter.name === programAssetsFilter.name) {
      return items.filter(isProgramEmail)
    } else if (filter.name === webinarAssetsFilter.name) {
      return items.filter(isWebinarEmail)
    } else if (filter.name === otherAssetsFilter.name) {
      return items.filter(isOtherEmail)
    }
    return []
  }

  const filterBySearch = (emails: TriggeredMessagePickerUi[], search: string) => {
    return emails.filter((email) => partialMatch(email, ['title', 'assetName'], search))
  }

  const updateFilterCounts = (items: TriggeredMessagePickerUi[]) => {
    setFilterCounts({
      [allEmailTriggeredFilter.name ?? '']: items.length,
      [formAssetsFilter.name]: filterByType(items, formAssetsFilter).length,
      [programAssetsFilter.name]: filterByType(items, programAssetsFilter).length,
      [webinarAssetsFilter.name]: filterByType(items, webinarAssetsFilter).length,
      [otherAssetsFilter.name]: filterByType(items, otherAssetsFilter).length,
    })
  }

  const getAssetTypeFromFilter = (filter: FilterDefinition): AssetType => {
    if (filter.name === formAssetsFilter.name) {
      return 'FORMS'
    } else if (filter.name === programAssetsFilter.name) {
      return 'PROGRAMS'
    } else if (filter.name === webinarAssetsFilter.name) {
      return 'WEBINARS'
    } else if (filter.name === otherAssetsFilter.name) {
      return 'OTHER'
    }
    return 'ALL'
  }

  const filterRequest = async (filter: FilterDefinition, search?: string, params?: FilterQueryParams) => {
    const assetType = getAssetTypeFromFilter(filter)
    const variables = assetType === 'OTHER' ? { assetType: 'ALL' as AssetType } : { assetType }
    const response = await request.getAllTriggeredMessagesRequest(variables)
    if (response?.data?.getAllTriggeredMessages) {
      const folders = response.data.getAllTriggeredMessages.filter(filterNotEmptyArray)
      let items: TriggeredMessagePickerUi[] = folders.flatMap((folder) => folder.messages?.filter(filterNotEmptyArray) ?? [])
      if (assetType === 'OTHER') {
        items = items.filter(isOtherEmail)
      }

      let itemResults: ListingPageItem[] = []
      if (search) {
        items = filterBySearch(items, search)
        itemResults = convertEmailsToItemDto(items)
      } else {
        itemResults = convertEmailsToItemDto(items)
        itemResults = paginateAssetResults(itemResults, params)
      }

      if (filter.name === allEmailTriggeredFilter.name) {
        updateFilterCounts(items)
      }
      return { data: itemResults }
    }
    return { data: [], error: response?.errors?.join(', ') }
  }

  const config: EmailPickerProps = {
    customSources: {
      allItemFilter: allEmailTriggeredFilter,
      itemName: 'Email',
      label: 'Triggered messages',
      mainColumnName: 'Email Title',
      customRequestGeneral: {
        // Unused; delayed calculation on frontend
        getCountQueryRequest: async () => ({}),
        getSubTypesByTypesRequest: async () => ({}),
        getCountForFavoritesAndCreatedByMeAndRecentRequest: async () => ({}),
      },
      customRequestFilters: [allEmailTriggeredFilter, ...emailTriggeredPickerFilters].map<CustomRequestFilter>((filter) => ({
        filter,
        request: async (_, params) => filterRequest(filter, '', params),
        searchRequest: async (search, _, params) => filterRequest(filter, search, params),
      })),
      value: EmailType.TRIGGERED,
      itemType: EmailType.TRIGGERED,
      searchPlaceholder: 'emails or asset',
      sidebarProps: {
        hideFolders: true,
        hideTags: true,
      },
    },
    renderSearchColumns: (_, __, search, folders) => renderTriggeredSearchTableColumns(search, folders, t),
    renderTableColumns: renderTriggeredTableColumns(t),
    renderCustomFilters: (params) => renderTriggeredCustomFilters(params, t),
    hasCustomFilters: true,
    hasTags: false,
    hasFavorites: false,
    hasCreatedByMe: false,
    subTypes: [],
    hideFolders: true,
    i18nListPageKey: 'Emails.Triggered',
    sortingBy: [{ id: 'sentTime', desc: true }],
  }
  return config
}
