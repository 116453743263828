import React, { FC, RefObject, useCallback, useContext, useState } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader/Loader'
import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerContext } from '@utils/composer/context/EmailComposer.context'

import { formStyles } from './FormPreviewIframe'

import './EmailComposerPreviewIframe.css'
interface EmailComposerPreviewIframeProps {
  previewFrameRef?: RefObject<HTMLIFrameElement>
  html: string
  plainTextMode?: boolean
  isMobile?: boolean
  isRotated?: boolean
  isDarkMode?: boolean
  scaleScore?: number | null
  isLandingPage?: boolean
}

const rootClass = 'email-composer-preview-iframe'

const EmailComposerPreviewIframe: FC<EmailComposerPreviewIframeProps> = ({
  previewFrameRef,
  html,
  isMobile,
  isRotated,
  isDarkMode,
  plainTextMode,
  scaleScore,
  isLandingPage,
}) => {
  const {
    values: {
      message: { id },
      isSaving,
    },
  } = useContext(EmailComposerContext)
  const { accountId } = useAccountSettings()
  const [isIframeReady, setIframeReady] = useState(false)
  const [isScaled, setScaled] = useState(false)

  const formattedHtml = html?.replaceAll(`acton/vcal/${accountId}/{{Env.MsgId}}`, `acton/vcal/${accountId}/${id}`)
  const applyIframeStyles = useCallback(
    (iframe: HTMLIFrameElement) => {
      if (plainTextMode) return

      const iframeDoc = iframe.contentDocument
      if (!iframeDoc) return

      const iframeBody = iframeDoc.body
      if (!iframeBody) return

      // Add form styles to iframe head
      const styleElement = iframeDoc.createElement('style')
      styleElement.textContent = formStyles
      iframeDoc.head.appendChild(styleElement)

      // Prevent clicks on <a> and <button>, but allow hover effects
      if (isLandingPage) {
        const script = iframeDoc.createElement('script')
        script.textContent = `
        document.addEventListener("click", function(event) {
          const target = event.target.closest("a, button");
          if (target) {
            event.preventDefault();
            event.stopPropagation();
          }
        }, true);
      `
        iframeBody.appendChild(script)
      }

      const link = iframeDoc.createElement('link')
      link.rel = 'stylesheet'

      link.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'
      iframeDoc.head.appendChild(link)

      if (scaleScore) {
        iframeBody.style.transformOrigin = '0 0'
        iframeBody.style.transform = `scale(${scaleScore})`
        iframeBody.style.margin = '0'

        const bodyHeight = iframeBody.scrollHeight
        const scaledHeight = bodyHeight * scaleScore
        const htmlElement = iframeDoc.documentElement
        if (htmlElement) {
          htmlElement.style.height = `${scaledHeight}px`
          iframe.style.height = `${scaledHeight + 10}px`
          setScaled(true)
        }
      }

      if (!plainTextMode && isDarkMode) {
        iframe.contentWindow?.postMessage('setDarkMode', '*')
      }
    },
    [isIframeReady, isScaled, isSaving, scaleScore, plainTextMode, isDarkMode]
  )

  const onLoad = useCallback(
    (event: React.SyntheticEvent<HTMLIFrameElement>) => {
      const iframe = event.target as HTMLIFrameElement

      if (!iframe.contentDocument || !iframe.contentDocument.body) {
        return
      }

      setIframeReady(true)
      setScaled(false)
      applyIframeStyles(iframe)
    },
    [applyIframeStyles, isIframeReady, isScaled]
  )

  const displayLoading = !plainTextMode && !isMobile && (!isScaled || !isIframeReady || isSaving)

  return (
    <div
      className={classNames(`${rootClass}__wrapper`, {
        [`${rootClass}__wrapper-plain`]: true,
        [`${rootClass}__wrapper--mobile-inner`]: isMobile,
        [`${rootClass}__wrapper--mobile-rotated`]: isRotated,
        ['not-rendered']: displayLoading,
      })}
    >
      {displayLoading && <Loader dataTest="review-and-send-loader" className="review-and-send-loader" />}
      <iframe
        key={isMobile ? '' : scaleScore}
        ref={previewFrameRef}
        className={classNames(rootClass, {
          [`${rootClass}--mobile`]: isMobile,
          [`${rootClass}--rotated`]: isRotated,
        })}
        data-test={rootClass}
        name={'preview-frame'}
        title={'Act-On Test Message'}
        srcDoc={plainTextMode ? undefined : formattedHtml}
        src={plainTextMode ? `data:text/plain;charset=utf-8,${encodeURIComponent(html)}` : undefined}
        onLoad={onLoad}
      />
    </div>
  )
}

export default EmailComposerPreviewIframe
