import React from 'react'

import { TFunction } from 'i18next'

import { RenderCustomFiltersParams } from '@complex/ListingPage/Context/ListingPageCommon.context'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import { SvgNames } from '@components/Svg'
import { FilterDefinition } from '@utils/filter'
import { toTrainCase } from '@utils/strings'

const rootClass = 'email-picker-modal'

export const formAssetsFilter: FilterDefinition = {
  name: 'Forms',
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}
export const programAssetsFilter: FilterDefinition = {
  name: 'Programs',
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}
export const webinarAssetsFilter: FilterDefinition = {
  name: 'Webinars',
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}
export const otherAssetsFilter: FilterDefinition = {
  name: 'Other',
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
}

export const emailTriggeredPickerFilters = [formAssetsFilter, programAssetsFilter, webinarAssetsFilter, otherAssetsFilter]

export const renderTriggeredCustomFilters = (
  { activeFilter, menuActions, filterCounts, renderCustomFilterWithCount }: RenderCustomFiltersParams,
  t: TFunction
) => [
  {
    header: t('Asset Type'),
    isOpen: true,
    icon: SvgNames.toggles,
    dataTest: 'menu-items',
    content: (
      <RadioGroup className={`listing-page__custom-filters`} verticalLayout>
        {emailTriggeredPickerFilters.map((filter) =>
          renderCustomFilterWithCount(
            <Radio
              key={filter.name}
              label={filter.name}
              checked={activeFilter.name === filter.name}
              onChange={() => menuActions.clickCustomFilter(filter)}
              dataTest={`${rootClass}-${toTrainCase(filter.name)}-filter`}
            />,
            filterCounts[filter.name],
            filter.name
          )
        )}
      </RadioGroup>
    ),
  },
]
