import React, { FC, useContext, useMemo } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'

import classNames from 'classnames'

import { GroupDefinitionDto, RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import SegmentDefinition from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition'
import { getBuilderDefinitionElementById } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { LogicalOperator } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { filterNotEmptyArray } from '@utils/array'

import './SegmentComposerDragLayer.css'

interface SegmentComposerDragLayerProps {
  className?: string
  dataTest?: string
}

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

const rootClass = 'segment-composer-drag-layer'

const SegmentComposerDragLayer: FC<SegmentComposerDragLayerProps> = (props: SegmentComposerDragLayerProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    values: {
      segmentDefinition: { group },
    },
  } = useContext(SegmentComposerContext)

  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem() as { id: string; operator?: LogicalOperator } | null,
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const itemBeingDragged = useMemo(() => {
    const items: (RowDefinitionDto | GroupDefinitionDto)[] = []
    if (group) {
      if (group.rows) {
        items.push(...group.rows.filter(filterNotEmptyArray))
      }
      if (group.subGroups) {
        items.push(...group.subGroups.filter(filterNotEmptyArray))
      }
    }
    if (item?.id && items.length > 0) {
      return getBuilderDefinitionElementById(item.id, group as GroupDefinitionDto)
    }
  }, [group, item])

  if (!isDragging || !itemBeingDragged) {
    return null
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div style={getItemStyles(currentOffset)}>
        <SegmentDefinition dataTest={`${dataTest}-preview`} builderElement={itemBeingDragged} operator={item?.operator} isPreview />
      </div>
    </div>
  )
}

export default SegmentComposerDragLayer
