import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import './MobileView.css'

interface MobileViewProps {
  content: ReactNode
  isRotated?: boolean
  size?: 'medium' | 'large'
  className?: string
  dataTest?: string
}

const rootClass = 'mobile-view'

export const MobileView: FC<MobileViewProps> = ({ content, isRotated = false, size = 'medium', className = rootClass, dataTest = rootClass }) => {
  return (
    <div className={classNames(rootClass, className, `${rootClass}--${size}`, { [`${rootClass}--rotated`]: isRotated })} data-test={dataTest}>
      <div className={`${rootClass}__content`} data-test={`${dataTest}-content`}>
        {content}
      </div>
    </div>
  )
}
