import React, { FC, useEffect, useState } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerPreviewMetadataProps } from '@complex/AssetPickerModal/Components/Preview/AssetPickerPreviewMetadata/AssetPickerPreviewMetadata'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { paginateAssetResults, searchAssets } from '@complex/AssetPickerModal/utils/AssetPickerModal.filtering.utils'
import { FilterQueryParams } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  CustomSource,
  FilterCounts,
  ListingPageExternalApi,
  ListPageCommonState,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import Typography from '@components/Typography/Typography'
import { filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { useTranslation } from '@utils/const/globals'
import { relativeDateFormat, standardDateFormat } from '@utils/date/dateUtils'

import { allSmsDraftsFilter, allSmsScheduledFilter, allSmsSentFilter } from './SmsPickerModal.sidebar'
import { renderSmsSearchTableColumns, renderSmsTableColumns } from './SmsPickerModal.tables'
import { convertSmsListingEntryToItemDto, SmsPickerSourceProps, SmsPickerType, SmsPickerUi } from './SmsPickerModal.utils'
import SmsPickerPreview from './SmsPickerPreview/SmsPickerPreview'
import { useSmsPickerRequests } from './useSmsPickerRequests'

export type SmsPickerModalProps = AssetPickerModalBaseProps

const rootClass = 'sms-picker-modal'

const SmsPickerModal: FC<SmsPickerModalProps> = (props) => {
  const [itemType, setItemType] = useState(SmsPickerType.SENT)
  const [counts, setCounts] = useState<FilterCounts>()
  const { t } = useTranslation()
  const [listingApi, setListingApi] = useState<ListingPageExternalApi>()

  const onSelectedCustomSourceChange = (selectedCustomSource: CustomSource) => {
    setItemType(selectedCustomSource.value as SmsPickerType)
  }

  const { getLaunchesForAccountPaginatedRequest, getMessageDetailsRequest } = useSmsPickerRequests()

  useEffect(() => {
    if (listingApi && counts) {
      listingApi.updateFilterCounts(counts)
    }
  }, [listingApi, itemType, counts])

  const propsMap: Record<SmsPickerType, SmsPickerSourceProps> = {
    [SmsPickerType.SENT]: {
      allItemFilter: allSmsSentFilter,
      columnNames: {
        name: t('SMS Title'),
        userName: t('Sent by'),
        timestamp: t('Sent date'),
      },
      label: t('Sent SMS'),
      i18nListPageKey: 'SMS.Sent',
    },
    [SmsPickerType.DRAFT]: {
      allItemFilter: allSmsDraftsFilter,
      columnNames: {
        name: t('SMS Title'),
        userName: t('Last edited by'),
        timestamp: t('Edited'),
      },
      label: t('Draft SMS'),
      i18nListPageKey: 'SMS.Draft',
    },
    [SmsPickerType.SCHEDULED]: {
      allItemFilter: allSmsScheduledFilter,
      columnNames: {
        name: t('SMS Title'),
        userName: t('Scheduled by'),
        timestamp: t('Send date'),
      },
      label: t('Scheduled SMS'),
      i18nListPageKey: 'SMS.Scheduled',
    },
  }
  const currentProps = propsMap[itemType]

  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update) => {
    const selectedItem = listPageValues.selectedRows[0] as SmsPickerUi

    const { data } = await getMessageDetailsRequest({ launchId: selectedItem.id ?? '' })

    const dateFormatter = itemType === SmsPickerType.SCHEDULED ? relativeDateFormat : standardDateFormat

    const previewMetadata: AssetPickerPreviewMetadataProps = {
      header: selectedItem.name ?? '',
      subHeader: t('Message Details'),
      metadata: [
        { label: currentProps.columnNames.userName, value: t(selectedItem.userName ?? '') },
        {
          label: currentProps.columnNames.timestamp,
          value: <Typography text={dateFormatter(selectedItem.timestamp)} />,
        },
      ],
    }
    const renderPreview = () => <SmsPickerPreview message={data?.getMessageDetails?.messageText ?? ''} />
    update({
      loading: false,
      renderPreview,
      previewMetadata,
    })
  }

  const tableProps: AssetPickerTableProps = {
    columns: renderSmsTableColumns(itemType, currentProps.columnNames, t),
    renderSearchColumns: (_, __, search) => renderSmsSearchTableColumns(itemType, currentProps.columnNames, search, t),
    listPage: currentProps.i18nListPageKey,
    enableLazyLoading: true,
    sortingBy: [{ id: currentProps.columnNames.name, desc: false }],
  }

  const request = async (status: SmsPickerType, params?: FilterQueryParams, search?: string) => {
    const response = await getLaunchesForAccountPaginatedRequest({ size: 10_000, page: 0, status })
    const { data, errors } = response ?? {}
    setCounts({ [propsMap[status].allItemFilter.name]: data?.getLaunchesForAccountPaginated?.totalCount ?? 0 })

    let processedData = data?.getLaunchesForAccountPaginated?.messages?.filter(filterNotEmptyArray) ?? []

    if (search) {
      processedData = (await searchAssets(processedData, search, ['title'])).data
    }
    const paginatedData = paginateAssetResults(
      processedData.map((listingEntry) => convertSmsListingEntryToItemDto(listingEntry)),
      params
    )
    return {
      data: paginatedData,
      errors,
    }
  }

  const sidebarProps: AssetPickerSidebarProps = {
    allItemFilter: currentProps.allItemFilter,
    hasRecent: false,
    hasCreatedByMe: false,
    hasFavorites: false,
    hideFilterCount: false,
    hasSalesUsersAction: false,
    hideFolders: true,
    customSources: [SmsPickerType.SENT, SmsPickerType.DRAFT, SmsPickerType.SCHEDULED].map<CustomSource>((statusType) => ({
      allItemFilter: propsMap[statusType].allItemFilter,
      mainColumnName: t('SMS Title'),
      value: statusType,
      itemType: statusType,
      label: propsMap[statusType].label,
      customRequestGeneral: {
        getCountQueryRequest: async () => ({}),
      },
      customRequestFilters: [
        {
          filter: propsMap[statusType].allItemFilter,
          request: async (_currentPage, params) => request(statusType, params),
          searchRequest: async (query, _currentPage, params) => request(statusType, params, query),
        },
      ],
    })),
  }

  const listingPageProps: AssetPickerListingPageProps = {
    customPreviewItemCall,
    onApiAvailable: (api) => {
      setListingApi(api)
    },
    sidebarProps,
    hasCustomRequests: true,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: rootClass,
    listingPageProps,
    tableProps,
    hasTags: false,
    hasCustomFilters: false,
    hasSidebar: true,
    hasPreview: true,
    itemType: itemType as unknown as ItemType,
    restrictSelectionToOneSource: false,
    isOpen: true,
    hideRecordsCount: true,
  }

  return <AssetPickerModal {...props} {...assetPickerProps} onSelectedCustomSourceChange={onSelectedCustomSourceChange} />
}

export default SmsPickerModal
