import React from 'react'

import { t } from 'i18next'
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback'

import { MAX_STANDARD_ACTIONS_COUNT } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Checkbox from '@components/Checkbox'
import Radio from '@components/Radio'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { ActionType, Selection, SelectionType } from '@components/TableV2/tableV2TS/enums'
import {
  HeaderAction,
  IndeterminateSelect,
  RenderTagManagerTriggerParams,
  RowAction,
  TableV2CheckboxProps,
  TableV2RadioProps,
} from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps, ConditionalAction, ConditionalActionValue } from '@components/TableV2/tableV2TS/types'
import TagManagerTrigger from '@components/TagManagerTrigger/TagManagerTrigger'
import TagManagerTriggerWithNumber from '@components/TagManagerTriggerWithNumber/TagManagerTriggerWithNumber'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import Tooltip from '@components/Tooltip/Tooltip'
import { IndexSignature } from '@interface/common'
import { Row, isFunction, RowSelectionState } from '@tanstack/react-table'
import { Message } from '@utils/sms.utils'

export const BODY_MIN_HEIGHT = 485
const NEAR_BOTTOM_DISTANCE = 300
export const VIRTUAL_SCROLLER_OVERSCAN_COUNT = 5
export const VIRTUAL_SCROLLER_ITEM_ESTIMATED_SIZE = 44

export const selections = [Selection.Checkbox, Selection.Radio]

export const handleScrollArea = (ev: Event, debouncedLoading: DebouncedState<() => void>, canLoadData: boolean) => {
  const { scrollHeight, scrollTop, offsetHeight } = ev.target as HTMLDivElement
  const scrolledToBottom = scrollTop >= scrollHeight - offsetHeight
  const distanceToBottom = Math.abs(scrollHeight - scrollTop - offsetHeight)
  const nearBottom = distanceToBottom <= NEAR_BOTTOM_DISTANCE

  if ((nearBottom || scrolledToBottom) && canLoadData) debouncedLoading()
}

export const pageContainerScroll = (pageContainer: Element, debouncedLoading: DebouncedState<() => void>, canLoadData: boolean) => {
  const { scrollTop, scrollHeight, clientHeight } = pageContainer
  const scrolledToBottom = scrollTop + clientHeight >= scrollHeight
  const distanceToBottom = scrollHeight - scrollTop - clientHeight
  const nearBottom = distanceToBottom <= NEAR_BOTTOM_DISTANCE

  if ((nearBottom || scrolledToBottom) && canLoadData) debouncedLoading()
}

export const IndeterminateCheckbox = ({
  indeterminate = false,
  dataTest = 'row-IndeterminateCheckbox',
  className,
  tooltip,
  ...rest
}: IndeterminateSelect) => {
  const checkbox = <Checkbox withoutLabel indeterminate={indeterminate} dataTest={dataTest} className={className} {...rest} />
  if (tooltip) {
    return (
      <Tooltip trigger={checkbox} inline={false} withoutTail alwaysShowOnHover>
        {tooltip}
      </Tooltip>
    )
  } else {
    return checkbox
  }
}

const selectAllCurrentPageRows = <T extends {}>(rows: Row<T>[], rowSelection: RowSelectionState): RowSelectionState => {
  return rows.reduce((acc, { id, subRows }) => {
    const updatedRowSelection = { ...acc, [id]: true }
    return { ...updatedRowSelection, ...selectAllCurrentPageRows(subRows, updatedRowSelection) }
  }, rowSelection)
}

const deselectAllCurrentPageRows = <T extends {}>(rows: Row<T>[], rowSelection: RowSelectionState): RowSelectionState => {
  return rows.reduce((acc, { id, subRows }) => {
    const { [id]: _, ...rest } = acc
    return subRows ? deselectAllCurrentPageRows(subRows, rest) : rest
  }, rowSelection)
}

export const tableV2Checkbox = <T,>({
  rowDisabledTitle,
  rowSelection,
  headerCheckboxDisabled,
  rowTooltip,
  overrideCheckboxCell,
  onRowCheckboxChange,
  onHeaderCheckboxChange,
}: TableV2CheckboxProps<T & { disabled?: boolean }>): ColumnDefWithAdditionalProps<T & { disabled?: boolean }> => ({
  id: Selection.Checkbox,
  enableCustomCellValue: true,
  header: ({ table }) => {
    const { rows } = table.getRowModel()
    const isIndeterminate = table.getIsSomePageRowsSelected()
    const isChecked = table.getIsAllPageRowsSelected()
    return (
      <IndeterminateCheckbox
        dataTest="header-checkbox"
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={() => {
          const toggleAll = !(isIndeterminate || isChecked)
          table.setRowSelection(toggleAll ? selectAllCurrentPageRows(rows, rowSelection) : deselectAllCurrentPageRows(rows, rowSelection))
          table.toggleAllPageRowsSelected(toggleAll)
          onHeaderCheckboxChange?.(table)
        }}
        disabled={!!headerCheckboxDisabled}
        title={headerCheckboxDisabled ? rowDisabledTitle : undefined}
      />
    )
  },
  cell: ({ row }) => {
    const overriddenCheckboxCell = overrideCheckboxCell?.(row)
    switch (true) {
      case overriddenCheckboxCell?.['cellStatus']:
        return overriddenCheckboxCell?.['cellValue']
      default:
        return (
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getCanSelect() && row.getIsSomeSelected()}
            onChange={(selected) => {
              onRowCheckboxChange?.(row)
              row.getToggleSelectedHandler()(selected)
            }}
            disabled={!row.getCanSelect()}
            title={!row.getCanSelect() ? rowDisabledTitle : ''}
            tooltip={rowTooltip?.(row)}
          />
        )
    }
  },
})

export const tableV2Radio = <T,>({
  rowDisabledTitle,
  rowTooltip,
  setRowSelection,
  tooltipProps = {
    position: 'top',
    withoutTail: true,
  },
}: TableV2RadioProps<T & { disabled?: boolean }>): ColumnDefWithAdditionalProps<T & { disabled?: boolean }> => {
  return {
    id: Selection.Radio,
    enableCustomCellValue: true,
    cell: ({ row, table }) => {
      const radio = (
        <Radio
          disabled={!row.getCanSelect()}
          checked={row.getIsSelected()}
          onChange={() => {
            if (!row.getIsSelected()) {
              table.toggleAllRowsSelected(false)
              setRowSelection({})
              row.toggleSelected(true)
            }
          }}
          name={`radio${row.id}`}
          title={!row.getCanSelect() ? rowDisabledTitle : undefined}
          value={`radio${row.id}`}
        />
      )
      const tooltip = rowTooltip?.(row)
      if (tooltip) {
        return (
          <Tooltip trigger={radio} inline={false} alwaysShowOnHover {...tooltipProps}>
            {tooltip}
          </Tooltip>
        )
      } else {
        return radio
      }
    },
  }
}

export const splitActions = <T extends {}>(actions: (RowAction<T> | HeaderAction<T>)[], data?: Row<T> | Row<T>[], actionType?: ActionType) => {
  const visibleActions = actions.filter(({ hidden }) => !(isFunction(hidden) ? hidden(data as Row<T>[]) : hidden))

  return visibleActions.reduce<(RowAction<T> | HeaderAction<T>)[][]>(
    (tempActions, currentAction) => {
      const inFooter = isFunction(currentAction.inFooter) ? currentAction.inFooter(data as Row<T>) : currentAction.inFooter
      const isInDropdown = isFunction(currentAction.inDropdown) ? currentAction.inDropdown(data as Row<T>) : currentAction.inDropdown
      const isHeaderActionInDropDown = actionType === ActionType.Header && visibleActions.length > MAX_STANDARD_ACTIONS_COUNT ? isInDropdown : false
      const isRowActionInDropDown = actionType === ActionType.Row ? isInDropdown : false
      const folderOrTagActions = actionType === ActionType.FolderOrTag ? isInDropdown : false

      return isHeaderActionInDropDown || isRowActionInDropDown || inFooter || folderOrTagActions
        ? [tempActions[0], [...tempActions[1], currentAction]]
        : [[...tempActions[0], currentAction], tempActions[1]]
    },
    [[], []]
  )
}

export const headerActionCount = (count: number) => t('Table.Default.Action.Text', { count })

export const getConditionalHeaderActions = <T extends {}>(
  actions: ConditionalAction<T>,
  rowsCount: number,
  ...args: Function[]
): HeaderAction<T>[] => {
  return Object.keys(actions).map((action, i) => {
    const { hiddenFieldName, icon, hasTooltip, iconSize } = actions[action as IndexSignature] as ConditionalActionValue<T>
    return {
      label: action,
      icon,
      iconSize,
      hasTooltip,
      hidden: (rows) =>
        rowsCount !== (rows as Row<T>[])?.length && (rows as Row<T>[]).every((row) => row.original[hiddenFieldName as IndexSignature]),
      onClick: () => args[i](),
      ...(actions[action as IndexSignature] as ConditionalActionValue<T>),
    }
  })
}

export const selectionType = <T extends {}>(rows: Row<T>[]) => {
  if (rows.some(({ id }) => id.includes('.'))) {
    const pattern = new RegExp(`^${rows[0].id.split('.')[0]}\.\d*`)
    return rows.every(({ id }) => pattern.test(id)) ? SelectionType.SUBROWS : SelectionType.MIXED
  } else {
    return SelectionType.ROWS
  }
}

export const sortDirection = {
  asc: <CaretIcon direction={CaretIconDirection.UP} />,
  desc: <CaretIcon direction={CaretIconDirection.DOWN} />,
}

export const caseInsensitive = <T extends {}>(prev: Row<T>, curr: Row<T>, columnId: string) => {
  const prevFirst =
    prev.original[columnId as IndexSignature]?.toString().toLowerCase() > curr.original[columnId as IndexSignature]?.toString().toLowerCase()
  const currFirst =
    prev.original[columnId as IndexSignature]?.toString().toLowerCase() < curr.original[columnId as IndexSignature]?.toString().toLowerCase()
  if (prevFirst) {
    return 1
  } else if (currFirst) {
    return -1
  } else {
    return 0
  }
}

export const statusDateTime = <T extends {}>(prev: Row<T>, curr: Row<T>, columnId: string) => {
  const prevDate = new Date(prev.original[columnId as IndexSignature]?.toString())
  const prevIsDate = !isNaN(prevDate.valueOf())

  const currDate = new Date(curr.original[columnId as IndexSignature]?.toString())
  const currIsDate = !isNaN(currDate.valueOf())
  if (prevIsDate && currIsDate) {
    return prevDate > currDate ? 1 : -1
  } else {
    if (!prevIsDate) {
      return !currIsDate ? 0 : 1
    } else {
      return 1
    }
  }
}

const renderTagManagerTrigger = ({ appliedTags, numberOfTagsDisplayed, rootClass }: RenderTagManagerTriggerParams) => {
  if (appliedTags.length === 0) {
    return <TagManagerTriggerWithText className={`${rootClass}__tag-manager-trigger-with-text`} />
  } else if (appliedTags.length > numberOfTagsDisplayed) {
    return <TagManagerTriggerWithNumber number={appliedTags.length - numberOfTagsDisplayed} />
  } else {
    return <TagManagerTrigger />
  }
}

export const renderTagManagerTriggerWithTooltip = (title: string, rest: RenderTagManagerTriggerParams) =>
  rest.appliedTags.length === 0 ? (
    renderTagManagerTrigger(rest)
  ) : (
    <Tooltip position={'top'} trigger={renderTagManagerTrigger(rest)}>
      {title}
    </Tooltip>
  )

export const getCaretCellID = <T extends {}>(row: Row<T>) => {
  const visibleCells = row.getVisibleCells()
  const firstCellID = visibleCells[0].column.id
  const cellHasSelection = firstCellID === 'cellCheckbox' || firstCellID === 'cellRadio'
  return cellHasSelection ? visibleCells[1].column.id : firstCellID
}

export const isCellClickable = <T extends {}>(row: Row<T>) => {
  return row.getVisibleCells().some((cell) => {
    const columnDef: ColumnDefWithAdditionalProps<T> = cell.column.columnDef
    return columnDef.enableClickableCell
  })
}

export const getCellStyles = <T,>(
  i: number,
  columnDef: ColumnDefWithAdditionalProps<T>,
  maxWidth?: number,
  maxSize?: number,
  minWidth?: number,
  minSize?: number,
  stickyColumns?: string[],
  stickyColsWidths?: number[]
) => {
  const size = maxSize === maxWidth ? '' : maxWidth
  const minSizeWidth = minSize === minWidth ? '' : minWidth
  const left = stickyColumns?.length && !!stickyColsWidths?.length && i < stickyColumns.length
  const columnDefPadding = columnDef.padding
  const padding =
    columnDefPadding instanceof Object
      ? {
          paddingTop: columnDefPadding?.top,
          paddingLeft: columnDefPadding?.left,
          paddingRight: columnDefPadding?.right,
          paddingBottom: columnDefPadding?.bottom,
        }
      : { padding: columnDefPadding }

  return {
    maxWidth: size,
    minWidth: minSizeWidth,
    width: size || minSizeWidth,
    textAlign: columnDef.textAlign,
    ...(columnDefPadding && padding),
    ...(left && { left: stickyColsWidths[i] }),
  }
}

export const checkUrlForTableV2 = () => window.location.href?.includes('?tableV2') || !!sessionStorage.getItem('tableV2')

export const expanderThreadState = <T,>(parentRows: Row<T>[], row: Row<T>, i: number) => {
  const rowID = row.id.split('.').slice(0, i + 2)
  let checkedIndex = row.id.split('.').length
  const parentRowSubRows = parentRows[+rowID[0]].subRows
  checkedIndex = checkedIndex > i + 2 ? 1 : 0

  const threads = [
    !!parentRowSubRows[+(+rowID[1] + checkedIndex)],
    !!parentRowSubRows[+rowID[1]]?.subRows[+(+rowID[2] + checkedIndex)],
    !!parentRowSubRows[+rowID[1]]?.subRows[+rowID[2]]?.subRows[+(+rowID[3] + checkedIndex)],
  ]

  return threads[i]
}

export const renderLockCell = (row: Row<Message>) => (
  <div title={`${t('Currently being edited by')} ${row.original.userFriendlyName ?? t('a team member')}`}>
    <Svg name={SvgNames.lockedMessage} type={SvgType.LARGER_ICON} />
  </div>
)
