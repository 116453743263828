import React from 'react'
import { Row } from 'react-table'

import { TableActions } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.constants'
import { getActionProps } from '@complex/ListingPage/Components/ListingPageTable/Utils/ListPageTable.utils'
import {
  ItemDtoWithBeeComposer,
  ListPageCommonState,
  ListPageTableActionCustomProps,
  TableProps,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { renderRelativeDate } from '@components/Table/components/tableColumns'
import { RowAction } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Tooltip from '@components/Tooltip/Tooltip'
import { ItemDto } from '@graphql/types/query-types'
import { AssetTypes } from '@utils/actonAssetTypes'
import { ItemType } from '@utils/categorization'
import { getStandardFormattedDate, relativeDate } from '@utils/date'
import { FilterDefinition, allLandingPageTemplates, createdByMeFilter, favoriteFilter, recentFilter } from '@utils/filter'
import { openLandingPageEditorWindow } from '@utils/landingPages'
import { renderPathToFolder } from '@utils/searchUtils'

export enum LandingPageTemplatesCustomTableActions {
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_FROM_LANDING_PAGE = 'CREATE_TEMPLATE_FROM_LANDING_PAGE',
  EDIT = 'EDIT',
  CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE',
  CREATE_LANDING_PAGE_FROM_TEMPLATE = 'CREATE_LANDING_PAGE_FROM_TEMPLATE',
  CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE = 'CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE',
}

const rowActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'EDIT', position: 0, hasTopSection: false, isInDropdown: false },
  { name: 'PREVIEW', position: 1, hasTopSection: false, isInDropdown: false },
  { name: 'ADD_TO_FAVORITES', position: 2, hasTopSection: false, isInDropdown: false },
  { name: 'CREATE_LANDING_PAGE', position: 3, hasTopSection: false, isInDropdown: false },
  { name: 'DUPLICATE', position: 4, hasTopSection: true, isInDropdown: true },
  { name: 'SHARE_TO_CATALOG', position: 5, hasTopSection: false, isInDropdown: true },
  { name: 'SHARE_TO_ACCOUNTS', position: 6, hasTopSection: false, isInDropdown: true },
  { name: 'MOVE_TO_FOLDER', position: 7, hasTopSection: true, isInDropdown: true },
  { name: 'REMOVE_FROM_FOLDER', position: 8, hasTopSection: false, isInDropdown: true },
  { name: 'DELETE', position: 9, hasTopSection: true, isInDropdown: true },
]

const headerActionCustomProps: ListPageTableActionCustomProps[] = [
  { name: 'ADD_TO_FAVORITES', position: 0 },
  { name: 'MANAGE_TAGS', position: 1 },
  { name: 'MOVE_TO_FOLDER', position: 2 },
  { name: 'REMOVE_FROM_FOLDER', position: 3 },
  { name: 'SHARE_TO_CATALOG', position: 4, isInDropdown: false },
  { name: 'DELETE', position: 5, isInDropdown: true },
]

const onCustomTableAction = (
  customTableAction: LandingPageTemplatesCustomTableActions,
  update: Update,
  listPageValues: ListPageCommonState,
  selectedItem?: ItemDto
) => {
  const showCustomModal = (action: LandingPageTemplatesCustomTableActions, refetchCountsOnAction = true) =>
    update({
      customTableAction: action,
      showCustomModal: true,
      refetchCountsOnAction,
    })

  const { selectedRows } = listPageValues
  const selectedRow = selectedItem ?? selectedRows[0]
  const row = selectedRow as ItemDtoWithBeeComposer
  const selectedRowId = row?.externalId ?? '-1'
  const isBeeComposer = row.beeComposer

  const customAction: { [key in LandingPageTemplatesCustomTableActions]: () => void } = {
    [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE]: () => () =>
      showCustomModal(LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE, false),
    [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE]: () => () =>
      showCustomModal(LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE, false),
    [LandingPageTemplatesCustomTableActions.EDIT]: () => openLandingPageEditorWindow(selectedRowId, isBeeComposer, '', true),
    [LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE]: () =>
      showCustomModal(LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE, false),
    [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE]: () =>
      showCustomModal(LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE, false),
    [LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () =>
      showCustomModal(LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE, false),
  }
  customAction[customTableAction]()
}

const getCustomRowActions = (tableActions: TableActions, t: Function, canCreate: boolean, newLPComposerTemplates: boolean): RowAction[] => [
  {
    label: t('Edit'),
    icon: SvgNames.pencil,
    hidden: !canCreate,
    onClick: (row: Row<ItemDto>) => tableActions.customTableRowAction(LandingPageTemplatesCustomTableActions.EDIT, row),
    ...getActionProps(LandingPageTemplatesCustomTableActions.EDIT, rowActionCustomProps),
  },
  {
    label: t('Create landing page from template'),
    icon: SvgNames.appendedList,
    hidden: !canCreate,
    tooltipAlign: 'end',
    tooltipAlignOffset: -40,
    onClick: (row: Row<ItemDtoWithBeeComposer>) =>
      tableActions.customTableRowAction(
        newLPComposerTemplates && row.original.beeComposer
          ? LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE
          : LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE,
        row
      ),
    ...getActionProps(LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE, rowActionCustomProps),
  },
]

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  goToCatalog: () => void,
  filter?: FilterDefinition
) => {
  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      size: EmptyListingSize.LARGE,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to templates that you want to easily find later',
      buttonText: 'View all templates',
      buttonOnClick: () => setFilter(allLandingPageTemplates, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      size: EmptyListingSize.LARGE,
      headline: 'You haven’t created any templates yet',
      text: 'You’ll find templates here once you’ve created them',
      buttonText: 'Create template',
      buttonOnClick: () => null,
    }
  } else if (filter === recentFilter) {
    return {
      imgSrc: StaticImageNames.emptyRecent,
      size: EmptyListingSize.LARGE,
      headline: 'You haven’t used these recently',
      text: 'You’ll find items that you used recently here',
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      size: EmptyListingSize.LARGE,
      headline: 'Let’s start building!',
      text: 'ListPage.Common.Templates.EmptyState.AllFilter.Text',
      linkText: 'Learn more about landing pages',
      link: 'https://connect.act-on.com/hc/en-us/articles/360023758214-Landing-Page-Composer-Overview',
      hideIcon: false,
      buttonText: 'Check out our template catalog',
      buttonOnClick: () => goToCatalog(),
    }
  }
}

const renderItemNameVal = (name: string, search: string, rootClass: string, t: Function, beeComposer?: boolean) => {
  return (
    <div className={`${rootClass}__template-name`}>
      <Tooltip
        ellipOnTrigger
        trigger={
          <div className={`${rootClass}__template-name-typography`}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(name, search) : name}</div>
            {beeComposer && <Pill text={t('BETA')} type={PillType.SECONDARY} noMargin size={PillSize.SMALL} />}
          </div>
        }
      >
        {name}
      </Tooltip>
    </div>
  )
}

const tableV2Columns = (t: Function): ColumnDefWithAdditionalProps<ItemDto, any>[] => [
  {
    header: t('Template Title'),
    accessorKey: 'name',
    textAlign: 'left',
    cell: (row) => {
      const isBeeComposer = (row.cell.row.original as unknown as ItemDto & { beeComposer: boolean }).beeComposer
      return renderItemNameVal(row.cell.getValue(), '', 'landing-page-templates-listing-page-container', t, isBeeComposer)
    },
    padding: {
      right: 0,
    },
  },
  {
    header: t('Tags'),
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 126,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: t('created Date'),
    accessorKey: 'createdAt',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    maxSize: 150,
    cell: (cell) => renderRelativeDate(relativeDate(parseInt(cell.getValue()), true, false), getStandardFormattedDate(parseInt(cell.getValue()))),
  },
  {
    header: t('Last Updated'),
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    fieldType: 'TIMESTAMP',
    maxSize: 150,
    cell: (cell) => renderRelativeDate(relativeDate(parseInt(cell.getValue()), true, false), getStandardFormattedDate(parseInt(cell.getValue()))),
  },
]

const renderSearchColumnsV2 = (
  searchInAllItems: boolean | undefined,
  currentFolder: FolderData,
  search: string,
  folders: FolderData[],
  t: Function
): ColumnDefWithAdditionalProps<ItemDto & { lastUpdated: number }>[] => [
  {
    header: 'Template Title',
    accessorKey: 'name',
    textAlign: 'left',
    cell: (row) => {
      const isBeeComposer = (row.cell.row.original as unknown as ItemDto & { beeComposer: boolean }).beeComposer
      return renderItemNameVal(row.getValue<string>(), search, 'landing-page-templates-listing-page-container', t, isBeeComposer)
    },
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    textAlign: 'left',
    maxSize: 150,
    disableSorting: true,
    enableCustomCellValue: true,
  },
  {
    header: 'Location',
    accessorKey: 'folderId',
    textAlign: 'left',
    maxSize: 150,
    cell: (row) =>
      renderPathToFolder(
        ItemType.LANDING_PAGE_TEMPLATE,
        row.getValue<number>(),
        folders,
        searchInAllItems,
        'list-page-table-container',
        t,
        currentFolder
      ),
  },
  {
    header: 'Last Updated',
    accessorKey: 'lastUpdated',
    textAlign: 'left',
    maxSize: 150,
    cell: ({
      row: {
        original: { lastUpdated },
      },
    }) => renderRelativeDate(relativeDate(lastUpdated, true, false), getStandardFormattedDate(lastUpdated)),
  },
]

export const tableProps = (t: Function, canCreate: boolean, newLPComposerTemplates: boolean): TableProps => ({
  listPage: 'LandingPageTemplates',
  headerActionCustomProps,
  rowActionCustomProps,
  getCustomRowActions: (tableActions) => getCustomRowActions(tableActions, t, canCreate, newLPComposerTemplates),
  hasAutoSelectedRows: false,
  hasExpander: false,
  columns: tableV2Columns(t),
  renderSearchColumns: (searchInAllItems: boolean | undefined, currentFolder: FolderData, search: string, folders: FolderData[]) =>
    renderSearchColumnsV2(searchInAllItems, currentFolder, search, folders, t),
  onCustomTableAction: (customAction, update, listPageValues, selectedItem) =>
    onCustomTableAction(customAction, update, listPageValues, selectedItem),
  actonAssetType: AssetTypes.LANDING_PAGE_TEMPLATE,
  shareModalText: '',
})
