import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import { renderCustomFilters } from '@components/AssetPickers/LandingPagesPickerModal/utils/LandingPagesPickerModal.helpers.helpers'
import LandingPageTemplatesPickerModal, {
  createLandingPageFromTemplateModalProps,
} from '@components/AssetPickers/LandingPageTemplatesPickerModal/LandingPageTemplatesPickerModal'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { CreateLandingPageMethod } from '@utils/composer/landingPage/types'
import { rootContext, useTranslation } from '@utils/const/globals'
import { allLandingPages } from '@utils/filter'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import {
  LANDING_PAGE_BUILDER_LINK,
  LandingPageBuilderMessage,
  getLandingPagePreviewUrl,
  isLandingPageBuilderMessage,
  landingPageSubTypes,
  openCreateLandingPageFromTemplateWindow,
  openLandingPageEditorWindow,
} from '@utils/landingPages'
import { UpdateState } from '@utils/types'

import CreateLandingPageModal from './components/CreateLandingPageModal/CreateLandingPageModal'
import LandingPageUploadModal from './components/LandingPagesUploadModal/LandingPagesUploadModal'
import LandingPagesUrlModal from './components/LandingPagesUrlModal/LandingPagesUrlModal'
import { getLandingPagesListingPageRequests } from './GraphQL/LandingPagesListingPageRequests.graphQL'
import { LandingPages } from './LandingPagesListingPageContainer.contants'
import { LandingPagesCustomTableActions, getCustomEmptyListingProps, tableProps } from './utils/LandingPagesListingPageContainer.tables'
import CreateLandingPageTemplateModal from '../LandingPageTemplates/components/CreateLandingPageTemplateModal/CreateLandingPageTemplateModal'

import './LandingPagesListingPageContainer.css'

interface LandingPagesListingPageContainerProps {
  className?: string
  dataTest?: string
}

interface LandingPagesListingPageContainerState {
  windowMessages: LandingPageBuilderMessage[]
}

type ActionsWithModals = Exclude<
  LandingPagesCustomTableActions,
  | LandingPagesCustomTableActions.EDIT
  | LandingPagesCustomTableActions.SHOW_DASHBOARD
  | LandingPagesCustomTableActions.SHOW_DETAILS
  | LandingPagesCustomTableActions.PUBLIC_URLS
>

type CustomModalType = {
  [key in ActionsWithModals]: () => ReactNode
}

const rootClass = 'landing-pages-listing-page-container'

const LandingPagesListingPageContainer: FC<LandingPagesListingPageContainerProps> = (props: LandingPagesListingPageContainerProps) => {
  const { dataTest = rootClass } = props
  const [containerValues, setContainerValues] = useState<LandingPagesListingPageContainerState>({
    windowMessages: [],
  })
  const updateContainer: UpdateState<LandingPagesListingPageContainerState> = (fields) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...fields }))
  }
  const { windowMessages } = containerValues
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const accountSettings = useAccountSettings()
  const {
    userAllowedToCreateContent,
    userAllowedToDeleteContent,
    isAgencyAccount,
    newLPComposerCreateBlank,
    isClientAccount,
    newLPComposerTemplates,
  } = accountSettings
  const {
    deleteLandingPagesRequest,
    createTemplateFromLandingPageRequest,
    duplicateLandingPageRequest,
    uploadLandingPageCodeRequest,
    uploadLandingPageUrlRequest,
    getItemRequest,
    getLandingPageTemplatesRequest,
  } = getLandingPagesListingPageRequests(actonClient, categorizationClient)

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [incorrectUrl, setIncorrectUrl] = useState<boolean>(false)
  const [isTemplatesExist, setIsTemplatesExist] = useState<boolean>(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('')

  useEffect(() => {
    getLandingPageTemplatesRequest().then((res) => {
      if (!!res.data?.getAllItems?.length) {
        setIsTemplatesExist(true)
      }
    })
  }, [])

  const deleteLandingPages = async (items: ItemDto[]) =>
    deleteLandingPagesRequest({ landingPageIds: items.map((item) => item.externalId ?? '').filter((id) => !!id) })

  const duplicateLandingPage = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId, includeUnPublishedChanges } = params
    const { update, setStatusToast, setError } = listPageAPI

    const { data, errors } = await duplicateLandingPageRequest({
      landingPageId: listingPageItem.externalId ?? '-1',
      newName,
      tags,
      folderId,
      includeUnPublishedChanges,
    })

    if (data) {
      setStatusToast(t('ListPage.LandingPages.Duplicate.SuccessToast'), Status.SUCCESS)
      update({ loading: true, fetchItems: true })
    } else {
      setError(t('ListPage.LandingPages.Duplicate.FailToast'), errors)
    }
  }

  const getLandingPagePreview = async (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    const lpId = selectedRows[0]?.externalId ?? ''
    update({ previewUrl: getLandingPagePreviewUrl(lpId) })
  }

  const onWindowMessage = useCallback(
    async (event: MessageEvent<LandingPageBuilderMessage>) => {
      if (isLandingPageBuilderMessage(event.data)) {
        const eventExists = windowMessages.filter((message) => message.lpId === event.data.lpId).length
        !eventExists && updateContainer({ windowMessages: [...windowMessages, { ...event.data }] })
      }
    },
    [windowMessages]
  )

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast, items: ItemDto[]) => {
      if (!windowMessages.length) {
        return
      }

      const landingPageData: ItemDto[] & { item: string }[] = []
      for (const message of windowMessages) {
        const data = await getItemRequest({ externalId: message.lpId, type: ItemType.LANDING_PAGE })
        data.data?.getItem && landingPageData.push(data.data?.getItem)
      }

      if (landingPageData.length && windowMessages.length) {
        const landingPageTitle = JSON.parse(landingPageData[0].item).name
        const isCreated = !items.find((itm) => itm.externalId === windowMessages[0].lpId)
        const message = (
          <Typography
            text={isCreated ? 'ListPage.LandingPages.LandingPageCreated' : 'ListPage.LandingPages.LandingPageEdited'}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            values={{ landingPageTitle }}
            inline
          />
        )

        setStatusToast(message, Status.SUCCESS)
        update({ fetchFolders: true, fetchFilterCounts: true, fetchItems: true })
      }

      updateContainer({ windowMessages: [] })
    },
    [windowMessages]
  )

  const renderCustomModal = (customTableAction: ActionsWithModals, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI) => {
    const { update, setError } = listPageAPI
    const { selectedRows, secondaryFolders } = listPageValues

    const selectedRow = selectedRows[0]

    const row = selectedRow as LandingPages
    const selectedRowId = row?.externalId ?? '-1'
    const closeModal = () => update({ customTableAction: undefined, showCustomModal: false, isProcessingAction: true })

    const customModal: CustomModalType = {
      [LandingPagesCustomTableActions.CREATE_LANDING_PAGE]: () => {
        const onSelectLandingPageCreateMethod = (method: CreateLandingPageMethod) => {
          if (method === CreateLandingPageMethod.NEW) {
            window.open(`${LANDING_PAGE_BUILDER_LINK}/blank`)
            closeModal()
          } else if (method === CreateLandingPageMethod.TEMPLATE) {
            update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE })
          } else if (method === CreateLandingPageMethod.CODE) {
            update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_CODE })
          } else if (method === CreateLandingPageMethod.URL) {
            update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_URL })
          } else {
            closeModal()
          }
        }
        return (
          <CreateLandingPageModal
            isOpen
            isLandingListingPage
            showAdditionalOptions
            disableTemplateOption={!isTemplatesExist}
            onCreate={(method) => onSelectLandingPageCreateMethod(method)}
            onCancel={closeModal}
            failToastError={{
              title: t('CreateLandingPageModal.Error.Title'),
              message: t('CreateLandingPageModal.Error.Message'),
            }}
            update={update}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_DUPLICATE]: () => {
        return (
          <CreateLandingPageModal
            isOpen
            isLandingListingPage
            showAdditionalOptions
            startId={selectedTemplateId}
            duplicateModalProps={{
              isLandingPageComposer: true,
              onDuplicateCancel: closeModal,
            }}
            onCancel={closeModal}
            failToastError={{
              title: t('CreateLandingPageModal.Error.Title'),
              message: t('CreateLandingPageModal.Error.Message'),
            }}
            update={update}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE]: () => {
        return (
          <CreateLandingPageTemplateModal
            isOpen
            showAdditionalOptions
            startId={selectedRowId}
            isLandingListingPage
            duplicateModalProps={{
              onDuplicateCancel: closeModal,
              headerTitleKey: t('Create template'),
            }}
            onCancel={closeModal}
            includeUnpublishedChangesLabel={t('LandingPage.IncludeUnpublish.Changes.toggle.template')}
            update={update}
            failToastError={{
              title: t('CreateLandingPageTemplateModal.Error.Title.create'),
              message: t('CreateLandingPageTemplateModal.Error.Message.create'),
            }}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () => {
        const onSelectLandingPageTemplate = async (items: (ItemDto & { beeComposer: boolean })[]) => {
          const isBeeComposer = items[0]?.beeComposer

          if (isBeeComposer && newLPComposerTemplates) {
            update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE_DUPLICATE })
          } else {
            items[0]?.externalId && openCreateLandingPageFromTemplateWindow(items[0]?.externalId)
            closeModal()
          }
        }

        return (
          <LandingPageTemplatesPickerModal
            {...createLandingPageFromTemplateModalProps}
            onSubmit={(items) => {
              onSelectLandingPageTemplate(items as (ItemDto & { beeComposer: boolean })[])
              setSelectedTemplateId(items[0]?.externalId ?? '')
            }}
            onClose={() => update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE })}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_CODE]: () => {
        const onCreateFromFile = async (fileBase64?: string, fileName?: string) => {
          setLoading(true)
          const { data, errors } = await uploadLandingPageCodeRequest({ fileBase64: fileBase64?.split(',')[1], fileName })

          if (data && data.uploadLandingPageCode.id) {
            const { id } = data.uploadLandingPageCode
            openLandingPageEditorWindow(id)
          }
          if (errors) {
            setError(t('There was a problem creating your landing page'), errors)
          }
          setLoading(false)
          closeModal()
        }

        return (
          <LandingPageUploadModal
            isOpen
            loading={loading}
            onSubmit={(fileBase64, fileName) => onCreateFromFile(fileBase64, fileName)}
            onClose={() => update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE })}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_LANDING_PAGE_FROM_URL]: () => {
        const onCreateFromUrl = async (url: string) => {
          setLoading(true)
          const { data, errors } = await uploadLandingPageUrlRequest({ url })

          if (data) {
            if (data.uploadLandingPageUrl.id) {
              const { id } = data.uploadLandingPageUrl
              openLandingPageEditorWindow(id)
              closeModal()
            } else {
              setIncorrectUrl(true)
            }
          }
          if (errors) {
            setError(t('There was a problem creating your landing page'), errors)
            closeModal()
          }
          setLoading(false)
        }

        return (
          <LandingPagesUrlModal
            isOpen
            loading={loading}
            incorrectUrl={incorrectUrl}
            setIncorrectUrl={setIncorrectUrl}
            onSubmit={(url) => onCreateFromUrl(url)}
            onClose={() => update({ customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE })}
          />
        )
      },
      [LandingPagesCustomTableActions.CREATE_TEMPLATE]: () => {
        const onCreateTemplate = async (folderId: number) => {
          const { data, errors } = await createTemplateFromLandingPageRequest({ folderId, id: selectedRowId, title: row?.name })
          if (data && data.copyLandingPageOrTemplate) {
            openLandingPageEditorWindow(data.copyLandingPageOrTemplate)
            window.location.assign(`${rootContext}/content/landingPageTemplates`)
          }
          if (errors) {
            setError(t('There was a problem creating your template'), errors)
          }
          closeModal()
        }

        return (
          <MoveToFolderModal
            folders={secondaryFolders as FolderData[]}
            addToFolder={true}
            headerText={t('Create template')}
            description={t('Save to folder')}
            primaryButtonText={t('Create')}
            folderPrimaryButtonText={t('Select')}
            onMoveClick={onCreateTemplate}
            onClose={closeModal}
            isOpen
          />
        )
      },
    }

    if (!(customTableAction in customModal)) {
      return
    }
    return customModal[customTableAction]()
  }

  const renderCreateLandingPageButton = (update: Update) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={!userAllowedToCreateContent}
      onClick={() => update({ showCustomModal: true, customTableAction: LandingPagesCustomTableActions.CREATE_LANDING_PAGE })}
      className={classNames(`${rootClass}__create-landing-page-button`, {
        [`${rootClass}__create-landing-page-button-disabled`]: !userAllowedToCreateContent,
      })}
      dataTest={`${rootClass}-create-landing-page-button`}
    >
      <Svg type={SvgType.ICON} name={SvgNames.plus} />
      {t('Create landing page')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Landing Pages',
    renderPageHeaderContent: (update) => (
      <>
        {userAllowedToCreateContent ? (
          renderCreateLandingPageButton(update)
        ) : (
          <Tooltip trigger={renderCreateLandingPageButton(update)}>{t('Ask your administrator for permission to do this')}</Tooltip>
        )}
      </>
    ),
  }

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Landing Page Manager',
    hasSalesUsersAction: true,
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allLandingPages,
    renderCustomFilters: (params) => renderCustomFilters(params),
    customFilterSelected: false,
  }

  const goToCatalog = () => window.open(CatalogLink.LANDING_PAGE, '_blank')

  return (
    <ListingPageContainer
      dataTest={dataTest}
      className={classNames(rootClass)}
      itemType={ItemType.LANDING_PAGE}
      listingPageProps={{
        disableShareToAccounts: !newLPComposerCreateBlank && (isAgencyAccount || isClientAccount),
        disableDuplicate: !newLPComposerCreateBlank,
        canPreview: true,
        canCreate: userAllowedToCreateContent,
        canEdit: userAllowedToCreateContent,
        canDeleteItems: userAllowedToDeleteContent,
        canShareToCatalog: false,
        hasTabs: true,
        subTypes: landingPageSubTypes,
        canDuplicate: true,
        isDeleteCallWithBulkResponse: true,
        sidebarProps,
        pageHeaderProps,
        hasSecondaryFolders: true,
        secondaryItemType: ItemType.LANDING_PAGE_TEMPLATE,
        tableProps: tableProps(t, userAllowedToCreateContent, newLPComposerCreateBlank, newLPComposerTemplates),
        getCustomEmptyListingProps: (setFilter, update, filter) => getCustomEmptyListingProps(setFilter, update, goToCatalog, filter),
        renderCustomModal,
        customDeleteItemsCall: deleteLandingPages,
        customDuplicateItem: duplicateLandingPage,
        customPreviewItemCall: getLandingPagePreview,
        onWindowMessage,
        onWindowActive,
        sortBy: [{ id: 'lastUpdated', desc: true }],
      }}
    />
  )
}

export default LandingPagesListingPageContainer
