import React from 'react'

import { TFunction } from 'i18next'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import { LinkTextButton } from '@components/LinkTextButton/LinkTextButton'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import TriggerButton, { TriggerButtonOption } from '@components/TriggerButton/TriggerButton'
import { getUUID } from '@const/globals'
import { GroupDefinitionDto, RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import {
  ConditionType,
  FactorType,
  getBooleanConditionOptions,
  getDateConditionOptions,
  getNumericConditionOptions,
  getTextConditionOptions,
  LogicalOperator,
} from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { filterNotEmptyArray } from '@utils/array'

export enum RowAction {
  ADD = 'add',
  DUPLICATE = 'duplicate',
  GROUP = 'group',
  DELETE = 'delete',
}

export enum LinkedRowAction {
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

export enum GroupAction {
  SAVE_TEMPLATE = 'saveTemplate',
  UNGROUP = 'ungroup',
  DUPLICATE = 'duplicate',
  COLLAPSE_EXPAND = 'collapseExpand',
  DELETE = 'delete',
}

export enum GroupAddButtonAction {
  ADD_ROW = 'addRow',
  ADD_GROUP = 'addGroup',
  ADD_SAVED_GROUP = 'addSavedGroup',
}

export enum Criteria {
  PROFILE = 'profile',
  BEHAVIOR = 'behavior',
  SCORE = 'score',
  SMS = 'sms',
  SUBSCRIPTION = 'subscription', // TODO: not supported on the backend yet
  SEGMENT = 'segment',
  SYSTEM = 'system',
  CRM = 'crm',
  CUSTOM_DATA = 'customData', // TODO: not supported on the backend yet
}

export const factorTypeByCriteria: { [key in Criteria]: FactorType } = {
  [Criteria.PROFILE]: FactorType.Profile,
  [Criteria.BEHAVIOR]: FactorType.Behavior,
  [Criteria.SCORE]: FactorType.Score,
  [Criteria.SMS]: FactorType.Sms,
  [Criteria.SUBSCRIPTION]: FactorType.Profile, // TODO: change to FactorType.Subscription once it is supported on the backend
  [Criteria.SEGMENT]: FactorType.Search, // TODO: It will depend on the selected segment Search | DirectSelect
  [Criteria.SYSTEM]: FactorType.System,
  [Criteria.CRM]: FactorType.Crm,
  [Criteria.CUSTOM_DATA]: FactorType.Profile, // TODO: change to FactorType.CustomData once it is supported on the backend
}

export const criteriaByFactorType: { [key in FactorType]: Criteria } = {
  [FactorType.Profile]: Criteria.PROFILE,
  [FactorType.Behavior]: Criteria.BEHAVIOR,
  [FactorType.Score]: Criteria.SCORE,
  [FactorType.Sms]: Criteria.SMS,
  //[FactorType.Subscription]: Criteria.SUBSCRIPTION,
  [FactorType.Search]: Criteria.SEGMENT,
  [FactorType.DirectSelect]: Criteria.SEGMENT,
  [FactorType.System]: Criteria.SYSTEM,
  [FactorType.Crm]: Criteria.CRM,
  //[FactorType.CustomData]: Criteria.CUSTOM_DATA,
}

export const iconByCriteria: { [key in Criteria]: SvgNames } = {
  [Criteria.PROFILE]: SvgNames.userUnselected,
  [Criteria.BEHAVIOR]: SvgNames.lastModified,
  [Criteria.SCORE]: SvgNames.score,
  [Criteria.SMS]: SvgNames.sms,
  [Criteria.SUBSCRIPTION]: SvgNames.signUpNoFill,
  [Criteria.SEGMENT]: SvgNames.listsAndSegments,
  [Criteria.SYSTEM]: SvgNames.system,
  [Criteria.CRM]: SvgNames.crmCloudLine,
  [Criteria.CUSTOM_DATA]: SvgNames.customData,
}

export const CRITERIA_WITH_LINKED_ROWS = [Criteria.CRM, Criteria.CUSTOM_DATA]

const renderIcon = (criteria: Criteria) => <Svg name={iconByCriteria[criteria]} type={SvgType.LARGER_ICON} />

export const getCriteriaOptions = (t: TFunction) => {
  return [
    {
      value: Criteria.PROFILE,
      label: t('Profile'),
      renderItemIcon: () => renderIcon(Criteria.PROFILE),
    },
    {
      value: Criteria.BEHAVIOR,
      label: t('Behavior'),
      renderItemIcon: () => renderIcon(Criteria.BEHAVIOR),
    },
    {
      value: Criteria.SCORE,
      label: t('Score'),
      renderItemIcon: () => renderIcon(Criteria.SCORE),
    },
    {
      value: Criteria.SMS,
      label: t('SMS'),
      renderItemIcon: () => renderIcon(Criteria.SMS),
    },
    {
      value: Criteria.SUBSCRIPTION,
      label: t('Subscription'),
      renderItemIcon: () => renderIcon(Criteria.SUBSCRIPTION),
    },
    {
      value: Criteria.SEGMENT,
      label: t('Segment'),
      renderItemIcon: () => renderIcon(Criteria.SEGMENT),
    },
    {
      value: Criteria.SYSTEM,
      label: t('System'),
      renderItemIcon: () => renderIcon(Criteria.SYSTEM),
    },
    {
      value: Criteria.CRM,
      label: t('CRM'),
      renderItemIcon: () => renderIcon(Criteria.CRM),
    },
    {
      value: Criteria.CUSTOM_DATA,
      label: t('Custom Data'),
      renderItemIcon: () => renderIcon(Criteria.CUSTOM_DATA),
    },
  ]
}

export const renderSingleValue = (option: SelectV2SingleOption) => option.label

export const getLinkedRowActionOptions = (t: TFunction) => {
  return [
    {
      text: t('SegmentComposer.Build.LinkedRow.Actions.Duplicate'),
      icon: SvgNames.copyStep,
      onClick: () => false,
    },
    {
      text: t('SegmentComposer.Build.LinkedRow.Actions.Delete'),
      icon: SvgNames.delete,
      onClick: () => false,
    },
  ]
}

export const getGroupActionOptions = (t: TFunction, isOpen: boolean, onActionClick: (action: GroupAction) => void) => {
  return [
    {
      text: t('SegmentComposer.Build.Row.Group.Actions.SaveTemplate'),
      icon: SvgNames.floppyDisk,
      onClick: () => false,
    },
    {
      text: t('SegmentComposer.Build.Row.Group.Actions.Ungroup'),
      icon: SvgNames.ungroup,
      onClick: () => false,
    },
    {
      text: t('SegmentComposer.Build.Row.Group.Actions.Duplicate'),
      icon: SvgNames.copyStep,
      onClick: () => false,
    },
    {
      text: t(`SegmentComposer.Build.Row.Group.Actions.${isOpen ? 'Collapse' : 'Expand'}`),
      icon: isOpen ? SvgNames.collapseAll : SvgNames.expandAll,
      onClick: () => onActionClick(GroupAction.COLLAPSE_EXPAND),
    },
    {
      text: t('SegmentComposer.Build.Row.Group.Actions.Delete'),
      icon: SvgNames.delete,
      onClick: () => onActionClick(GroupAction.DELETE),
    },
  ]
}

export const combineRowsOptions = [{ label: LogicalOperator.And }, { label: LogicalOperator.Or }]

export const getNewRowId = () => `row-${getUUID()}`

export const getAddButtonOptions = (t: TFunction, onActionClick: (action: GroupAddButtonAction) => void) => [
  {
    text: t('SegmentComposer.Build.AddButton.AddRow'),
    icon: SvgNames.addRow,
    onClick: () => onActionClick(GroupAddButtonAction.ADD_ROW),
  },
  {
    text: t('SegmentComposer.Build.AddButton.AddGroup'),
    icon: SvgNames.addGroup,
    onClick: () => onActionClick(GroupAddButtonAction.ADD_GROUP),
  },
  {
    text: t('SegmentComposer.Build.AddButton.AddSavedGroup'),
    icon: SvgNames.addSavedGroup,
    onClick: () => onActionClick(GroupAddButtonAction.ADD_SAVED_GROUP),
  },
]

export const dropdownFooter = <LinkTextButton link={'https://connect.act-on.com/hc/en-us/articles/28101895959447'} text={'Learn more'} hideIconLeft />

export const renderAddButton = (t: TFunction, options: TriggerButtonOption[]) => {
  const rootClass = 'query-segment-builder'
  return (
    <div className={`${rootClass}__add-button-container`}>
      <TriggerButton
        className={`${rootClass}__add-button`}
        dataTest={`${rootClass}-add-button`}
        label={t('SegmentComposer.Build.AddButton.Label')}
        options={options}
        buttonType={ButtonType.FLOAT_TEAL}
        svgName={SvgNames.addRounded}
        svgIcon={SvgType.LARGER_ICON}
        showCaretIcon={false}
        iconPosition={ButtonIconPosition.FLOAT}
        dropDownProps={{
          footer: dropdownFooter,
        }}
        useLabel
      />
    </div>
  )
}

export enum FieldType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
}

export const getConditionType = (value: string) => {
  switch (value) {
    case 'TEXT':
      return ConditionType.TEXT_LIST
    case 'NUMERIC':
      return ConditionType.NUMERIC_VALUE
    case 'DATE':
      return ConditionType.DATE_VALUE
    default:
      return ConditionType.TEXT_LIST
  }
}

export const getDefaultComparisonByField: {
  [key in FieldType]: (t: TFunction) => SelectV2SingleOption
} = {
  [FieldType.TEXT]: (t) => getTextConditionOptions(t)[0].options[0],
  [FieldType.NUMERIC]: (t) => getNumericConditionOptions(t)[0],
  [FieldType.DATE]: (t) => getDateConditionOptions(t)[0],
  [FieldType.BOOLEAN]: (t) => getBooleanConditionOptions(t)[0],
}

/**
 * Retrieves an element from the builder definition tree by its unique identifier.
 *
 * @param id - The unique identifier of the element to retrieve.
 * @param rootGroup - The root `GroupDefinitionDto|RowDefinitionDto` to start the search from.
 * @returns The matching `GroupDefinitionDto`, `RowDefinitionDto`, or `null` if no element is found.
 */
export const getBuilderDefinitionElementById = (
  id: string,
  rootGroup: GroupDefinitionDto | RowDefinitionDto
): GroupDefinitionDto | RowDefinitionDto | null => {
  const items: (RowDefinitionDto | GroupDefinitionDto)[] = []
  if (!isSegmentComposerRow(rootGroup)) {
    if (rootGroup.rows) {
      items.push(...rootGroup.rows.filter(filterNotEmptyArray))
    }
    if (rootGroup.subGroups) {
      items.push(...rootGroup.subGroups.filter(filterNotEmptyArray))
    }
  }

  for (const currentElement of items) {
    if (isSegmentComposerRow(currentElement)) {
      if (currentElement.rowId === id) {
        return currentElement
      }
    } else {
      if (currentElement.groupId === id) {
        return currentElement
      }
      const matchingElement = getBuilderDefinitionElementById(id, currentElement)
      if (matchingElement) {
        return matchingElement
      }
    }
  }
  return null
}

/**
 * Type guard to determine if a given element is an `ExpressionRow`.
 *
 * @param element - The element to check, which could be either an `RowDefinitionDto` or `GroupDefinitionDto`.
 * @returns `true` if the element is an `RowDefinitionDto`, `false` otherwise.
 */
export const isSegmentComposerRow = (element: RowDefinitionDto | GroupDefinitionDto): element is RowDefinitionDto => 'factor' in element
