import React, { FC, useEffect, useState } from 'react'

import equal from 'fast-deep-equal/es6'

import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { YesNo } from '@components/ConfirmationModal'
import ContactsDetails, { ContactDetailsProps } from '@components/ContactsDetails/ContactsDetails'
import {
  ContactsDetailContext,
  ContactsDetailsContainerInitialState,
  ContactsDetailsContainerState,
} from '@components/ContactsDetails/ContactsDetailsContext'
import useContactsDetailsRequests from '@components/ContactsDetails/utils/ContactsDetails.graphQL'
import {
  deleteContactsUtils,
  getChangeBounceStatusConfirmationModal,
  getColumnsUtils,
  getSegmentContacts,
  mapContactIdToUuid,
  onRemoveFromBounce,
  removeContactsFromSegmentUtils,
  saveColumnsUtils,
} from '@components/ContactsDetails/utils/ContactsDetails.utils'
import { FilesData } from '@components/SegmentDetails/SegmentDetailsContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { UnifiedContactListColumn } from '@graphql/types/query-types'
import { OnChangeFn, PaginationState, Row } from '@tanstack/react-table'
import { BouncesSegments, bouncesVerbs, ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { FileUplaodModalType } from '@utils/formUtils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { useUnifiedContactList } from '@utils/hooks/useUnifiedContactList'
import { isFunction } from '@utils/utils'

type ColumnsToSaveType = { columns: Column[]; allSubsegments: boolean; segmentId: string; onSaveCallback: VoidFunction }

export interface ContactDetailsContainerProps extends Omit<ContactDetailsProps, 'onContactRowClicked'> {
  columnsToSave?: ColumnsToSaveType
  onContactsLoaded: (data: PageInput) => void
  onContactRowClicked?: (row: Row<Contact>, uuid: string, columns: Column[]) => void
  previousCount?: number
  toggleDownloadModal?: (type: FileUplaodModalType, contact: string[], headerText?: string) => void
  handleDownloadOrPreviewFile?: (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: FilesData[]) => void
}

const ContactsDetailsContainer: FC<ContactDetailsContainerProps> = (props: ContactDetailsContainerProps) => {
  const {
    columnsToSave,
    segment,
    isBounceSegment,
    segmentContacts,
    selectedContacts = [],
    onContactRowClicked,
    onContactsLoaded,
    onContactsSelected,
    itemType,
    previousCount,
    toggleDownloadModal,
    handleDownloadOrPreviewFile,
    ...rest
  } = props
  const { defaultColumns, onPageOptionsChange, resetSelectionWhenPageChanges, showSelectedContactsOnly } = rest

  const [containerValues, setContainerValues] = useState<ContactsDetailsContainerState>({
    ...ContactsDetailsContainerInitialState,
    ...(defaultColumns ? { columns: defaultColumns } : {}),
    loading: !showSelectedContactsOnly,
  })
  const {
    pageSize,
    search,
    pageIndex,
    columns,
    contactsToDelete,
    statusToast: { showStatus, successStatus, statusMessage, title: statusToastTitle },
    mappedIdsToUuids,
  } = containerValues

  const { t } = useTranslation()

  const update = (fieldsToUpdate: Partial<ContactsDetailsContainerState>) => {
    setContainerValues((containerValues) => ({
      ...containerValues,
      ...fieldsToUpdate,
    }))
  }

  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const { addFieldColumnsToList, refreshRecordsCount } = useContactsDetailsRequests()
  const { unifiedListFieldMappings = [] } = useUnifiedContactList()

  const getColumns = async (headers: string[], unifiedListFieldMappings: UnifiedListFieldMapping[]) => {
    const columns = await getColumnsUtils(segment, headers, segmentClient, unifiedListFieldMappings)
    return columns ?? headers.map((name, id) => ({ name, id } as Column))
  }

  const loadContacts = async (columnsOverride?: Column[]) => {
    const isInactiveBounceSegment = segment.externalId === bouncesVerbs[BouncesSegments.INACTIVE]
    const pageNumber = isInactiveBounceSegment ? pageIndex + 1 : pageIndex

    setContainerValues((state) => ({ ...state, loading: true }))
    try {
      const pageInput: PageInput = {
        id: isBounceSegment && !isInactiveBounceSegment ? segment?.classicExternalId : segment?.externalId || undefined,
        pageNumber,
        pageSize,
        search,
      }
      const data = await getSegmentContacts(pageInput, segmentClient, isInactiveBounceSegment, itemType === ItemType.FORM_SUBMISSION)
      const { headers = [], hasNextPage = false, totalCount } = data
      onContactsLoaded(data)
      if (resetSelectionWhenPageChanges && onContactsSelected && selectedContacts.length > 0) {
        onContactsSelected([])
      }
      const columns = columnsOverride ?? defaultColumns ?? (await getColumns(headers as string[], unifiedListFieldMappings))
      setContainerValues((state) => ({ ...state, hasNextPage, columns }))
      if (previousCount !== undefined && previousCount != totalCount) {
        await refreshRecordsCount([segment.externalId], itemType !== ItemType.FORM_SUBMISSION)
      }
    } finally {
      setContainerValues((state) => ({ ...state, loading: false }))
    }
  }

  useEffect(() => {
    update({ mappedIdsToUuids: mapContactIdToUuid(segmentContacts) })
  }, [segmentContacts])

  const onRemoveContactFromSegment = (recIds?: string[]) => {
    const contactsIds = recIds ? recIds : selectedContacts.map(({ recId }) => recId)
    setContainerValues((state) => ({ ...state, loading: true }))
    removeContactsFromSegmentUtils(segment.externalId as string, contactsIds, segmentClient).then((response) => {
      response.data?.removeContactsFromSegment && loadContacts()
    })
  }

  const onRemoveFromBounceClick = (contacts: Contact[]) => {
    setContainerValues((state) => ({
      ...state,
      confirmationModalData: {
        ...getChangeBounceStatusConfirmationModal(contacts, columns, segment, t),
        onAnswer: async (answer: YesNo) => {
          if (answer === YesNo.YES) {
            try {
              await onRemoveFromBounce(contacts, segment, segmentClient)
              loadContacts()
            } catch {
              update({
                statusToast: {
                  statusMessage: t(`Something went wrong on our end. No contacts have been changed.`),
                  successStatus: false,
                  showStatus: true,
                },
              })
            }
          }
        },
      },
    }))
  }

  const onColumnsSave = async (columns: Column[], allSubsegments: boolean, segmentId?: string) => {
    const id = segmentId ?? segment.externalId
    const newFields = columns.reduce((newColumnsList: UnifiedContactListColumn[], { newColumn }) => {
      return newColumn ? [...newColumnsList, newColumn] : newColumnsList
    }, [])
    if (newFields.length) {
      await addFieldColumnsToList({ listId: id, upsertColumns: newFields, isWebinar: itemType == ItemType.WEBINAR_SUBMISSION })
    }
    try {
      update({ loading: true })
      await saveColumnsUtils(columns, id, segmentClient, allSubsegments)
      loadContacts(columns)
      update({
        showColumnsOrderModal: false,
        statusToast: {
          showStatus: true,
          statusMessage: t('Your column fields have been updated.'),
          successStatus: true,
          title: t('Success!'),
        },
      })
    } catch {
      update({
        statusToast: {
          showStatus: true,
          statusMessage: t('Something went wrong on our end. Please try again.'),
          successStatus: false,
        },
      })
    }
  }

  const onDeleteContacts = () => {
    const hasUuids = typeof contactsToDelete[0] === 'string'
    deleteContactsUtils(
      !hasUuids ? contactsToDelete.map((recordId) => recordId as number) : [],
      hasUuids ? contactsToDelete.map((uuid) => uuid as string) : [],
      listClient
    ).then(() => {
      update({
        statusToast: {
          statusMessage: t(`We're deleting your contacts now. This may take some time. Check back later for an updated contact list.`),
          successStatus: true,
          showStatus: true,
        },
      })
    })
  }

  const onPaginationChange: OnChangeFn<PaginationState> = (updateOrValue) => {
    const pagination = isFunction(updateOrValue) ? updateOrValue({ pageIndex, pageSize }) : updateOrValue
    onPageOptionsChange?.(pagination)
    update(pagination)
  }

  const closeStatusToast = () => {
    setContainerValues((containerValues) => ({ ...containerValues, statusToast: { showStatus: false } }))
  }

  useEffect(() => {
    const doSaveColumns = async ({ columns, allSubsegments, segmentId, onSaveCallback }: ColumnsToSaveType) => {
      await onColumnsSave(columns, allSubsegments, segmentId)
      onSaveCallback()
    }
    if (columnsToSave) {
      doSaveColumns(columnsToSave)
    }
  }, [columnsToSave])

  useEffect(() => {
    if (defaultColumns && !equal(columns, defaultColumns)) {
      update({ columns: defaultColumns })
    }
  }, [defaultColumns])

  return (
    <ContactsDetailContext.Provider
      value={{
        values: { ...containerValues, unifiedListFieldMappings },
        loadContacts,
        onPaginationChange,
        onRemoveContactFromSegment,
        onRemoveFromBounceClick,
        onColumnsSave,
        onDeleteContacts,
        toggleDownloadModal,
        handleDownloadOrPreviewFile,
        update,
      }}
    >
      {showStatus && <StatusToast isSuccess={successStatus} message={statusMessage} closeStatus={closeStatusToast} title={statusToastTitle} />}
      <ContactsDetails
        segment={segment}
        segmentContacts={segmentContacts}
        selectedContacts={selectedContacts}
        onContactRowClicked={(row) => onContactRowClicked?.(row, mappedIdsToUuids[row.original.recId], columns)}
        onContactsSelected={onContactsSelected}
        itemType={itemType}
        {...rest}
      />
    </ContactsDetailContext.Provider>
  )
}

export default ContactsDetailsContainer
