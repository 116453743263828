import { ListPageCommonState } from '@complex/ListingPage/Context/ListingPageCommon.context'
import {
  CopyLandingPageOrTemplateMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CreateBeeLandingPageMutation,
  SaveBeeLandingPageMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetLastPublishedMessageContentQuery,
  IsValidUrlQuery,
  IsValidUrlQueryVariables,
  LabelDto,
  RetrieveBeeLandingPageQuery,
} from '@graphql/types/query-types'
import { DeepUpdateState, FetchPromise } from '@utils/types'

import { CommonComposerState } from '../commonComposer/CommonComposer.context'

export enum CreateLandingPageMethod {
  NEW = 'new',
  TEMPLATE = 'template',
  CODE = 'code',
  URL = 'url',
}

export interface LandingPageComposerRequests {
  retrieveBeeLandingPageRequest: (messageId: string) => FetchPromise<RetrieveBeeLandingPageQuery>
  saveBeeLandingPageRequest: (variables: SaveBeeLandingPageMutationVariables) => Promise<boolean>
  checkIsUrlValid: (url: IsValidUrlQueryVariables) => FetchPromise<IsValidUrlQuery>
  getLastPublishedMessage: (contentId: string) => Promise<GetLastPublishedMessageContentQuery['getLastPublishedMessageContent']>
  createLandingPage: (
    name: string,
    tags: LabelDto[],
    folderIdToClone: number | undefined
  ) => Promise<CreateBeeLandingPageMutation['createBeeLandingPage']>
  copyLandingPageTemplate: (
    variables: CopyLandingPageOrTemplateMutationVariables
  ) => Promise<CopyLandingPageOrTemplateMutation['copyLandingPageOrTemplate']>
}

export type BlockedModalProps = {
  title: string
  bodyText: string
  customButtonProps?: {
    onClick: () => void
    text: string
  }
}

export type DuplicateModalProps = {
  itemName?: string
  defaultName?: string
  headerTitleKey?: string
  itemNameLabel?: string
  namePlaceholder?: string
  onSubmitText?: string
  isLandingPageComposer?: boolean
  isLandingPageTemplateComposer?: boolean
  onDuplicateCancel?: () => void
  handleDuplicateCreate?: (
    name: string,
    tags: LabelDto[],
    folderIdToClone: number | undefined,
    includeUnpublishedChanges?: boolean
  ) => Promise<void> | void
  onInputChange?: (value: string) => void
  showLPToggleToIncludeChanges?: boolean
}

export interface CreateLandingPageModalProps {
  landingPageId?: string
  startId?: string
  className?: string
  isLandingListingPage?: boolean
  dataTest?: string
  onCancel?: () => void
  onCreate?: (method: CreateLandingPageMethod) => void
  isOpen: boolean
  isSaveFailed?: boolean
  showAdditionalOptions?: boolean
  disableTemplateOption?: boolean
  duplicateModalProps?: DuplicateModalProps
  update?: DeepUpdateState<CommonComposerState> | ((state: Partial<ListPageCommonState>) => void)
  failToastError?: {
    title: string
    message: string
  }
}
