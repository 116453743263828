import React, { FC, useCallback } from 'react'

import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import SpinnerModal from '@components/SpinnerModal/SpinnerModal'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import ComposerFormTypeModal from '@src/pages/EmailComposer/components/EmailComposerModalState/ComposerFormTypeModal/ComposerFormTypeModal'
import EditPlainTextModal from '@src/pages/EmailComposer/components/EmailComposerPreview/components/components/EditPlainTextModal/EditPlainTextModal'
import CreateLandingPageModal from '@src/pages/listingPages/LandingPages/components/CreateLandingPageModal/CreateLandingPageModal'
import CreateLandingPageTemplateModal from '@src/pages/listingPages/LandingPageTemplates/components/CreateLandingPageTemplateModal/CreateLandingPageTemplateModal'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import BlockedModal from '../../EmailModals/components/BlockedModal/BlockedModal'
import { detectEmailType } from '../../utils/EmailComposerDetector.utils'

const EmailComposerModalsState: FC = () => {
  const {
    values: {
      isCoEditing,
      editingUsernames,
      detectedURLChanges: { uploadHtmlMode, plainTextMode },
      message: { lastUpdatedBy, id, messageType },
      modalState: {
        duplicateModal,
        spinner,
        statusToast,
        editPlainText,
        deleteConfirmation,
        confirmation,
        formTypeModal,
        bulkErrorModal,
        blockedModal,
        duplicateTemplateModal,
      },
      landingPage: { isLandingPage },
      isSaveFailed,
    },
    api: { updateModal, update },
  } = useComposerContext()
  const { t } = useTranslation()

  const handleCloseToast = useCallback(
    () => updateModal('statusToast', undefined, isLandingPage && !duplicateModal),
    [duplicateModal, isLandingPage, updateModal]
  )

  const handleCloseConfirmationModal = useCallback(() => updateModal('confirmation', undefined), [updateModal])
  const handleOnAnswer = useCallback((anw: YesNo) => deleteConfirmation?.onAnswer?.(anw), [deleteConfirmation])

  const { isLandingPageTemplate } = detectEmailType(messageType)

  return (
    <>
      {duplicateModal && (
        <CreateLandingPageModal
          isOpen
          update={update}
          landingPageId={isLandingPageTemplate ? '' : id}
          duplicateModalProps={duplicateModal}
          isSaveFailed={isSaveFailed}
        />
      )}
      {duplicateTemplateModal && (
        <CreateLandingPageTemplateModal isOpen startId={id} duplicateModalProps={duplicateTemplateModal} isSaveFailed={isSaveFailed} />
      )}
      {(isCoEditing || !!blockedModal) && (
        <BlockedModal
          username={uploadHtmlMode || plainTextMode ? lastUpdatedBy : editingUsernames.join(', ')}
          title={blockedModal?.title}
          bodyText={blockedModal?.bodyText}
          {...blockedModal}
        />
      )}
      {editPlainText && <EditPlainTextModal />}
      {statusToast && <StatusToast {...statusToast} closeStatus={handleCloseToast} />}
      {deleteConfirmation && (
        <DeleteConfirmationModal
          {...deleteConfirmation}
          isOpen
          title={deleteConfirmation.title || t('Are you sure?')}
          body={deleteConfirmation.body ?? ''}
          deleteButtonText={t('Discard changes')}
          onAnswer={handleOnAnswer}
        />
      )}
      {spinner && <SpinnerModal {...spinner} />}
      {confirmation && <ConfirmationModal body={''} closeModal={handleCloseConfirmationModal} {...confirmation} isOpen />}
      {formTypeModal && <ComposerFormTypeModal {...formTypeModal} />}
      {bulkErrorModal && <BulkActionsModal {...bulkErrorModal} />}
    </>
  )
}

export default EmailComposerModalsState
