import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import copyLandingPageOrTemplate from '@graphql/mutations/copyLandingPageOrTemplate'
import createBeeLandingPage from '@graphql/mutations/createBeeLandingPage'
import saveBeeLandingPage from '@graphql/mutations/saveBeeLandingPage'
import getLastPublishedMessageContent from '@graphql/queries/getLastPublishedMessageContent'
import isValidUrl from '@graphql/queries/isValidUrl'
import retrieveBeeLandingPage from '@graphql/queries/retrieveBeeLandingPage'
import {
  CopyLandingPageOrTemplateMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CreateBeeLandingPageMutation,
  CreateBeeLandingPageMutationVariables,
  SaveBeeLandingPageMutation,
  SaveBeeLandingPageMutationVariables,
} from '@graphql/types/mutation-types'
import {
  GetLastPublishedMessageContentQuery,
  GetLastPublishedMessageContentQueryVariables,
  IsValidUrlQuery,
  IsValidUrlQueryVariables,
  LabelDto,
  RetrieveBeeLandingPageQuery,
  RetrieveBeeLandingPageQueryVariables,
} from '@graphql/types/query-types'
import { LandingPageComposerRequests } from '@utils/composer/landingPage/types'
import { logError } from '@utils/env'

export const useLandingPageComposerRequests = (): LandingPageComposerRequests => {
  const actonClient = useApolloClient()

  const retrieveBeeLandingPageRequest = useCallback(
    async (pageId: string) =>
      actonClient
        .query<RetrieveBeeLandingPageQuery, RetrieveBeeLandingPageQueryVariables>({
          query: retrieveBeeLandingPage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: { pageId },
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors }
        }),
    [actonClient]
  )

  const saveBeeLandingPageRequest = useCallback(
    async (variables: SaveBeeLandingPageMutationVariables) => {
      const saveEmailContent = async (variables: SaveBeeLandingPageMutationVariables) => {
        const result = await actonClient
          .mutate<SaveBeeLandingPageMutation, SaveBeeLandingPageMutationVariables>({
            mutation: saveBeeLandingPage,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables,
          })
          .catch((errors) => {
            logError(errors)
            return { data: undefined, errors }
          })

        return !!result.data?.saveBeeLandingPage
      }

      try {
        return await saveEmailContent(variables)
      } catch (error) {
        logError(error)
        return false
      }
    },
    [actonClient]
  )

  const checkIsUrlValid = useCallback(
    async (variables: IsValidUrlQueryVariables) =>
      actonClient
        .query<IsValidUrlQuery, IsValidUrlQueryVariables>({
          query: isValidUrl,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables,
        })
        .catch((errors) => {
          return { data: undefined, errors }
        }),
    [actonClient]
  )

  const createLandingPage = useCallback(
    async (name: string, tags: LabelDto[], folderIdToClone: number | undefined) => {
      const result = await actonClient
        .mutate<CreateBeeLandingPageMutation, CreateBeeLandingPageMutationVariables>({
          mutation: createBeeLandingPage,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: {
            createBeeLandingPageInput: {
              folderId: folderIdToClone,
              tags: tags,
              title: name,
            },
          },
        })
        .catch((errors) => {
          logError(errors)
          return { data: undefined, errors: errors }
        })

      return result?.data?.createBeeLandingPage || { id: '' }
    },
    [actonClient]
  )

  const getLastPublishedMessage = useCallback(
    async (contentId: string) => {
      const result = await actonClient.query<GetLastPublishedMessageContentQuery, GetLastPublishedMessageContentQueryVariables>({
        query: getLastPublishedMessageContent,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { contentId },
      })

      if (result.data) {
        return result.data.getLastPublishedMessageContent
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  const copyLandingPageTemplate = useCallback(
    async (variables: CopyLandingPageOrTemplateMutationVariables) => {
      const result = await actonClient.mutate<CopyLandingPageOrTemplateMutation, CopyLandingPageOrTemplateMutationVariables>({
        mutation: copyLandingPageOrTemplate,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables,
      })

      if (result.data) {
        return result.data.copyLandingPageOrTemplate
      } else {
        logError(result.errors)
      }
    },
    [actonClient]
  )

  return {
    getLastPublishedMessage,
    retrieveBeeLandingPageRequest,
    saveBeeLandingPageRequest,
    createLandingPage,
    checkIsUrlValid,
    copyLandingPageTemplate,
  }
}
