import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import ContactsDetailsContainer from '@components/ContactsDetails/ContactsDetailsContainer'
import { filterContactColumns } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { PaginationState } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { Contact } from '@utils/contact/contact.constants'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

import './DirectSelectSegmentBuilder.css'

interface DirectSelectSegmentBuilderProps {
  className?: string
  dataTest?: string
}

const rootClass = 'direct-select-segment-builder'
const MODIFIED_DATE_FIELD = 'Modified Date'
const CREATED_DATE_FIELD = 'Created Date'

const DirectSelectSegmentBuilder: FC<DirectSelectSegmentBuilderProps> = (props: DirectSelectSegmentBuilderProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update,
    values: {
      allContactsSegment,
      columns,
      columnsToSave,
      defaultSelectedContacts,
      parentContactsPage,
      isNew,
      isViewingSelectedContacts,
      segmentId,
      segmentHierarchy,
      selectedContacts,
      selectedContactsPage,
    },
  } = useContext(SegmentComposerContext)

  const { t } = useTranslation()

  /*
   * if isNew is true, then the segmentId is the ID of the parent segment
   * otherwise, the segmentId is the ID of the current segment
   */
  const getParentSegment = useCallback(
    (segmentHierarchy: Segment[]): Segment | undefined => {
      for (const segment of segmentHierarchy) {
        if (isNew && segment.externalId === segmentId) {
          return segment
        }
        if (segment.subRows?.length) {
          if (!isNew && segment.subRows.some(({ externalId }) => externalId === segmentId)) {
            return segment
          }
          const parentSegment = getParentSegment(segment.subRows)
          if (parentSegment) {
            return parentSegment
          }
        }
      }
      return allContactsSegment
    },
    [allContactsSegment, isNew, segmentId]
  )

  const hasContactsSelected = selectedContacts.length > 0
  const parentSegment = useMemo(() => getParentSegment(segmentHierarchy), [getParentSegment, segmentHierarchy])

  const onViewSelectedClick = () => {
    update({ isViewingSelectedContacts: !isViewingSelectedContacts })
  }

  const onColumnsSave = (columns: Column[], allSubsegments: boolean) => {
    if (!isNew) {
      if (segmentId) {
        update({
          columns,
          columnsToSave: { columns, allSubsegments, segmentId, onSaveCallback: () => update({ columnsToSave: undefined }) },
        })
      }
    } else {
      // TODO wait until segment save, then save columns
      update({ columns })
    }
  }

  const onContactsSelected = (contacts: Contact[]) => {
    update({ selectedContacts: contacts })
  }

  const hasMatchingField = ({ fields }: Contact, search: string) => {
    const filteredColumns = columns?.filter(filterContactColumns)
    return fields.some((field, index) => {
      if (filteredColumns && filteredColumns[index].name === CREATED_DATE_FIELD && filteredColumns[index].name === MODIFIED_DATE_FIELD) {
        return false
      }
      return field.toLowerCase().includes(search.toLowerCase())
    })
  }

  const getFilteredContacts = (search: string, contacts: Contact[]) =>
    search ? contacts.filter((contact) => hasMatchingField(contact, search)) : contacts

  const getSelectedContactsPage = (pageIndex: number, pageSize: number, contacts: Contact[]): Record<string, string[]> => {
    const actualContacts = contacts.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
    return actualContacts.reduce((acc: Record<string, string[]>, { recId = '', rawFields = [] }) => ({ ...acc, [recId]: rawFields }), {})
  }

  const onContactsLoaded = (page: PageInput) => {
    update({ parentContactsPage: page })
  }

  const onPageOptionsChange = ({ pageIndex, pageSize }: PaginationState) => {
    if (isViewingSelectedContacts) {
      const contacts = search ? getFilteredContacts(search, defaultSelectedContacts) : defaultSelectedContacts
      update({
        selectedContactsPage: {
          ...selectedContactsPage,
          contacts: getSelectedContactsPage(pageIndex, pageSize, contacts),
          pageNumber: pageIndex,
          pageSize,
          totalCount: contacts.length,
        },
      })
    }
  }

  const onSearch = (search: string) => {
    if (isViewingSelectedContacts) {
      const matchingContacts = getFilteredContacts(search, defaultSelectedContacts)
      const actualPageIndex = 0
      const actualPageSize = selectedContactsPage.pageSize ?? 20
      update({
        selectedContactsPage: {
          ...selectedContactsPage,
          contacts: getSelectedContactsPage(actualPageIndex, actualPageSize, matchingContacts),
          pageNumber: actualPageIndex,
          pageSize: actualPageSize,
          search,
          totalCount: matchingContacts.length,
        },
      })
    }
  }

  useEffect(() => {
    const pageNumber = 0
    const pageSize = parentContactsPage.pageSize ?? 20
    const search = parentContactsPage.search
    const contacts = search ? getFilteredContacts(search, selectedContacts) : selectedContacts
    update({
      defaultSelectedContacts: selectedContacts,
      selectedContactsPage: {
        ...parentContactsPage,
        contacts: getSelectedContactsPage(pageNumber, pageSize, contacts),
        pageNumber,
        pageSize,
        search,
        totalCount: contacts.length,
      },
    })
  }, [isViewingSelectedContacts])

  const viewSelectedSection = (
    <div className={`${rootClass}__view-selected`}>
      <Button
        className={`${rootClass}__view-selected-button`}
        buttonType={ButtonType.INFO}
        onClick={onViewSelectedClick}
        disabled={!hasContactsSelected && !isViewingSelectedContacts}
      >
        <Svg type={SvgType.LARGER_ICON} name={isViewingSelectedContacts ? SvgNames.arrowLeft : SvgNames.listCheck} />
        <Typography
          text={t(isViewingSelectedContacts ? 'View all' : 'View selected')}
          type={TextType.NORMAL_TEXT_TEAL_LARGE}
          weight={TextWeight.MEDIUM}
        />
      </Button>
      <Typography
        dataTest={`${dataTest}__selected-contacts-count`}
        lineHeight={LineHeight.MEDIUM_SMALL}
        text={t('SegmentComposer.Build.DirectSelect.SelectedContacts', { count: selectedContacts.length })}
        type={TextType.BODY_TEXT_SMALL_LIGHT}
        tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
        inline
      />
    </div>
  )

  const search = isViewingSelectedContacts ? selectedContactsPage.search : parentContactsPage.search

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography
        dataTest={`${dataTest}__contacts-from`}
        text={t('SegmentComposer.Build.DirectSelect.TableHeader', {
          context: search ? 'search' : 'default',
          name: parentSegment?.name,
          search,
        })}
        type={TextType.BANNER_HEADER_DARK}
      />
      {parentSegment && (
        <ContactsDetailsContainer
          className={`${rootClass}__contacts-details`}
          columnsToSave={columnsToSave}
          defaultColumns={columns}
          itemType={ItemType.SEGMENT}
          segment={parentSegment}
          segmentContacts={isViewingSelectedContacts ? selectedContactsPage : parentContactsPage}
          onColumnsSave={onColumnsSave}
          onContactsLoaded={onContactsLoaded}
          onContactsSelected={onContactsSelected}
          onPageOptionsChange={onPageOptionsChange}
          onSearch={onSearch}
          selectedContacts={selectedContacts}
          tableHeader={viewSelectedSection}
          customHeaderActions={() => []}
          customRowActions={() => []}
          showSelectedContactsOnly={isViewingSelectedContacts}
          enableTableV2
        />
      )}
    </div>
  )
}

export default DirectSelectSegmentBuilder
