import { SvgNames } from '@components/Svg'
import { FilterDefinition } from '@utils/filter'

export const allSmsDraftsFilter: FilterDefinition = {
  name: 'All SMS Drafts',
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allSmsScheduledFilter: FilterDefinition = {
  name: 'All Scheduled SMS',
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}

export const allSmsSentFilter: FilterDefinition = {
  name: 'All Sent SMS',
  svgUnselected: SvgNames.segmentsUnselected,
  svgSelected: SvgNames.segmentsSelected,
}
