import React, { FC, useContext, useEffect } from 'react'

import classNames from 'classnames'

import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import PreviewAssetModalContent from '@components/PreviewAssetModal/PreviewAssetModalContent'
import Spinner from '@components/Spinner/Spinner'
import { useMinimumLoadingDuration } from '@utils/hooks/useMinimumLoadingDuration'

import AssetPickerPreviewMetadata from './AssetPickerPreviewMetadata/AssetPickerPreviewMetadata'

import './AssetPickerPreview.css'

interface AssetPickerPreviewContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-preview'

const AssetPickerPreviewContainer: FC<AssetPickerPreviewContainerProps> = (props) => {
  const { className, dataTest = className } = props
  const {
    values,
    update,
    setError,
    values: {
      previewUrl,
      previewHtml,
      previewMetadata,
      renderPreview,
      loading,
      listingPageProps: { customPreviewItemCall },
    },
  } = useContext(ListingPageCommonContext)

  useEffect(() => {
    customPreviewItemCall && customPreviewItemCall(values, update, setError)
  }, [])

  // There will be a brief period between when user clicked Preview and when we receive these props
  const isPreviewPropsBlank = !renderPreview && !previewUrl && !previewHtml
  const showLoader = useMinimumLoadingDuration({ isLoading: isPreviewPropsBlank })

  return (
    <div className={classNames(`${rootClass}`, { [`${rootClass}--has-metadata`]: previewMetadata, [`${rootClass}--loading`]: showLoader })}>
      {renderPreview ? (
        <div className={`${rootClass}__custom-content`} data-test={`${dataTest}-custom-content`}>
          {renderPreview()}
        </div>
      ) : previewUrl || previewHtml ? (
        <div className={`${rootClass}__content`}>
          <PreviewAssetModalContent
            title={'Asset Preview'}
            assetSrc={previewUrl}
            previewHtml={previewHtml}
            loading={loading}
            checkIsPreviewEmpty
            {...props}
          />
        </div>
      ) : (
        <Spinner />
      )}
      {previewMetadata && <AssetPickerPreviewMetadata {...previewMetadata} />}
    </div>
  )
}

export default AssetPickerPreviewContainer
