import { useCallback, useState } from 'react'

import { AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import {
  CustomSource,
  ListingPageExternalApi,
  ListingPageSubType,
  ListPageCommonState,
  SetError,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { SortingState } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'

import { useEmailDraftPickerProps } from './Drafts/useEmailDraftsPickerProps'
import { useEmailScheduledPickerProps } from './Scheduled/useEmailScheduledPickerProps'
import { useEmailSentPickerProps } from './Sent/useEmailSentPickerProps'
import { useEmailTemplatesPickerProps } from './Templates/useEmailTemplatesPickerProps'
import { useEmailTriggeredPickerProps } from './Triggered/useEmailTriggeredPickerProps'
import { useEmailTemplatesPickerRequests } from '../EmailTemplatesPickerModal/GraphQL/EmailTemplatesPickerRequests.graphQL'

export enum EmailType {
  // Categorization Service
  DRAFT = `EMAIL_DRAFT`,
  TEMPLATE = `EMAIL_TEMPLATE`,
  // Non-Categorization Service
  SENT = 'EMAIL_SENT',
  SCHEDULED = 'EMAIL_SCHEDULED',
  TRIGGERED = 'EMAIL_TRIGGERED',
}

export interface EmailPickerProps {
  customSources: CustomSource
  i18nListPageKey: string
  renderSearchColumns: AssetPickerModalProps['tableProps']['renderSearchColumns']
  renderTableColumns: AssetPickerModalProps['tableProps']['columns']
  renderCustomFilters: AssetPickerModalProps['listingPageProps']['sidebarProps']['renderCustomFilters']
  onApiAvailable?: AssetPickerModalProps['listingPageProps']['onApiAvailable']
  enableSorting?: AssetPickerModalProps['tableProps']['enableSorting']
  sortingBy?: SortingState
  hasCustomFilters: boolean
  subTypes: ListingPageSubType[]
  defaultActiveSubTypes?: string[]
  hasTags: boolean
  hideFolders?: boolean
  hasCreatedByMe: boolean
  hasFavorites: boolean
  externalDataLoading?: boolean
}

export const useEmailPickerProps = (emailType: EmailType) => {
  const [listingApi, setListingApi] = useState<ListingPageExternalApi>()

  const propsMap: Record<EmailType, EmailPickerProps> = {
    [EmailType.SENT]: useEmailSentPickerProps(listingApi),
    [EmailType.DRAFT]: useEmailDraftPickerProps(),
    [EmailType.TEMPLATE]: useEmailTemplatesPickerProps(),
    [EmailType.SCHEDULED]: useEmailScheduledPickerProps(listingApi),
    [EmailType.TRIGGERED]: useEmailTriggeredPickerProps(listingApi),
  }

  const customSources: CustomSource[] = Object.values(propsMap).map((props) => props.customSources)

  const currentSource = customSources.find((source) => source.value === emailType) ?? customSources[0]
  const {
    sortingBy,
    renderTableColumns,
    renderSearchColumns,
    renderCustomFilters,
    hasFavorites,
    hasCreatedByMe,
    hasTags,
    hasCustomFilters,
    i18nListPageKey,
    subTypes,
    defaultActiveSubTypes,
    externalDataLoading,
    hideFolders,
    enableSorting,
  } = propsMap[emailType]

  // Note: There doesn't appear to be a difference in rendering between previews for different email types
  const { getItemPreviewRequest } = useEmailTemplatesPickerRequests()
  const customPreviewItemCall = async (listPageValues: ListPageCommonState, update: Update, setError: SetError) => {
    const { selectedRows } = listPageValues

    update({ loading: true })
    const { data, errors } = await getItemPreviewRequest(selectedRows[0].externalId ?? '')

    if (data) {
      update({ loading: false, previewHtml: data.getEmailTemplatePreview.html })
    } else if (errors) {
      setError('There was a problem loading your email preview', errors)
    }
  }

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns,
    renderSearchColumns,
    listPage: i18nListPageKey,
    enableSorting,
    // `enableSorting` would only be set if all items are being displayed at once
    enableLazyLoading: !enableSorting,
    sortingBy,
  }

  const sidebarProps: AssetPickerSidebarProps = {
    hasRecent: false,
    hasCreatedByMe,
    hasFavorites,
    hideFilterCount: false,
    allItemFilter: currentSource.allItemFilter!,
    hasSalesUsersAction: false,
    hideFolders,
    customSources,
    renderCustomFilters,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    customPreviewItemCall,
    onApiAvailable: useCallback(
      (api: ListingPageExternalApi) => {
        setListingApi(api)
      },
      [setListingApi]
    ),
    sidebarProps,
    hasCustomRequests: true,
    subTypes,
    defaultActiveSubTypes,
    externalDataLoading,
  }

  const assetPickerProps: AssetPickerModalProps = {
    className: emailType,
    listingPageProps,
    tableProps,
    hasTags,
    hasCustomFilters,
    hasSidebar: true,
    hasPreview: true,
    itemType: emailType as unknown as ItemType,
    restrictSelectionToOneSource: false,
    isOpen: true,
    hideRecordsCount: true,
  }

  return assetPickerProps
}
