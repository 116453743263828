export const formStyles = `.ao-form .ao-recaptcha-error {
  border: 1px solid #ff0000;
  padding: 5px;
}

.ao-form .ao-recaptcha-error-robot-message {
  width: 316px;
  text-align: left;
  color: #ff0000;
  display: none;
}

.ao-form .ao-recaptcha-error-wrapper {
  width: 304px;
  height: 78px;
}

.ao-form .ao-recaptcha-wrapper {
  display: inline-block;
}

.ao-form img:not([width]) {
  max-width: 100%;
}

.ao-form input {
  border: 1px solid #ccc;
}

.ao-form input:not([type='radio']):not([type='checkbox']) {
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px;
}

.ao-form select {
  width: 100%;
  display: block;
  border-style: solid;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 0;
  background-position: calc(100% - 12px);
  background-size: auto;
  background-repeat: no-repeat;
}

.ao-form select::-ms-expand {
  display: none;
}

.ao-form textarea {
  width: 100%;
  display: block;
  box-sizing: border-box;
  resize: vertical;
  height: 75px;
  line-height: 1.5;
  padding: 5px;
  border-style: solid;
}

.ao-form button.ao-form-submit {
  cursor: pointer;
}

.ao-form label.ao-form-label {
  line-height: 1.5;
  display: block;
}

.ao-form div.ao-form-field-date-wrapper input.ao-form-field-date {
  width: 2.8em;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.ao-form div.ao-form-field-date-wrapper select.ao-form-field-date {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}

.ao-form div.ao-form-field-date-wrapper input.ao-form-field-date.ao-form-field-date-lg {
  width: 5.6em;
}

.ao-form input.ao-form-error,
.ao-form textarea.ao-form-error,
.ao-form select.ao-form-error {
  border: 1px solid #ff0000;
}

.ao-form div.ao-file-upload-error {
  border-color: #f25656 !important;
  background-color: #fff7f7 !important;
}

.ao-form label.ao-file-upload-error-label {
  background-color: #fff7f7 !important;
}
.ao-form input:not([type='radio']):not([type='checkbox']):focus,
.ao-form select:focus,
.ao-form textarea:focus {
  outline-style: none;
}

.ao-form .ao-block-wrapper {
  clear: both;
}

.ao-form .ao-block-wrapper input + span.ao-form-error-message,
.ao-form .ao-block-wrapper select + span.ao-form-error-message,
.ao-form .ao-block-wrapper textarea + span.ao-form-error-message,
.ao-form .ao-block-wrapper div.ao-form-field-date-wrapper > span.ao-form-error-message,
.ao-form .ao-block-wrapper div.ao-combo-layout + span.ao-form-error-message {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 1.5;
}

.ao-form .ao-block-wrapper input.ao-form-error + span.ao-form-error-message,
.ao-form .ao-block-wrapper select.ao-form-error + span.ao-form-error-message,
.ao-form .ao-block-wrapper div.ao-form-field-date-wrapper > input.ao-form-error ~ span.ao-form-error-message,
.ao-form .ao-block-wrapper div.ao-combo-layout.ao-form-error + span.ao-form-error-message {
  visibility: visible;
}

.ao-form .ao-combo-label {
  display: inline-block;
}

.ao-form .ao-combo-label input {
  position: relative;
  vertical-align: middle;
}

.ao-form .ao-combo-label label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.ao-form .ao-combo-layout:after {
  content: '';
  display: table;
}

.ao-form .horizontal .ao-combo-label {
  margin-right: 10px;
}

.ao-form .ao-fit img {
  width: 100%;
}

.ao-form .ao-left {
  text-align: left;
}

.ao-form .ao-right {
  text-align: right;
}

.ao-form .ao-right select.ao-form-field {
  text-align: right;
  text-align-last: right;
  padding-right: 30px;
}

.ao-form .ao-center {
  text-align: center;
}

.ao-form .ao-center select.ao-form-field {
  text-align: center;
  text-align-last: center;
}

.ao-form .ao-row-wrapper {
  max-width: 100%;
}

.ao-form .ao-row {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
}

.ao-form .ao-row .ao-column {
  display: flex;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

.ao-form .ao-row .ao-column-inner {
  box-sizing: border-box;
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  box-flex: auto;
  -webkit-flex: auto;
  -moz-flex: auto;
  -ms-flex: auto;
  flex: auto;
  width: 100%;
}

.ao-form .ao-row .ao-image-block {
  max-width: 100%;
  display: block;
}

.ao-form .ao-row .ao-image-block.left {
  text-align: left;
}

.ao-form .ao-row .ao-image-block.right {
  text-align: right;
}

.ao-form .ao-row .ao-image-block.center {
  text-align: center;
}

.ao-form .ao-row .ao-image-block.fit {
  width: 100%;
}

.ao-form .ao-row .ao-column.ao-column-12,
.ao-form .ao-row .vertical1 .ao-combo-label {
  width: 100%;
}

.ao-form .ao-row .ao-column.ao-column-12.ao-column-label2,
.ao-form .ao-row .vertical1 .ao-combo-label.ao-column-label2 {
  float: left;
}

.ao-form .ao-row .ao-column.ao-column-11 {
  width: 91.6667%;
}

.ao-form .ao-row .ao-column.ao-column-10 {
  width: 83.3333%;
}

.ao-form .ao-row .ao-column.ao-column-9 {
  width: 75%;
}

.ao-form .ao-row .ao-column.ao-column-8 {
  width: 66.6667%;
}

.ao-form .ao-row .ao-column.ao-column-7 {
  width: 58.3333%;
}

.ao-form .ao-row .ao-column.ao-column-6,
.ao-form .ao-row .vertical2 .ao-combo-label {
  width: 50%;
}

.ao-form .ao-row .ao-column.ao-column-6.ao-column-label2,
.ao-form .ao-row .vertical2 .ao-combo-label.ao-column-label2 {
  float: left;
}

.ao-form .ao-row .ao-column.ao-column-5 {
  width: 41.6667%;
}

.ao-form .ao-row .ao-column.ao-column-4,
.ao-form .ao-row .vertical3 .ao-combo-label {
  width: 33.3333%;
}

.ao-form .ao-row .ao-column.ao-column-4.ao-column-label2,
.ao-form .ao-row .vertical3 .ao-combo-label.ao-column-label2 {
  float: left;
}

.ao-form .ao-row .ao-column.ao-column-3,
.ao-form .ao-row .vertical4 .ao-combo-label {
  width: 25%;
}

.ao-form .ao-row .ao-column.ao-column-3.ao-column-label2,
.ao-form .ao-row .vertical4 .ao-combo-label.ao-column-label2 {
  float: left;
}

.ao-form .ao-row .ao-column.ao-column-2 {
  width: 16.6667%;
}

.ao-form .ao-row .ao-column.ao-column-1 {
  width: 8.33333%;
}

@media (max-width: 767px) {
  .ao-lp .responsive .ao-form:not(.ao-responsive) .ao-row {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
  }
  .ao-form.ao-responsive .ao-row {
    flex-wrap: wrap;
    display: flex;
  }

  .ao-form.ao-responsive .ao-row,
  .ao-form.ao-responsive .ao-row-wrapper {
    width: 100%;
  }

  .ao-form.ao-responsive .ao-column.ao-column-1,
  .ao-form.ao-responsive .ao-column.ao-column-2,
  .ao-form.ao-responsive .ao-column.ao-column-3,
  .ao-form.ao-responsive .ao-column.ao-column-4,
  .ao-form.ao-responsive .ao-column.ao-column-5,
  .ao-form.ao-responsive .ao-column.ao-column-6,
  .ao-form.ao-responsive .ao-column.ao-column-7,
  .ao-form.ao-responsive .ao-column.ao-column-8,
  .ao-form.ao-responsive .ao-column.ao-column-9,
  .ao-form.ao-responsive .ao-column.ao-column-10,
  .ao-form.ao-responsive .ao-column.ao-column-11,
  .ao-form.ao-responsive .ao-column.ao-column-12 {
    display: flex;
  }

  .ao-form.ao-responsive .ao-column.mobile-ao-column-1 {
    width: 100%;
  }

  .ao-form.ao-responsive .ao-column.mobile-ao-column-2 {
    width: 50%;
  }

  .ao-form.ao-responsive .ao-column.mobile-ao-column-3 {
    width: 33.3333%;
  }

  .ao-form.ao-responsive .ao-column.mobile-ao-column-4 {
    width: 25%;
  }

  .ao-form.ao-responsive .ao-column-inner {
    display: block;
  }

  .ao-form.ao-responsive .ao-block {
    overflow: hidden;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ao-form.ao-responsive .ao-row {
    flex-wrap: wrap;
    display: flex;
  }

  .ao-form.ao-responsive .ao-row,
  .ao-form.ao-responsive .ao-row-wrapper {
    width: 100%;
  }

  .ao-form.ao-responsive .ao-column.ao-column-1,
  .ao-form.ao-responsive .ao-column.ao-column-2,
  .ao-form.ao-responsive .ao-column.ao-column-3,
  .ao-form.ao-responsive .ao-column.ao-column-4,
  .ao-form.ao-responsive .ao-column.ao-column-5,
  .ao-form.ao-responsive .ao-column.ao-column-6,
  .ao-form.ao-responsive .ao-column.ao-column-7,
  .ao-form.ao-responsive .ao-column.ao-column-8,
  .ao-form.ao-responsive .ao-column.ao-column-9,
  .ao-form.ao-responsive .ao-column.ao-column-10,
  .ao-form.ao-responsive .ao-column.ao-column-11,
  .ao-form.ao-responsive .ao-column.ao-column-12 {
    display: flex;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-1 {
    width: 100%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-2 {
    width: 50%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-3 {
    width: 33.3333%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-4 {
    width: 25%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-5 {
    width: 20%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-6 {
    width: 16.6667%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-7 {
    width: 14.2857%;
  }

  .ao-form.ao-responsive .ao-column.tablet-ao-column-8 {
    width: 12.5%;
  }

  .ao-form.ao-responsive .ao-column-inner {
    display: block;
  }

  .ao-form.ao-responsive .ao-block {
    overflow: hidden;
  }
}

#browse-file {
  user-select: none;
}

#file-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  position: relative;
}

#defaultMessage {
  display: block;
}

#fileList {
  display: none;
}

#file-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  margin-bottom: 12px;
  position: relative;
}

#remove-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: none;
}

#tooltip {
  user-select: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Open Sans', Arial, sans-serif;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 0;
}

#tooltip div {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

#file-details-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  flex: 1;
}

#file-name-container {
  display: flex;
  justify-content: flex-end;
}

#file-name {
  color: #444444;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

#file-size-container {
  display: flex;
  justify-content: flex-end;
}

#file-size {
  color: #767676;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

#status-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex: 1;
  gap: 6px;
}

#progress-bar-wrapper {
  width: 263px;
  background-color: #cbd0dc;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: 8px;
  display: none;
}

#progress-bar {
  width: 0%;
  background-color: #336699;
  height: 8px;
  border-radius: 12px;
  color: #fff;
  transition: width 0.2s ease-in-out;
}

#status-icon-container {
  height: 17px;
}

#status-message-container {
  display: flex;
  justify-content: flex-start;
}

#status-message {
  color: #444444;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
`
