import React from 'react'

import { TFunction } from 'i18next'

import {
  AssetPickerTableType,
  renderDefaultDateColumn,
  renderDefaultNameColumn,
  renderDefaultSubmitsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { TABLEV2_CELL_PADDING } from '@components/TableV2/utils/commonUtils'
import { relativeDateFormat, standardDateFormat } from '@utils/date/dateUtils'

import { SmsPickerType, SmsPickerUi } from './SmsPickerModal.utils'

const rootClass = 'sms-picker-modal'

export interface SmsColumnNames {
  name: string
  userName: string
  timestamp: string
}

const mediumColumnSize = { maxSize: 190, minSize: 190 }
const longColumnSize = { maxSize: 360 - TABLEV2_CELL_PADDING * 2, minSize: 360 - TABLEV2_CELL_PADDING * 2 }

export const renderSmsNameCell = (row: ItemDtoRow, search: string, _rootClass: string, t: TFunction) => {
  const name = (row as unknown as SmsPickerUi).name || t('--')
  return <div>{search ? renderBoldTextOnMatch(name, search) : name}</div>
}

export const renderSmsTableColumns = (
  pickerType: SmsPickerType,
  columnNames: SmsColumnNames,
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  renderDefaultNameColumn(t(columnNames.name), '', rootClass, (row, search, rootClass) => renderSmsNameCell(row, search, rootClass, t)),
  { ...renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t(columnNames.userName), 'userName'), ...mediumColumnSize },
  {
    ...renderDefaultDateColumn(
      AssetPickerTableType.DEFAULT,
      columnNames.timestamp,
      'timestamp',
      pickerType === SmsPickerType.SCHEDULED ? relativeDateFormat : standardDateFormat
    ),
    ...mediumColumnSize,
  },
]

export const renderSmsSearchTableColumns = (
  pickerType: SmsPickerType,
  columnNames: SmsColumnNames,
  search: string,
  t: TFunction
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  {
    ...renderDefaultNameColumn(t(columnNames.name), search, rootClass, (row, search, rootClass) => renderSmsNameCell(row, search, rootClass, t)),
    ...longColumnSize,
  },
  {
    ...renderDefaultSubmitsColumn(AssetPickerTableType.DEFAULT, t(columnNames.userName), 'userName'),
    cell: (cell) => (search ? renderBoldTextOnMatch(cell.getValue(), search) : cell.getValue()),
    ...mediumColumnSize,
  },
  {
    ...renderDefaultDateColumn(
      AssetPickerTableType.SEARCH,
      columnNames.timestamp,
      'timestamp',
      pickerType === SmsPickerType.SCHEDULED ? relativeDateFormat : standardDateFormat
    ),
    ...mediumColumnSize,
  },
]
