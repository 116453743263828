import React, { FC, ReactElement } from 'react'

import classNames from 'classnames'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import {
  dropdownFooter,
  getLinkedRowActionOptions,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'

import './ConditionGroup.css'

interface ConditionGroupProps {
  children: ReactElement[]
  connector?: string
  className?: string
  dataTest?: string
  row: RowDefinitionDto
}

const rootClass = 'condition-group'

const ConditionGroup: FC<ConditionGroupProps> = (props: ConditionGroupProps) => {
  const { dataTest = rootClass, className = '', connector = 'AND', children, row } = props
  const { t } = useTranslation()

  const actionsOptions = getLinkedRowActionOptions(t)

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {children.map((component, index) => (
        <div className={`${rootClass}__condition-row-container`} key={`row-${row.rowId}-linked-row-${index}`}>
          <div className={`${rootClass}__depth-indicator`} />
          <div className={`${rootClass}__condition-row`}>
            <div className={`${rootClass}__connector`}>
              <div className={`${rootClass}__connector-start`} />
              <Typography className={`${rootClass}__logic-connector`} text={connector} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.BOLD} />
              <Svg name={SvgNames.linkedArrow} type={SvgType.LARGER_ICON} fill={SvgColor.BUTTON_GRAY} />
            </div>
            <div className={`${rootClass}__expression`}>{component}</div>
            <TriggerButton
              dataTest={`${dataTest}-action-button`}
              className={`${rootClass}__action-button`}
              dropDownProps={{ align: 'end', footer: dropdownFooter }}
              alignRight={false}
              label={''}
              useLabel={false}
              options={actionsOptions}
              buttonType={ButtonType.FLOAT}
              svgName={SvgNames.overflowMenu}
              svgIcon={SvgType.LARGER_ICON}
              showCaretIcon={false}
              iconPosition={ButtonIconPosition.FLOAT}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default ConditionGroup
