import { gql } from '@apollo/client'

export default gql`
  query getAllTriggeredMessages($assetType: AssetType) {
    getAllTriggeredMessages(assetType: $assetType) {
      folderName
      folderId
      count
      messages {
        id
        title
        sentCount
        createdBy
        sentTime
        assetType
        assetName
        folderId
        folderName
        assetId
      }
      assetType
    }
  }
`
