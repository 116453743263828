import { useEffect, useRef, useState } from 'react'

import { isCypress } from '@utils/const/globals'

interface UseMinimumLoadingDurationProps {
  isLoading: boolean
  minDurationMs?: number
}

// Would need to update many tests to account for this artificial delay, so for now we will just make the duration shorter in Cypress
const DEFAULT_MIN_DURATION = isCypress() ? 10 : 350

/**
 * This hook takes a boolean `isLoading` and returns a boolean that will not become false for at least a minimum amount of time.
 * This is useful for ensuring that a loading spinner does not appear to 'flash' on screen if data fetching happens very fast.
 *
 * @param isLoading - Whether the data is currently loading
 * @param minDurationMs - The minimum duration in milliseconds that the loading value should remain true
 * @returns `true` if `isLoading` is `true` or `minDurationMs` time has not yet passed, otherwise `false`
 *
 * @example
 * ```tsx
 * const { isLoading } = props
 * const showLoader = useMinimumLoadingDuration({ isLoading, minDurationMs: 1000 })
 * // Loader will not unmount until at least 1 second has passed
 * return showLoader ? <Loader /> : null
 * ```
 */
export const useMinimumLoadingDuration = ({ isLoading, minDurationMs = DEFAULT_MIN_DURATION }: UseMinimumLoadingDurationProps) => {
  const startedLoadingAt = useRef(0)
  const timeout = useRef(0)
  const [showLoader, setShowLoader] = useState(isLoading)

  useEffect(() => {
    if (!isLoading) {
      const timeElapsed = Date.now() - startedLoadingAt.current
      const duration = Math.max(0, minDurationMs - timeElapsed)

      timeout.current = window.setTimeout(() => {
        startedLoadingAt.current = 0
        setShowLoader(false)
      }, duration)

      return () => clearTimeout(timeout.current)
    } else {
      clearTimeout(timeout.current)
      setShowLoader(true)
      startedLoadingAt.current = Date.now()
    }
  }, [isLoading])

  return isLoading || showLoader || startedLoadingAt.current > 0
}
