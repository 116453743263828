import React, { useEffect, useRef } from 'react'

import MediaPickerModal from '@components/AssetPickers/MediaPickerModal/MediaPickerModal'
import { MediaItem } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.context'
import { MediaPickerModalType } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import EmailComposerPreviewContent from '@src/pages/EmailComposer/components/EmailComposerPreview/components/EmailComposerPreviewContent/EmailComposerPreviewContent'
import EmailComposerPreviewTabs from '@src/pages/EmailComposer/components/EmailComposerPreview/components/EmailComposerPreviewHeader/components/EmailComposerPreviewTabs/EmailComposerPreviewTabs'
import { processIframePreviewHtml } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { EmailPreviewPlatform } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import { formatSelectedFiles, trimCode } from '../../utils/LandingPageComposer.utils'
import LandingPageComposerCustomCodeSidebar from '../LandingPageComposerCustomCodeSidebar/LandingPageComposerCustomCodeSidebar'

import './LandingPageComposerCustomCode.css'

const rootClass = 'landing-page-composer-custom-code'

const tabs = [
  { label: 'Desktop', value: 'desktop', disabled: false },
  { label: 'Mobile', value: 'mobile', disabled: false },
]

const LandingPageComposerCustomCode = () => {
  const {
    values: {
      loading,
      isBeeLoading,
      showMediaPickerModal,
      mediaPickerModalType,
      message: { templateHtml },
      preview: { platform },
      landingPage: { selectedCss, selectedScripts, customJavaScript, customCss, customCodeChanged },
      customTabFirstRender,
    },
    api: { onPreview, updatePreview, updateModal, onSave, update },
  } = useComposerContext()

  const { t } = useTranslation()

  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (!isBeeLoading) {
      updatePreview({ html: templateHtml ? processIframePreviewHtml(templateHtml) : '' })
      onPreview()
    }
  }, [isBeeLoading])

  const handleClosePickerModal = () => {
    update({ showMediaPickerModal: false })
  }

  const handleMediaPickerSubmit = async (selectedItems: MediaItem[]) => {
    try {
      handleClosePickerModal()
      update({ loading: true })

      const formattedSelectedItems = await formatSelectedFiles(selectedItems)

      if (mediaPickerModalType === MediaPickerModalType.CSS) {
        update({ landingPage: { selectedCss: formattedSelectedItems } })
      } else if (mediaPickerModalType === MediaPickerModalType.JS) {
        update({ landingPage: { selectedScripts: formattedSelectedItems } })
      }
    } catch (error) {
      if (error) {
        updateModal('statusToast', {
          status: Status.FAIL,
          title: t('LandingPageComposer.CustomCode.Insert.Error.Title'),
          message: t('LandingPageComposer.CustomCode.Insert.Error.Message'),
        })
      }
    } finally {
      update({ loading: false })
    }
  }

  useEffect(() => {
    if (!customCodeChanged) {
      const isCustomJs = customJavaScript !== undefined

      const parser = new DOMParser()
      const doc = parser.parseFromString(templateHtml, 'text/html')

      const elements = [
        { id: 'custom-javascript', type: 'script', content: customJavaScript, location: doc.documentElement },
        { id: 'custom-css', type: 'style', content: customCss, location: doc.head },
      ]

      let scriptContentChanged = false
      let styleContentChanged = false

      elements.forEach(({ id, type, content, location }) => {
        if (content === undefined) return

        const existingElement = doc.getElementById(id)
        const normalizedContent = content || ''
        const existingContent = existingElement?.textContent || ''

        const contentChanged = !customTabFirstRender && trimCode(existingContent) !== trimCode(normalizedContent)

        if (id === 'custom-javascript') scriptContentChanged = contentChanged
        if (id === 'custom-css') styleContentChanged = contentChanged

        if (existingElement) {
          existingElement.textContent = normalizedContent
        } else {
          const newElement = doc.createElement(type)
          newElement.id = id
          newElement.textContent = normalizedContent
          location.appendChild(newElement)
        }
      })

      if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

      debounceTimeout.current = setTimeout(() => {
        if (!loading && (scriptContentChanged || styleContentChanged)) {
          if (styleContentChanged) {
            update({ preview: { html: doc.documentElement.outerHTML } })
          }
          if (scriptContentChanged) {
            update({ showRefreshPreviewModal: isCustomJs, showMediaPickerModal: false })
          }
          update({
            message: { templateHtml: doc.documentElement.outerHTML },
            landingPage: { customCodeChanged: scriptContentChanged || styleContentChanged },
          })
        }
      }, 2000)

      return () => {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current)
      }
    }
  }, [customJavaScript, customCss, customCodeChanged])

  return (
    <>
      {showMediaPickerModal && (
        <MediaPickerModal
          onClose={handleClosePickerModal}
          onMultipleSubmit={handleMediaPickerSubmit}
          onBack={handleClosePickerModal}
          isOpen={showMediaPickerModal}
          cssOrJsMediaType={mediaPickerModalType}
          preSelectedItems={
            mediaPickerModalType === MediaPickerModalType.CSS
              ? (selectedCss as Array<{ id: string; name: string }>)
              : mediaPickerModalType === MediaPickerModalType.JS
              ? (selectedScripts as Array<{ id: string; name: string }>)
              : []
          }
        />
      )}
      <div className={rootClass}>
        <div className={`${rootClass}__container`}>
          <div className={`${rootClass}__container-header`}>
            <Typography text={t('LandingPageComposer.CustomCode.ViewOnly')} type={TextType.BODY_TEXT_LARGE_LIGHT} lineHeight={LineHeight.LARGE} />
            <EmailComposerPreviewTabs
              tabs={tabs}
              activeTab={platform}
              onChange={(value) => updatePreview({ platform: value as EmailPreviewPlatform })}
              onSave={onSave}
              updateModal={updateModal}
            />
            <div className={`${rootClass}__container-header-spacer`} />
          </div>
          {!loading && <EmailComposerPreviewContent isCustomCodePreview />}
        </div>
        <LandingPageComposerCustomCodeSidebar />
      </div>
    </>
  )
}

export default LandingPageComposerCustomCode
