import React, { FC } from 'react'

import classNames from 'classnames'

import { MobileView } from '@components/MobileView/MobileView'
import TextBubble from '@components/TextBubble/TextBubble'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'
import { AllUrlsRegExp } from '@utils/formUtils'

import './SmsPickerPreview.css'

interface SmsPickerPreviewProps {
  message: string
  className?: string
  dataTest?: string
}

const rootClass = 'sms-picker-preview'

const SmsPickerPreview: FC<SmsPickerPreviewProps> = (props: SmsPickerPreviewProps) => {
  const { message, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const styleLinks = () => {
    const messageText = message || t('Empty message')
    const styledText = messageText.replace(AllUrlsRegExp, (match: string) => `<SmsLink>${match}</SmsLink>`)
    return <Typography text={styledText} tagProps={{ SmsLink: { type: TextType.LINK_SMS } }} inline />
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <MobileView content={<TextBubble text={styleLinks()} noMargin roundedRadius />} size={'medium'} />
    </div>
  )
}

export default SmsPickerPreview
