import React, { FC, Ref, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

import Loader from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader/PageHeader'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { rootContext, useTranslation } from '@const/globals'
import Analytics from '@src/pages/reports/Analytics/Analytics'
import ExploreData from '@src/pages/reports/Analytics/ExploreData/ExploreData'
import SpotIQ from '@src/pages/reports/Analytics/SpotIQ/SpotIQ'
import { TsEmbed } from '@thoughtspot/visual-embed-sdk/dist/visual-embed-sdk'
import { useAccountSettings } from '@utils/account/account.utils'
import { tsInitializer, useThoughtSpotService } from '@utils/hooks/microservices/useThoughtspotService'
import { logNewRelicError } from '@utils/new-relic.utils'
import { MatchParams } from '@utils/types'

import './Analytics.css'

type AnalyticsProps = RouteComponentProps<MatchParams> & {
  location: {
    state: {
      tab?: string
      subTab?: string
    }
  }
}

const defaultTab = 'home'

const baseUrl = `${rootContext}/report/analytics`

const rootClass = 'analytics-container'

const AnalyticsContainer: FC<AnalyticsProps> = (props: AnalyticsProps) => {
  const { match, location } = props
  const history = useHistory()

  const { userEmail, hasAIAnalytics } = useAccountSettings()
  const { t } = useTranslation()

  const { tsAuth, thoughtSpotHost, sandboxAnalyticsEmbedProps } = useThoughtSpotService()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      if (!initialized.current) {
        tsInitializer(thoughtSpotHost, userEmail, tsAuth)
        initialized.current = true
      }
      setLoading(false)
    }
  }, [loading])

  const [detailPath, setDetailPath] = useState('')
  const [embedRef, setEmbedRef] = useState<Ref<TsEmbed>>()
  const [spotIQRef, setSpotIQRef] = useState<Ref<TsEmbed>>()

  const onDetailRender = useCallback(() => setShowArrow(true), [])

  const tabs: TabItemData[] = [
    {
      index: 'home',
      label: 'Home',
      content: (
        <Analytics
          hasAIAnalytics={hasAIAnalytics}
          detailPath={detailPath}
          onDetailRender={onDetailRender}
          setRef={setEmbedRef}
          {...sandboxAnalyticsEmbedProps}
        />
      ),
    },
    {
      index: 'explore-data',
      label: 'Explore Data',
      content: <ExploreData hasAIAnalytics={hasAIAnalytics} detailPath={detailPath} />,
    },
    {
      index: 'spot-iq',
      label: 'Spot IQ',
      content: <SpotIQ setRef={setSpotIQRef} detailPath={detailPath} onDetailRender={onDetailRender} hasAIAnalytics={hasAIAnalytics} />,
    },
  ]

  const tabErrorChecked = useRef(false)
  const rawTab = match?.params?.tab ?? location?.state?.tab ?? defaultTab

  useEffect(() => {
    if (!tabErrorChecked.current) {
      if (!['home', 'explore-data', 'spot-iq'].includes(rawTab)) {
        logNewRelicError(`Analytics: Unexpected tab requested ${rawTab}`)
      }
      tabErrorChecked.current = true
    }
  }, [rawTab, tabErrorChecked])

  const realTab = tabs.find((tabItem) => tabItem.index === rawTab)?.index ?? defaultTab
  const [tab, setTab] = useState<string>(realTab)
  const [showArrow, setShowArrow] = useState(false)
  const initialized = useRef<boolean>(false)
  useEffect(() => {
    if (location.hash.startsWith('#')) {
      setDetailPath(location.hash.replace('#/', ''))
    }

    history.replace(`${baseUrl}/${tab}`, {
      ...{ ...(location?.state ?? {}) },
      tab,
    })
  }, [tab])

  const navigationTriggered = useCallback(() => {
    const isHome = tab === 'home'
    const ref = isHome ? embedRef : spotIQRef
    const newPage = isHome ? 'home' : 'insights'
    // @ts-ignore
    ref.navigateToPage(newPage, true)
    setShowArrow(false)
  }, [embedRef, spotIQRef, tab])

  const onTabChange = useCallback((newTab: string) => {
    setShowArrow(false)
    setTab(newTab)
    setDetailPath('')
  }, [])

  return (
    <PageContainer className={rootClass} alwaysPadding>
      <PageHeader primaryText={t('Analytics')} leftContent customLinkCallback={showArrow ? () => navigationTriggered() : undefined} />
      {loading && <Loader />}
      {!loading && <Tabs childData={tabs} defaultValue={tab} onChange={onTabChange} />}
    </PageContainer>
  )
}

export default AnalyticsContainer
