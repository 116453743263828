import React, { FC, useContext, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'

import { getRowSelectionState } from '@complex/AssetPickerModal/utils/AssetPickerModalUtil'
import { getFolderById, isCustomFilterSelected } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import { ItemDtoRow, ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { SvgNames, SvgType } from '@components/Svg'
import { TableV2 } from '@components/TableV2/TableV2'
import { RowAction, TableV2Props } from '@components/TableV2/tableV2TS/interfaces'
import { useTranslation } from '@const/globals'
import { commonFilters } from '@utils/filter'
import { useMinimumLoadingDuration } from '@utils/hooks/useMinimumLoadingDuration'

import AssetPickerSearchHeader, { SearchHeaderFilter } from './AssetPickerSearchHeader/AssetPickerSearchHeader'
import { filterTagsColumn } from './AssetPickerTable.utils'
import { AssetPickerContext } from '../../Context/AssetPicker.context'

import './AssetPickerTableContainer.css'

interface AssetPickerTableContainerProps {
  className?: string
  dataTest?: string
}

const rootClass = 'asset-picker-table-container'

const AssetPickerTableContainer: FC<AssetPickerTableContainerProps> = (props) => {
  const { className, dataTest = rootClass } = props

  const {
    onRowSelectionChanged,
    values: {
      hasTags,
      isSingleSelect,
      loadingPreSelectedItems,
      isViewingSelected,
      selectedViewItems,
      hasPreview,
      tableProps: {
        columns,
        renderSearchColumns,
        sortingBy,
        listPage,
        headerCheckboxDisabled,
        rowDisabled,
        rowDisabledTitle,
        rowTooltip,
        hasDisabledRowStyles,
        enableLazyLoading = true,
        enableSorting,
        onLoading = () => updateListing({ currentPage: currentPage + 1 }),
      },
    },
  } = useContext(AssetPickerContext)

  const {
    update: updateListing,
    onColumnSort,
    onClickTagInRow,
    onSearch,
    toggleSubType,
    setFilter,
    currentTag,
    values: {
      listingPageProps: {
        sidebarProps: { allItemFilter, customDefaultFilters },
        subTypes,
      },
      activeSubTypes,
      defaultSubTypes,
      currentPage,
      customSourceItems,
      folders,
      tags,
      items,
      search,
      searchAll,
      searchItemsResults,
      loading,
      fetchNextPageItems,
      allItemsLoaded,
      activeFolderId,
      activeTagId,
      filterActive,
      selectedCustomSource,
      emptyListingProps,
    },
  } = useContext(ListingPageCommonContext)

  const { t } = useTranslation()

  const tableRef = useRef<HTMLDivElement>(null)

  const ignoreSort = useRef(true)
  const onSort: typeof onColumnSort = (sorts) => {
    // Workaround for ignoring the first call to sort, which TableV2 does on mount
    if (ignoreSort.current) {
      setTimeout(() => {
        ignoreSort.current = false
      }, 100)
    } else {
      onColumnSort(sorts)
    }
  }

  const currentFolder = activeFolderId ? getFolderById(activeFolderId, folders) : undefined

  const searchForColumns = useMemo(
    () =>
      renderSearchColumns(searchAll, currentFolder as FolderData, search, folders, selectedCustomSource).map((column) => ({
        ...column,
        disableSorting: true,
      })),
    [searchItemsResults, searchAll]
  )

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0
    }
  }, [filterActive, activeFolderId, activeTagId])

  const rowActions: RowAction<ItemDtoRow>[] = hasPreview
    ? [
        {
          label: t(`AssetPicker.${listPage}.Table.Preview`),
          icon: SvgNames.zoom,
          iconSize: SvgType.ICON,
          onClick: (row: any) => {
            updateListing({ showPreview: true, selectedRows: [row.original] })
          },
        },
      ]
    : []

  const isCustomSourceFilter = () => {
    return (
      !!selectedCustomSource &&
      !!filterActive &&
      selectedCustomSource?.customRequestFilters?.find(({ filter }) => filterActive?.name === filter?.name)
    )
  }

  const tableData = search
    ? searchItemsResults
    : isCustomSourceFilter() && !!customSourceItems && customSourceItems[selectedCustomSource?.label as string]
    ? customSourceItems[selectedCustomSource?.label as string]
    : items
  const defaultSelectedRows = useMemo(
    () => getRowSelectionState(tableData, selectedViewItems, isSingleSelect),
    [loading, search, isViewingSelected, isSingleSelect, selectedCustomSource, isSingleSelect, selectedViewItems]
  )

  const showLoader = useMinimumLoadingDuration({ isLoading: loading || loadingPreSelectedItems })

  const prevTableKey = useRef<string>('initializing')
  const tableKey = useMemo(() => {
    // If we finish loading and the custom source or search term has changed, the table will remount
    // This ensures that the calculated selected rows and table data are in sync on mount
    if (showLoader) {
      return prevTableKey.current
    }
    const newKey = `${selectedCustomSource?.value}-${search}`
    prevTableKey.current = newKey
    ignoreSort.current = true
    setTimeout(() => {
      ignoreSort.current = false
    }, 100)
    return newKey
  }, [selectedCustomSource, search, showLoader])

  const showEmptyState = !tableData?.length && !loading && !loadingPreSelectedItems

  // `fetchNextPageItems` means the user scrolled to activate lazy loading so we do not need to clear the table to make the loader visible
  // Note: `fetchNextPageItems` will change before `showLoader` changes, so it is intentionally not in the dependency array
  const clearTableForLoader = useMemo(() => showLoader && !fetchNextPageItems, [showLoader])

  useEffect(() => {
    // Stops the scroll position from jittering when the loader component mounts and makes the table body taller
    fetchNextPageItems && tableRef.current?.scrollTo({ top: tableRef?.current.scrollHeight })
  }, [fetchNextPageItems])

  const tableV2Props: TableV2Props<ItemDtoRow> = {
    columns: filterTagsColumn(search ? searchForColumns : columns, hasTags),
    data: clearTableForLoader ? [] : tableData,
    loading: showLoader || fetchNextPageItems,
    allLoaded: !showLoader && allItemsLoaded,
    defaultSelectedRows,
    rowActions,
    enableCheckbox: !isSingleSelect,
    enableRadio: isSingleSelect,
    sortingBy,
    emptyState: showEmptyState ? emptyListingProps : undefined,
    enableStickyHeader: true,
    withoutBorders: true,
    onRowSelectionChanged,
    headerCheckboxDisabled,
    rowDisabled,
    rowTooltip,
    hasDisabledRowStyles,
    rowDisabledTitle,
    scrollableElementRef: tableRef,
    tagProps: { tags, readOnlyTags: true, tagAction: onClickTagInRow, selectedTagId: currentTag?.id },
    enableInsideLoader: true,
    showBottomBorder: !!search && !loading,
    showTopBorder: !!search,
    // Backend vs. frontend pagination props
    onLoading: !enableLazyLoading || search ? undefined : onLoading,
    enableLazyLoading: search ? false : enableLazyLoading,
    // Frontend sorting props
    enableSorting,
    // Backend sorting props
    manualSorting: !enableSorting,
    onSort: search ? undefined : onSort,
  }

  const activeTag = tags.find((tag) => tag.id === activeTagId)

  const renderSearchHeader = () => {
    const filters: SearchHeaderFilter[] = activeSubTypes
      .filter((value) => !defaultSubTypes.includes(value))
      .map((name) => {
        const label = subTypes?.find((subType) => subType.name === name)?.label ?? ''
        return { name: label, onRemove: () => toggleSubType(name) }
      })

    let activeFilter = filterActive
    if (filterActive) {
      // Add as a readOnly active filter if it is a custom filter that is not a part of the first section of the sidebar
      const defaultFilterNames = [...commonFilters, allItemFilter, ...(customDefaultFilters ?? [])].map((filter) => filter.name)
      if (!defaultFilterNames.includes(filterActive.name)) {
        filters.push({
          name: filterActive.name,
          onRemove: () => {
            updateListing({ items: [] })
            setFilter(allItemFilter, isCustomFilterSelected(allItemFilter, allItemFilter))
          },
          readOnly: true,
        })
        // Prevents displaying the filter both as the base filter and a chip
        activeFilter = undefined
      }
    }
    return (
      <AssetPickerSearchHeader
        baseFilter={currentFolder ?? activeTag ?? activeFilter}
        filters={filters}
        searchTerm={search}
        searchCount={tableV2Props.data.length}
        onRemoveAllFilters={() => {
          updateListing({ activeSubTypes: defaultSubTypes, fetchItems: true })
        }}
      />
    )
  }

  useEffect(() => {
    // Note: It would be better if this was called by useListingPageBase when the search term changes,
    // something to try later
    search && onSearch(filterActive?.name === allItemFilter.name)
  }, [search])

  return (
    <div
      className={classNames(`${rootClass}__table`, {
        [`${rootClass}__table--empty-listing`]: showEmptyState,
        [`${className}`]: className,
      })}
      data-test={dataTest}
      ref={tableRef}
    >
      {search && renderSearchHeader()}
      <TableV2 {...tableV2Props} key={tableKey} />
    </div>
  )
}

export default AssetPickerTableContainer
